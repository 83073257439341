import React, { useRef } from 'react'
import PropTypes from 'prop-types'
import XLSX from 'xlsx'
import { saveAs } from 'file-saver'
import { Button } from 'shards-react'
import moment from 'moment'

export function formatDate(date) {
  const WEEK_DAYS = ['Вс', 'Пн', 'Вт', 'Ср', 'Чт', 'Пт', 'Сб']
  const _date = moment(date)
  const isToday = _date.isSame(moment(), 'd')
  const isYesterday = _date.isSame(moment().subtract(1, 'days'), 'd')
  const weekDay = _date.weekday()

  if (isToday) {
    return 'Сегодня'
  } else if (isYesterday) {
    return 'Вчера'
  } else {
    return _date.format(`DD MMMM, ${WEEK_DAYS[weekDay]}`)
  }
}

const ExcelExporter = ({ children, isChief, sheetName = 'Количество заболевших', title='Статистика по заболевшим COVID-19.xlsx' }) => {
  const sheetsContainer = useRef(null)

  const s2ab = s => {
    let buf = new ArrayBuffer(s.length)
    let view = new Uint8Array(buf)
    for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff
    return buf
  }

  const downloadSheets = () => {
    const tableChildren = sheetsContainer.current.childNodes
    const output = document.createElement('table')

    tableChildren.forEach((el, index) => {
      const table = sheetsContainer.current.childNodes[index].querySelector('table')
      const title = sheetsContainer.current.childNodes[index].querySelector('h3')
      const subtitle = sheetsContainer.current.childNodes[index].querySelector('h4')

      const header = `
                    <tr><td colspan="14" rowspan="2">${title ? title.innerText : ''}</td></tr>
                    <tr></tr>
                    <tr><td colspan="14" rowspan="2">${subtitle ? subtitle.innerText : ''}</td></tr>
                    <tr></tr>`

      const footer = isChief
        ? `<tr><td colspan="14" rowspan="2" style="font-weight: bold;"><strong>Начальник училища ______________________________</strong></td></tr>
                    <tr></tr>`
        : null

      output.insertAdjacentHTML('beforeend', header)

      if (table) {
        const childRows = Array.from(table.children)
        childRows.forEach(el => {
          let cloned = el.cloneNode(true)
          output.appendChild(cloned)
        })
      }

      if (footer) {
        output.insertAdjacentHTML('beforeend', footer)
      }
      output.insertAdjacentHTML('beforeend', `<tr></tr><tr></tr>`)
    })

    let wb = XLSX.utils.book_new()
    wb.SheetNames.push(sheetName)

    const ws2 = XLSX.utils.table_to_sheet(output, { raw: false })

    wb.Sheets[sheetName] = ws2
    const wbout = XLSX.write(wb, { bookType: 'xlsx', bookSST: true, type: 'binary' })
    saveAs(new Blob([s2ab(wbout)], { type: 'application/octet-stream' }), title)
  }

  return (
    <div>
      {children && typeof children[0] !== 'undefined' && (
        <Button theme='success' className={'mb-2'} onClick={downloadSheets}>
          Скачать .xlsx
        </Button>
      )}

      <div ref={sheetsContainer}>{children}</div>
    </div>
  )
}

ExcelExporter.propTypes = {
  isChief: PropTypes.bool,
  sheetName: PropTypes.string,
}

export default ExcelExporter

import React from 'react'
import PropTypes from 'prop-types'
import moment from 'moment'
import classNames from 'classnames'

import styles from './s.module.css'

const DetailsTable = ({ data = {}, foreignMode }) => {

  if (!data || !data.Data) return null

  const { Data, Request } = data

  const {orgName, classname} = Data && Data.length ? Data[0] : {}

  const formatted = date => moment(date).format('DD.MM.YYYY')

  return (
    <div className={'mt-5'}>
      <h3 className={'text-center mb-2'}>
        {orgName}
        <br/>
        {`Движение переменного состава в ${classname} классе с ${formatted(Request.DateFrom)} по ${formatted(Request.DateTo)}`}
      </h3>
      <table border='1' cellPadding='10' className={styles.table}>
        <thead className={styles.text_center}>
          <tr>
            <td>
              <strong>Фамилия имя Отчество</strong>{' '}
            </td>
            <td>
              <strong>Движение</strong>{' '}
            </td>
            <td>
              <strong>Дата</strong>{' '}
            </td>
            <td>
              <strong>№ приказа</strong>{' '}
            </td>
            <td>
              <strong>Дата приказа МО РФ</strong>{' '}
            </td>
            <td>
              <strong>№ приказа МО РФ</strong>{' '}
            </td>
            <td>
              <strong>Тип набора</strong>{' '}
            </td>
            <td>
              <strong>Дата педсовета</strong>{' '}
            </td>
            <td>
              <strong>№ протокола педсовета</strong>{' '}
            </td>
            <td>
              <strong>Причина отчисления</strong>{' '}
            </td>
            {foreignMode &&
            <td>
              <strong>Гражданство</strong>{' '}
            </td>}
          </tr>
        </thead>
        <tbody>
          {Data.map(item => (
            <tr
              className={classNames({
                [styles.red]: item.is_out === '1',
              })}
            >
              <td>{item.fio}</td>
              <td>{item.Move}</td>
              <td>{item.MoveDate ? item.MoveDate.split(' ')[0] : '-'}</td>
              <td>{item.Prikaz}</td>
              <td>{item.dateMO ? item.dateMO.split(' ')[0] : '-'}</td>
              <td>{item.PrikazMO}</td>
              <td>{item.stream}</td>
              <td>{item.PedDate ? item.PedDate.split(' ')[0] : '-'}</td>
              <td>{item.Protocol}</td>
              <td>{item.reason}</td>
              {foreignMode &&
              <td>{item.Country}</td>}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  )
}

DetailsTable.propTypes = {
  data: PropTypes.array.isRequired,
}

export default DetailsTable

import {ERROR, START, SUCCESS} from "../../../../constants/action-postfix";
import {PHONEBOOK_REQUEST} from "../phonebookReducer";


const initialState = false;

export const phonebookLoaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${PHONEBOOK_REQUEST}${START}`:
            return true;
        case `${PHONEBOOK_REQUEST}${SUCCESS}`:
            return false;
        case `${PHONEBOOK_REQUEST}${ERROR}`:
            return false;
        default:
            return state;
    }
};
import React, { Component } from "react";

export default class Cell extends Component {
  state = {
    hover: false
  };
  handleMouseEnter = () => {
    this.setState({ hover: !this.state.hover });
  };
  handleMouseLeave = () => {
    this.setState({ hover: !this.state.hover });
  };

  render() {
    return (
      <div
        onMouseEnter={this.handleMouseEnter}
        onMouseLeave={this.handleMouseLeave}
      >
        {this.state.hover ? this.props.hoverText : this.props.text}
      </div>
    );
  }
}
import React, { useMemo } from 'react'
import PropTypes from 'prop-types'

import styles from '../../Week/Table/DiseasedWeekTable.module.css'

import { Table } from 'antd'
import ExcelExporter from '../../ExcelExporter'

const DiseasedPeriodTable = ({ data }) => {
  const mainColumns = useMemo(
    () => [
      {
        title: 'Класс',
        dataIndex: 'kurs',
      },
      {
        title: 'Кол-во обучающихся',
        dataIndex: 'total',
      },
      {
        title: 'Кол-во заболевших',
        dataIndex: 'ill',
      },
      {
        title: '% заболевших',
        dataIndex: 'percantage',
        render: (text, record) => ((Number(record['ill']) / Number(record['total'])) * 100).toFixed(2),
      },
      {
        title: 'Из них с заболеваниями',
        children: [
          {
            title: 'COVID-19',
            dataIndex: 'covid19',
          },
          {
            title: 'ОРЗ',
            dataIndex: 'ORZ',
          },
          {
            title: 'пневмония',
            dataIndex: 'pneumonia',
          },
          {
            title: 'другие',
            dataIndex: 'other',
          },
        ],
      },
      {
        title: 'Место лечения',
        children: [
          {
            title: 'медпункт',
            dataIndex: 'med_punkt',
          },
          {
            title: 'стационар больницы / госпиталя',
            dataIndex: 'hospital',
          },
          {
            title: 'на дому',
            dataIndex: 'home',
          },
        ],
      },
      {
        title: 'Продолжительность заболевания',
        children: [
          {
            title: 'до 1 суток',
            dataIndex: 'day_1',
          },
          {
            title: '3 суток и более',
            dataIndex: 'day_3',
          },
          {
            title: '7 суток и более',
            dataIndex: 'day_7',
          },
        ],
      },
      {
        title: 'Кол-во повторно заболевших в течение месяца',
        dataIndex: 'repeated',
      },
    ],
    []
  )

  const ratingColumns = useMemo(
    () => [
      {
        title: '№',
        render: (text, record, index) => index + 1,
      },
      {
        title: 'Название образовательной организации',
        dataIndex: 'OrgName',
      },
      {
        title: 'Всего обучающихся',
        dataIndex: 'total',
      },
      {
        title: 'Всего заболевших',
        dataIndex: 'ill',
      },
      {
        title: '% заболевших',
        render: (text, record) => Number(record['percent']).toFixed(2),
      },
    ],
    []
  )

  const getFormatedDates = () => {
    const { datefrom, dateto } = data.dates

    if (datefrom === dateto) {
      return `на ${datefrom.split(' ')[0]}`
    }
    return `за период ${datefrom.split(' ')[0]} – ${dateto.split(' ')[0]} г. `
  }

  return (
    <div>
      <ExcelExporter title="Сведения о заболевших.xlsx" isChief>
        {data.organizations &&
          data.organizations.map(org => (
            <div key={org.idOrganization} className={styles.container}>
              <h3 className={'text-center mb-2'}>{org.OrgName}</h3>
              <h4 className={'text-center mb-2'}>{`Сведения о количестве заболевших ${getFormatedDates()}`}</h4>

              <Table dataSource={org.Values} columns={mainColumns} pagination={false} bordered />
            </div>
          ))}

        {data.rating1 && (
          <div className={styles.container}>
            <h3 className={'text-center mb-2'}>Самый высокий процент заболевших (только выше 10%):</h3>
            {!!data.rating1.length ? (
              <Table dataSource={data.rating1} columns={ratingColumns} pagination={false} bordered />
            ) : (
              <h2 className={'text-center'}>Нет данных</h2>
            )}
          </div>
        )}

        {data.rating2 && (
          <div className={styles.container}>
            <h3 className={'text-center mb-2'}>Остальные:</h3>
            {!!data.rating2.length && (
              <Table dataSource={data.rating2} columns={ratingColumns} pagination={false} bordered />
            )}
          </div>
        )}

        {data.summary && (
          <div className={styles.container}>
            <h3 className={'text-center mb-2'}>{`Сведения о количестве заболевших в ДОУ МО РФ ${getFormatedDates()}`}</h3>
            {!!data.summary.length && (
              <Table dataSource={data.summary} columns={mainColumns} pagination={false} bordered />
            )}
          </div>
        )}
      </ExcelExporter>
    </div>
  )
}

DiseasedPeriodTable.propTypes = {
  formData: PropTypes.object,
  data: PropTypes.array,
}

export default DiseasedPeriodTable

export const MONTHS = [
    {
        'num': '01',
        'name': 'Январь'
    },    {
        'num': '02',
        'name': 'Февраль'
    },    {
        'num': '03',
        'name': 'Март'
    },    {
        'num': '04',
        'name': 'Апрель'
    },    {
        'num': '05',
        'name': 'Май'
    },    {
        'num': '06',
        'name': 'Июнь'
    },    {
        'num': '07',
        'name': 'Июль'
    },    {
        'num': '08',
        'name': 'Август'
    },    {
        'num': '09',
        'name': 'Сентябрь'
    },    {
        'num': '10',
        'name': 'Октябрь'
    },    {
        'num': '11',
        'name': 'Ноябрь'
    },    {
        'num': '12',
        'name': 'Декабрь'
    },
];

export const findMonthName = (number) =>
    MONTHS.find(el => (el.num === number));

import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import RouteLayout from '../../RouteLayout'
import Table from './Table'
import Form from './Form'

import {
  setStudentsTotalFormData,
  studentsMoveAction,
  studentsTotalAction,
} from '../../../redux/reducers/StudentsTotal/studentsTotalReducer'
import { organizationList } from '../../../redux/reducers/organization-list-reducer'
import Spinner from '../../common/Spinner'
import DetailsTable from './DetaisTable'
import { StudentsTotalPdfPrint } from './PDFPrint/PDFPrint'

const mapStateToProps = state => {
  let res = new Map()
  const list = state.reports.studentsTotal.list.data

  list &&
    list.forEach(item => {
      if (!res.get(item.idOrganization)) {
        res.set(item.idOrganization, [])
      }

      res.get(item.idOrganization).push(item)
    })

  return {
    auth: state.auth.data,
    formData: state.reports.studentsTotal.form,
    list: Array.from(res.values()),
    loader: state.reports.studentsTotal.list.isLoading,
    details: state.reports.studentsTotal.details.data,
    detailsLoader: state.reports.studentsTotal.details.isLoading,
    orgList: state.reports.orgList.data,
    foreignMode: state.reports.studentsTotal.list.foreign
  }
}

const mapDispatchToProps = {
  requestReport: studentsTotalAction.studentsTotalRequestStart,
  requestDetails: studentsMoveAction.studentsMoveRequestStart,
  setFormData: setStudentsTotalFormData,
  organizationListStart: organizationList.organizationListRequestStart,
}

const StudentsTotal = ({
  requestReport,
  requestDetails,
  formData,
  setFormData,
  foreignMode,
  list,
  loader,
  orgList,
  organizationListStart,
  auth,
  details,
  detailsLoader,
}) => {
  useEffect(() => {
    if (orgList !== undefined && orgList.length === 0) {
      organizationListStart(auth)
    }
  }, [organizationListStart, auth])

  const requestReportHandler = () => {
    requestReport({ ...auth, ...formData })
  }

  const requestDetailsHandler = (groupid, kurs, idOrg) => {
    requestDetails({ ...auth, ...formData, groupid, kurs, idOrg })
  }

  return (
    <RouteLayout title={'ЧИСЛЕННОСТЬ ПЕРЕМЕННОГО СОСТАВА'}>
      <Form
        formData={formData}
        setFormData={setFormData}
        orgList={orgList}
        onSubmit={requestReportHandler}
      />
      {!loader && <StudentsTotalPdfPrint />}
      {loader
        ? <Spinner />
        : <Table
            foreignMode={foreignMode}
            data={list}
            requestDetails={requestDetailsHandler}
            dateto={formData.dateto}
          />
      }
      {detailsLoader
        ? <Spinner />
        : <DetailsTable
            data={details}
            foreignMode={foreignMode}
          />
      }
    </RouteLayout>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(StudentsTotal)

import {SUCCESS} from "../../../../constants/action-postfix";
import {CONNECT_FAILS_REQUEST} from "../connectFailsReducer";


const initialState = [];

export const connectFailsListReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${CONNECT_FAILS_REQUEST}${SUCCESS}`:
            return action.payload;

        default:
            return state;
    }
};
import React, { useState } from 'react'
import { useSelector } from 'react-redux'
import Select from 'react-select'
import { FormGroup, FormCheckbox } from 'shards-react'
import { fileSharingAddFile } from '../../../../api/fileSharing'
import { getAuthData } from '../../../../redux/reducers/auth-reducer'
import { getOrgList } from '../../../../redux/reducers/organization-list-reducer'
import { getBase64 } from '../../../../utils/getBase64'
import { organizationToOptions } from '../../../../utils/toSelectOption'
import Button from '../../../common/Button'
import { Modal } from '../../../common/Modal'

export const FileSharingSentModal = ({ setModalVisible, handleRequesList }) => {
  const [fileSubmitting, setFileSubmitting] = useState(false)
  const authData = useSelector(getAuthData)
  const orgList = useSelector(getOrgList)
  const [file, setFile] = useState({ filename: null, data: null })
  const [idOrg, setIdOrg] = useState('')
  const [school, setSchool] = useState(0)

  const filteredOrgList = school ? orgList : orgList.filter(({isSchool}) => isSchool === '0')

  const handleFileSubmit = async () => {
    setFileSubmitting(true)
    const schoolParam = !idOrg || idOrg == 0 ? school : undefined
    await fileSharingAddFile({ ...authData, idOrg, school: schoolParam, ...file })
    setFileSubmitting(false)
    setModalVisible(false)
    handleRequesList()
  }

  const setFielHandler = e => {
    const file = e.target.files[0]
    getBase64(file).then(data => setFile({ filename: file.name, data }))
  }

  const setIdOrgHandler = orgOptions => {
    const arrIds = orgOptions ? orgOptions.map(e => e.value) : []
    setIdOrg(arrIds.join(','))
  }

  return (
    <Modal title='Добавить файл' isVisible={true}>
      <input type='file' onChange={setFielHandler} name='file' className='mb-2' />

      <FormGroup className='mb-2'>
        <Select placeholder='Выберите организацию' options={organizationToOptions(filteredOrgList)} onChange={setIdOrgHandler} isMulti />
      </FormGroup>

      <FormCheckbox
        onChange={() => setSchool(Number(!school))}
        checked={!!school}
        className={'mr-3'}
      >
        В том числе школы
      </FormCheckbox>

      <Button className='mr-2' label='Отменить' type='button' onClick={() => setModalVisible(false)} />
      <Button label='Сохранить' className='mr-2' type='button' onClick={handleFileSubmit} submitting={fileSubmitting} />
    </Modal>
  )
}

import {ERROR, START, SUCCESS} from "../../../../constants/action-postfix";
import {GRADUATES_REQUEST} from "../graduatesReducer";


const initialState = false;

export const loaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${GRADUATES_REQUEST}${START}`:
            return true;
        case `${GRADUATES_REQUEST}${SUCCESS}`:
            return false;
        case `${GRADUATES_REQUEST}${ERROR}`:
            return false;
        default:
            return state;
    }
};
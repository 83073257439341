import React, { useMemo } from "react";
import { Table } from "antd";
import styles from "./PhonebookTable.module.css";
import { PhonebookPdfPrint } from "../PDFprint/PDFprint";

export default function TableItem({ school, i, data }) {
  const mainColumns = useMemo(
    () => [
      {
        title: (
          <div className={styles.header}>
            Наименование образовательной организации, почтовый адрес, телефон,
            дата образования
          </div>
        ),
        render: (_, row, i) => {
          if (i === 0) {
            let _data = null;
            data.forEach((school) => {
              if (school.phones.find((el) => el.mid === row.mid))
                _data = school;
            });
            if (_data) {
              return {
                children: (
                  <div className={styles.firstColumn}>
                    <div className={styles.firstColumnEl}>
                      <b>{_data.info.school}</b>
                    </div>
                    <div className={styles.firstColumnEl}>
                      {_data.info.address}
                    </div>
                    <div className={styles.firstColumnEl}>
                      <b>Телефон:</b> {_data.info.phone}
                    </div>
                    <div className={styles.firstColumnEl}>
                      <b>Факс:</b> {_data.info.fax}
                    </div>
                    <div className={styles.firstColumnEl}>
                      <b>Дата образования училища:</b> {_data.info.founded}
                    </div>
                    <div className={styles.firstColumnEl}>
                      ({_data.info.order})
                    </div>
                  </div>
                ),
                props: {
                  rowSpan: _data.phones.length,
                },
              };
            } else return {};
          }
          return {
            props: {
              rowSpan: 0,
            },
          };
        },
      },
      {
        title: <div className={styles.header}>Должность</div>,
        dataIndex: "bookEntry",
      },
      {
        title: <div className={styles.header}>Фамилия, Имя, Отчество</div>,
        dataIndex: "fio",
      },
      {
        title: <div className={styles.header}>Дата рождения</div>,
        dataIndex: "Birthday",
        render: (val) => {
          return val.split(" ")[0];
        },
      },
      {
        title: <div className={styles.header}>Рабочий телефон</div>,
        dataIndex: "workPhone",
      },
      {
        title: <div className={styles.header}>Мобильный телефон</div>,
        dataIndex: "mobilePhone",
      },
    ],
    []
  );

  const PDFTable = React.useMemo(() => {
    return (
      <table border='1' cellPadding='5' id={`PhonebookPDFTable_${i}`} style={{ display: 'none' }}>
        <thead>
          <tr>
            <td colSpan='6'>
            Список телефонов руководящего состава {school.info.school_name1 ? school.info.school_name1 : school.info.school_name}
            </td>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>Наименование образовательной организации, почтовый адрес, телефон, дата образования</td>
            <td>Должность</td>
            <td>Фамилия, Имя, Отчество</td>
            <td>Дата рождения</td>
            <td>Рабочий телефон</td>
            <td>Мобильный телефон</td>            
          </tr>
          {school.phones.map((item,i) => (
            <tr key={i}>
              {i===0 && <td rowSpan={school.phones.length}>
                    
                      {school.info.school}<br/>
                      {school.info.address}<br/>
                      Телефон: {school.info.phone}<br/>
                      Факс: {school.info.fax}<br/>
                      Дата образования училища: {school.info.founded}<br/>
                      ({school.info.order})
                </td>}
              <td>{item.bookEntry}</td>
              <td>{item.fio}</td>
              <td>{item.Birthday.split(" ")[0]}</td>
              <td>{item.workPhone}</td>
              <td>{item.mobilePhone}</td>
            </tr>
          ))}
          </tbody>
          </table>
    )},[])

  return (
    <div key={i} className={styles.container}>
      <h3 className={"text-center mb-2"}>
        {"Список телефонов руководящего состава"}
      </h3>
      <h4 className={"text-center mb-2"}>
        {school.info.school_name1
          ? school.info.school_name1
          : school.info.school_name}
      </h4>

      <PhonebookPdfPrint i={i}/>
      <Table
        dataSource={school.phones}
        columns={mainColumns}
        pagination={false}
        bordered
      />
      {PDFTable}
    </div>
  );
}

import {combineReducers} from "redux";
import {diseasedMonthListReducer} from "./list/diseasedMonthListReducer";
import {diseasedMonthLoaderReducer} from "./loader/diseasedMonthLoaderReducer";
import {createActions} from "../../../factory/actionFactory";
import {DISEASED_WEEK_REQUEST, SET_WEEK_PERIOD_DATA, setWeekPeriodData} from "../Week/diseasedWeekReducer";
import { call, put, takeLatest } from 'redux-saga/effects';
import {reportsAPI} from "../../../../api/reports";
import {START} from "../../../../constants/action-postfix";
import {diseasedMonthPeriodReducer} from "./period/diseasedMonthPeriodReducer";

export const DISEASED_MONTH_REQUEST = "DISEASED_MONTH_REQUEST";
export const SET_MONTH_PERIOD_DATA = "SET_MONTH_PERIOD_DATA";

export const diseasedMonthReducer = combineReducers({
    list: diseasedMonthListReducer,
    loader: diseasedMonthLoaderReducer,
    period: diseasedMonthPeriodReducer
});

//actions
export const diseasedMonthActions = createActions('diseasedMonth', DISEASED_MONTH_REQUEST);
export const setMonthPeriodData = period => ({
    type: SET_MONTH_PERIOD_DATA,
    payload: period
});

// SAGAS
export function* diseasedMonthWatcher() {
    yield takeLatest(`${DISEASED_MONTH_REQUEST}${START}`, diseasedMonthWorker)
}

function* diseasedMonthWorker({ payload }) {
    try {
        const response = yield call(reportsAPI.diseasedMonthReport, payload);
        yield put(diseasedMonthActions.diseasedMonthRequestSuccess(response.data.Answer.Data_));
        yield put(setMonthPeriodData(payload))
    } catch(error) {
        yield put(diseasedMonthActions.diseasedMonthRequestError(error));
    }
}
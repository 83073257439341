import React from 'react'
import './spinner.css'

const Spinner = ({
  top = null
}) => {
  return (
    <div className='lds-css ng-scope' style={{marginTop: top}}>
      <div className='lds-spin'>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
        <div>
          <div></div>
        </div>
      </div>
    </div>
  )
}

export default Spinner

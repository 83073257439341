import {combineReducers} from "redux";
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {reportsAPI} from "../../../api/reports";
import { createActions } from "../../factory/actionFactory";
import {START} from "../../../constants/action-postfix";
import { loaderReducer } from "./loader/loaderReducer";
import { formReducer } from "./form/formReducer";
import { dataReducer } from "./data/dataReducer";
import { yearReducer } from "./year/yearReducer";



export const GRADUATES_REQUEST = "GRADUATES_REQUEST";
export const SET_FORM_DATA = "SET_GRADUATES_FORM_DATA";
export const SET_YEAR = "SET_GRADUATES_YEAR";

export const graduatesReducer = combineReducers({
    form: formReducer,
    data: dataReducer,
    loader: loaderReducer,
    year: yearReducer
});

//actions
export const graduatesActions = createActions('graduates', GRADUATES_REQUEST);
export const setFormData = payload => ({ type: SET_FORM_DATA, payload});
export const setYear = payload => ({ type: SET_YEAR, payload});

// SAGAS
export function* graduatesWatcher() {
    yield takeLatest(`${GRADUATES_REQUEST}${START}`, graduatesWorker)
}

function* graduatesWorker() {
    try {
        const auth = yield select(state => state.auth.data)
        const form = yield select(state => state.reports.graduates.form)
        const params = {...auth, ...form}
        const response = yield call(reportsAPI.graduates, params);
        yield put(graduatesActions.graduatesRequestSuccess(response.data.Answer.Data));
        yield put(setYear(form.year));
    } catch(error) {
        yield put(graduatesActions.graduatesRequestError(error));
    }
}
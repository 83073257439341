import React, { useCallback, useMemo } from 'react'
import PropTypes from 'prop-types'
import SubTable from './SubTable'
import SubPDFTable from './SubPDFTable'
import SummaryPDFRow from './SummaryPDFRow'
import ExcelExporter from '../../Diseased/ExcelExporter'
import { Typography } from 'antd'
import SummaryRow from './SummaryRow'
import moment from 'moment'

const KURSES = {
  "5": <td>5 кл.</td>,
  "6": <td>6 кл.</td>,
  "7": <td>7 кл.</td>,
  "8": <td>8 кл.</td>,
  "9": <td>9 кл.</td>,
  "10": <td>10 кл. <br/> (I курс)</td>,
  "11": <td>11 кл. <br/> (II курс)</td>,
  "12": <td>III курс</td>,
  "13": <td>IV курс</td>
}


const StudentsTotalTable = ({ data, requestDetails, dateto, foreignMode }) => {
  const { Text } = Typography

  const tableData = useMemo(() => {
    let result = []
    let allClasses = {}
    const classes = [].concat
      .apply([], data)
      .map(item => item.kurs_norm)
      .sort((a, b) => a - b)

    classes.forEach(item => {
      allClasses[item] = []
    })

    data.forEach(org => {
      let orgClasses = JSON.parse(JSON.stringify(allClasses))
      org.forEach(item => {
        orgClasses[item.kurs_norm].push(item)
      })
      result.push([[org[0].kurs_norm, org[0].orgName], Object.values(orgClasses)])
    })

    return result
  }, [data])

  const summaryData = useMemo(() => {
    return calculateSummaryData(tableData)
  }, [tableData])

  const formatDifference = useCallback((a, b) => {
    const difference = Number(a) - Number(b)
    const module = Math.abs(difference)

    if (difference > 0) {
      return (
        <Text type='success' strong>
          -{module}
        </Text>
      )
    } else if (difference < 0) {
      return (
        <Text type='danger' strong>
          +{module}
        </Text>
      )
    }
    return <Text strong>{module}</Text>
  }, [])

  const formatDifferencePDF = useCallback((a, b) => {
    const difference = Number(a) - Number(b)
    const module = Math.abs(difference)

    if (difference > 0) {
      return '+' + module
    } else if (difference < 0) {
      return -module
    }
    return module
  }, [])

  function calculateSummaryData(data) {
    let result = []

    if (!data.length) return result

    data.forEach(org => {
      org[1].forEach((cls, idx) => {
        result[idx] = result[idx] || []
        if (cls.length) {
          let currentPlan = parseInt(cls[0].plan) || 0
          let currentFact = parseInt(cls[0].fact) || 0

          result[idx].plan = result[idx].plan || 0
          result[idx].fact = result[idx].fact || 0

          result[idx].plan += currentPlan
          result[idx].fact += currentFact
        }
      })
    })
    return result
  }


  const factKurses = React.useMemo(() => Array.from(new Set(data[0] ? data.flat().map(item => item.kurs_norm) : [])), [data])


  const PDFTable = React.useMemo(() => {
    
    return (
      <table border='1' cellPadding='5' id='studentsTotalPDFTable' style={{ display: 'none' }}>
        <thead>
          <tr>
            <td colSpan={factKurses.length + 4}>
              Численность переменного состава в довузовских образовательных организациях Министерства обороны Российской Федерации
              на {moment(dateto).format('DD.MM.YYYY')} г.
            </td>
          </tr>
          <tr>
            <td>№ <br/> п/п</td>
            <td>Наименование образовательной организации</td>
            <td>Численность</td>
            {factKurses.map(kurs => KURSES[kurs])}
            <td>Всего</td>
          </tr>
        </thead>
        <tbody>
          {tableData.map((item, index) => (
            <SubPDFTable key={index} dataIndex={index} data={item} formatDifferencePDF={formatDifferencePDF} />
          ))}
          {tableData.length > 1 && <SummaryPDFRow data={summaryData} formatDifferencePDF={formatDifferencePDF} />}
        </tbody>
      </table>
    )
  }, [tableData, summaryData, dateto, formatDifferencePDF])

  return (
    <ExcelExporter sheetName='Переменный состав' title='Переменный состав.xlsx' isChief>
      <div style={{height:"100%", maxHeight: '90vh', overflow: 'auto', overscrollBehaviorY: 'auto'}}>
        <table border='1' cellPadding='5'>
          <tbody>
            {tableData.map((item, index) => (
                <React.Fragment key={index}>
                  <tr>
                    <td
                      rowSpan={foreignMode ? 5 : 7}
                      style={{
                        width: '50px',
                        textAlign: 'center',
                        borderBottom: '2px solid',
                        borderLeft: '2px solid',
                        borderTop: '2px solid',
                      }}
                    >
                      {index + 1}
                    </td>
                    <td
                      rowSpan={foreignMode ? 5 : 7}
                      style={{
                        width: '300px',
                        borderBottom: '2px solid',
                        borderTop: '2px solid',
                        borderRight: '2px solid',
                      }}
                    >
                      {item[0][1]}
                    </td>
                  </tr>
  
                  <SubTable
                    data={item}
                    requestDetails={requestDetails}
                    formatDifference={formatDifference}
                    foreignMode={foreignMode}
                  />
                </React.Fragment>
              ))}
            {tableData.length > 1 && (
              <>
                <tr>
                  <td
                    rowSpan={foreignMode ? 4 : 6}
                    colSpan='2'
                    style={{ width: '250px', textAlign: 'center' }}
                  >
                    <Text strong>ИТОГО:</Text>
                  </td>
                </tr>
                <SummaryRow
                  data={summaryData}
                  formatDifference={formatDifference}
                  foreignMode={foreignMode}
                />
              </>
            )}
          </tbody>
        </table>
      </div>
      {PDFTable}
      <div></div>
    </ExcelExporter>
  )
}

StudentsTotalTable.propTypes = {
  data: PropTypes.array,
  requestDetails: PropTypes.func,
}

export default StudentsTotalTable

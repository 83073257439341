import { ERROR, START, SUCCESS } from '../../../../constants/action-postfix'
import { SET_FOREIGN_MODE } from '../../../types'
import { STUDENTS_TOTAL_REQUEST } from '../studentsTotalReducer'

const initialState = {
  data: [],
  foreign: false,
  isLoading: false,
  error: null,
}

export const listReducer = (state = initialState, action) => {
  switch (action.type) {
    case `${STUDENTS_TOTAL_REQUEST}${START}`:
      return {
        data: [],
        isLoading: true,
        error: null,
      }
    case `${STUDENTS_TOTAL_REQUEST}${SUCCESS}`:
      return {
        data: action.payload,
        isLoading: false,
        error: null,
      }

    case `${STUDENTS_TOTAL_REQUEST}${ERROR}`:
      return {
        data: null,
        isLoading: false,
        error: action.payload,
      }
    case SET_FOREIGN_MODE:
      return {
        ...state, 
        foreign: action.payload
      }
    default:
      return state
  }
}

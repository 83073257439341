import {combineReducers} from "redux";
import { call, put, takeLatest } from 'redux-saga/effects';
import {reportsAPI} from "../../../api/reports";
import {START} from "../../../constants/action-postfix";
import { createActions } from "../../factory/actionFactory";
import { vaccinatedListReducer } from "./list/vaccinatedListReducer";
import { vaccinatedLoaderReducer } from "./loader/vaccinatedLoaderReducer";
import { vaccinatedFormReducer } from "./form/vaccinatedFormReducer";



export const VACCINATED_REQUEST = "VACCINATED_REQUEST";
export const SET_FORM_DATA = "SET_VACCINATED_FORM_DATA";

export const vaccinatedReducer = combineReducers({
    list: vaccinatedListReducer,
    loader: vaccinatedLoaderReducer,
    form: vaccinatedFormReducer
});

//actions
export const vaccinatedActions = createActions('vaccinated', VACCINATED_REQUEST);
export const setPeriodFormData = data => ({
    type: SET_FORM_DATA,
    payload: data
});

// SAGAS
export function* vaccinatedWatcher() {
    yield takeLatest(`${VACCINATED_REQUEST}${START}`, vaccinatedWorker)
}

function* vaccinatedWorker({ payload }) {
    try {
        const response = yield call(reportsAPI.vaccinated, payload);
        yield put(vaccinatedActions.vaccinatedRequestSuccess(response.data.Answer.Data));
    } catch(error) {
        yield put(vaccinatedActions.vaccinatedRequestError(error));
    }
}
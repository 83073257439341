import React from 'react';
import PropTypes from 'prop-types';
import styles from "../../Week/Table/DiseasedWeekTable.module.css";
import Table from "../../../../Table";
import ExcelExporter from "../../ExcelExporter";


const DiseasedMonthTable = ({data, period}) => {

    const columns = React.useMemo(
        () => [
            {
                Header: 'Параллель',
                columns: [{
                    Header: null,
                    accessor: 'kurs'
                }],
                merged: true
            },
            ...data.periods ? data.periods.map(period => ({
                Header: period.period,
                columns: [
                    {
                        Header: 'Кол-во обуч-ся',
                        accessor: `${period.period_order}_0`,
                    },
                    {
                        Header: 'Кол-во заболевших',
                        accessor: `${period.period_order}_1`,
                    },
                ],
            })) : []
        ], [data]
    );

    const computeData = (source) => {

        let result = [];
        let currentKurs = '';
        let currentRow = {};

        source.forEach((item, index) => {
            const { kurs, period_order, value, col_order } = item;

            if(currentKurs !== kurs) {
                currentKurs = kurs;
                if(!!Object.entries(currentRow).length) {
                    result.push(currentRow);
                    currentRow = {};
                }
                currentRow.kurs = currentKurs;
            }
            currentRow[`${period_order}_${col_order}`] = value;
        });

        result.push(currentRow);

        return result
    };

    return (
        <div>
            <ExcelExporter>
                {data.organizations && data.organizations.map((org, index) =>
                    <div key={org.idOrganization} className={styles.container}>
                        <h3 className={'text-center mb-2'}>{org.OrgName}</h3>
                        {period &&
                        <h4 className={'text-center mb-2'}>Сведения о заболевших за {period.month} {period.year}г.</h4>
                        }

                        <Table data={computeData(org.Values)} columns={columns} />
                    </div>
                )}
            </ExcelExporter>
        </div>
    );
};

DiseasedMonthTable.propTypes = {
    data: PropTypes.object,
};

export default DiseasedMonthTable;
import moment from "moment";
import {SET_COVID_FORM_DATA} from "../diseasedCovidReducer";

const initialState = {
    'school': false,
    'idOrg': "0",
    'date': moment().format('YYYY-MM-DD'),
};

export const formReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_COVID_FORM_DATA:
            return action.payload;
        default:
            return state;
    }
};

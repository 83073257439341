import React from 'react'
import PropTypes from 'prop-types'
import { Page, Text, View, Document, StyleSheet, Font } from '@react-pdf/renderer'
import { pdfStyles } from './PdfStyles'

const PdfReports = ({ data, name, TableHeader, TableItem }) => {
  const rowsFirstPage = 22
  const rowsAnotherPage = 23
  let pagesRowsCount = [0]
  let pagesRowsCountWithoutLast
  let arrayLength = data.length
  let currentValue = 0

  if (arrayLength > rowsFirstPage) {
    pagesRowsCount.push(rowsFirstPage)
    arrayLength -= rowsFirstPage
    currentValue = rowsFirstPage

    while (true) {
      if (rowsAnotherPage < arrayLength) {
        arrayLength -= rowsAnotherPage
        currentValue += rowsAnotherPage
        pagesRowsCount.push(currentValue)
      } else {
        pagesRowsCount.push(currentValue + arrayLength)
        break
      }
    }
  } else {
    pagesRowsCount.push(arrayLength)
  }

  pagesRowsCountWithoutLast = [...pagesRowsCount]
  pagesRowsCountWithoutLast.pop(0)

  return (
    <Document>
      {pagesRowsCountWithoutLast.map((count, idx) => (
        <React.Fragment key={`pdfPage${idx}`}>
          <Page size='A4' wrap style={pdfStyles.page}>
            {idx === 0 && (
              <View>
                <Text style={pdfStyles.textX}>{name}</Text>
              </View>
            )}
            <View style={pdfStyles.table}>
              <TableHeader />
              {data.map((item, index) => (
                <React.Fragment key={`pdfItem${index}`}>
                  {index >= pagesRowsCount[idx] && index < pagesRowsCount[idx + 1] && <TableItem item={item} index={index} />}
                </React.Fragment>
              ))}
              <Text style={pdfStyles.textZ}>{idx + 1}</Text>
            </View>
          </Page>
        </React.Fragment>
      ))}
    </Document>
  )
}

PdfReports.propTypes = {
  name: PropTypes.string,
  data: PropTypes.array,
}

export default PdfReports

import React from 'react'
import { useDispatch, useSelector } from 'react-redux';
import { Button, Col, FormCheckbox, FormSelect, Row } from 'shards-react'
import { graduatesActions, setFormData } from '../../../../redux/reducers/Graduates/graduatesReducer';
import styles from '../styles.module.css'


const year = new Date().getFullYear();
const yearsList = [Number(year) - 1, Number(year), Number(year) + 1];

const { graduatesRequestStart } = graduatesActions

const Form = ({ form }) => {
  
  const dispatch = useDispatch()
  
  const setYearHandler = (e) => {
    const { value } = e.target
    dispatch(setFormData({ ...form, year: value }))
  }

  const setSchoolModeHandler = () => {
    const school = form.school == 0 ? 1 : 0
    dispatch(setFormData({ ...form, school }))
  }

  const onSubmit = () => {
    dispatch(graduatesRequestStart())
  }

  return (
    <Row className={styles.formRow}>
        
        <Col md={3}>
          <label htmlFor="year">Выберите год</label>
          <FormSelect
              id='year'
              size="sm"
              value={form.year}
              onChange={setYearHandler}
          >
              {yearsList.map(year => <option key={year} value={year} selected={year === form.year}>{year}</option>)}
          </FormSelect>
        </Col>

        <Col md={3}>
          <FormCheckbox
            className={styles.checkbox}
            checked={form.school}
            onChange={setSchoolModeHandler}
          >
            Школы
          </FormCheckbox>
        </Col>

        <Button onClick={onSubmit}>Создать отчет</Button>

    </Row>
  )
}

export default Form
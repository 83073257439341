import {SET_CANDIDATES_PARAMS_DATA} from "../candidatesReducer";


const initialState = {
    'status': '',
    'idOrg': 0
};

export const candidatesParamsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CANDIDATES_PARAMS_DATA:
            return action.payload;
        default:
            return state;
    }
};
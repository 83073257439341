import React from 'react'
import { FileSharingReceivedTable as Table } from './Table'
import { FileSharingReceivedForm as Form } from './Form'

export const FileSharingReceived = () => {
  return (
    <div className='mt-2'>
      <Form />
      <Table />
    </div>
  )
}

import { useFormik } from 'formik'
import moment from 'moment'
import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, FormGroup, FormInput, FormSelect, Row } from 'shards-react'
import { getAuthData } from '../../../../redux/reducers/auth-reducer'
import { fileSharingActions } from '../../../../redux/reducers/fileSharing/fileSharingReducer'
import { getOrgList } from '../../../../redux/reducers/organization-list-reducer'

export const FileSharingReceivedForm = () => {
  const dispatch = useDispatch()
  const auth = useSelector(getAuthData)
  const orgList = useSelector(getOrgList)

  const formik = useFormik({
    initialValues: {
      fromDate: moment(new Date()).subtract(1, 'M').format('YYYY-MM-DD'),
      toDate: moment(new Date()).format('YYYY-MM-DD'),
      idOrg: 0,
      receive: 1,
    },
    onSubmit: values => {
      dispatch(fileSharingActions.listRequestStart({ ...auth, ...values }))
    },
  })

  return (
    <Row className={'my-3 justify-content-between'}>
      <Col md={'auto'} className={'d-flex align-items-center'}>
        <FormGroup className={'mr-3'}>
          <label htmlFor='_to'>Дата с</label>
          <FormInput size='sm' type={'date'} name={'fromDate'} value={formik.values.fromDate} onChange={formik.handleChange} />
        </FormGroup>

        <FormGroup className={'mr-3'}>
          <label htmlFor='_to'>Дата по</label>
          <FormInput size='sm' type={'date'} name={'toDate'} value={formik.values.toDate} onChange={formik.handleChange} />
        </FormGroup>

        <FormGroup className={'mr-3'}>
          <label htmlFor='idOrg'>Училище</label>
          <FormSelect
            size='sm'
            id='idOrg'
            name='idOrg'
            onChange={formik.handleChange}
            value={formik.values.idOrg}
            className='d-block'
          >
            <option value={0}>Все</option>
            {orgList.map((e, i) => (
              <option key={i} value={e.idOrganization}>
                {e.orgName}
              </option>
            ))}
          </FormSelect>
        </FormGroup>

        <Button onClick={formik.handleSubmit}>Обновить</Button>
      </Col>
    </Row>
  )
}

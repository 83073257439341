import React from 'react'

export const TableFooter = ({ data }) => {
  const newData = { 5: 0, 6: 0, 7: 0, 8: 0, 9: 0, 10: 0, 11: 0 }
  data.forEach((e, i) => {
    if ([5, 6, 7, 8, 9, 10, 11].some(el => el == e.class_to_enter)) {
      newData[e.class_to_enter] += +e.cnt
    }
  })
  return (
    <tr>
      <td colSpan='2'>Всего</td>
      <td></td>
      {Object.values(newData).map(e => (
        <td>{e}</td>
      ))}
      <td>
        {Object.values(newData).reduce((a, c) => {
          return a + c
        }, 0)}
      </td>
    </tr>
  )
}

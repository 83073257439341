import React from 'react';
import PropTypes from 'prop-types';

import styles from './RouteLayout.module.css';

const RouteLayout = ({ title, children }) => {
    return (
        <div className={styles.container}>
            <div className={styles.title}>
                { title }
            </div>

            { children }
        </div>
    );
};

RouteLayout.propTypes = {
    title: PropTypes.string,
};

export default RouteLayout;
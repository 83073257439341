import {SUCCESS} from "../../../../constants/action-postfix";
import {DUPLICATES_REQUEST} from "../duplicatesReducer";


const initialState = [];

export const duplicatesListReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${DUPLICATES_REQUEST}${SUCCESS}`:
            return action.payload
        default:
            return state;
    }
};
import { call, put, takeEvery } from 'redux-saga/effects'
import { fileSharingListRequest } from '../../../api/fileSharing'
import { START, SUCCESS } from '../../../constants/action-postfix'
import { createActions } from '../../factory/actionFactory'

export const FILE_SHARING_LIST_REQUEST = 'FILE_SHARING_LIST_REQUEST'
export const FILE_SHARING_SENT_LOADER = 'FILE_SHARING_SENT_LOADER'
export const FILE_SHARING_RECEIVED_LOADER = 'FILE_SHARING_RECEIVED_LOADER'

export const fileSharingActions = {
  ...createActions('list', FILE_SHARING_LIST_REQUEST),
  ...createActions('sentLoading', FILE_SHARING_SENT_LOADER),
  ...createActions('receivedLoading', FILE_SHARING_RECEIVED_LOADER),
}

const initialState = {
  sentList: [],
  sentLoading: false,
  receivedList: [],
  receivedLoading: false,
}

export const fileSharingReducer = (state = initialState, action) => {
  const { type, payload } = action

  switch (type) {
    case `${FILE_SHARING_LIST_REQUEST}${SUCCESS}`:
      // listType = 'sentList' || 'receivedList'
      const { listType, list } = payload
      return { ...state, [listType]: list }

    case `${FILE_SHARING_SENT_LOADER}${START}`:
      return { ...state, sentLoading: true }

    case `${FILE_SHARING_SENT_LOADER}${SUCCESS}`:
      return { ...state, sentLoading: false }

    case `${FILE_SHARING_RECEIVED_LOADER}${START}`:
      return { ...state, receivedLoading: true }

    case `${FILE_SHARING_RECEIVED_LOADER}${SUCCESS}`:
      return { ...state, receivedLoading: false }

    default:
      return state
  }
}

export function* fileSharingWatcher() {
  yield takeEvery(`${FILE_SHARING_LIST_REQUEST}${START}`, listRequestWorker)
}

function* listRequestWorker({ payload }) {
  const loaderType = payload.receive === 0 ? 'sentLoading' : 'receivedLoading'
  const listType = payload.receive === 0 ? 'sentList' : 'receivedList'

  try {
    yield put(fileSharingActions[`${loaderType}RequestStart`]())
    const res = yield call(fileSharingListRequest, payload)
    yield put(fileSharingActions.listRequestSuccess({ list: res.data.Answer.Files, listType }))
  } catch (error) {
    console.log(error)
  } finally {
    yield put(fileSharingActions[`${loaderType}RequestSuccess`]())
  }
}

import React from "react";
import RouteLayout from "../../RouteLayout";
import DuplicatesTable from "./DuplicatesTable/DuplicatesTable";
import { connect } from "react-redux";
import DuplicatesReportForm from "./Form/DuplicatesReportForm";
import {
  setDuplicatesFormData,
  duplicatesActions
} from "../../../redux/reducers/Duplicates/duplicatesReducer";
import Spinner from "../../common/Spinner";


export const STATUS_LIST = [
  {
    value: 0,
    name: 'Все',
  },
  {
    value: 1,
    name: 'Допущенные к вступительным испытаниям',
  },
  {
    value: 3,
    name: 'Прошедшие вступительные испытания',
  }
]

function Duplicates({
  formData,
  setFormData,
  requestReport,
  auth,
  data,
  isLoading,
}) {
  
  const requestReportHandler = () => {
    const params = {
      school: formData.school ? 1 : 0,
      year: formData.year,
      status: formData.status,
      stream: formData.stream
    };
    requestReport({ ...auth, ...params });
  };


  return (
    <RouteLayout title={"Отчет по двойникам из числа кандидатов на поступление"}>
      <DuplicatesReportForm
        formData={formData}
        setFormData={setFormData}
        onSubmit={requestReportHandler}
      />
      {isLoading ? <Spinner /> : !!data.length && <DuplicatesTable data={data}/>}
    </RouteLayout>
  );
}

const mapStateToProps = (state) => ({
  formData: state.reports.duplicates.form,
  auth: state.auth.data,
  isLoading: state.reports.duplicates.loader,
  data: state.reports.duplicates.list,
});

const mapDispatchToProps = {
  setFormData: setDuplicatesFormData,
  requestReport: duplicatesActions.duplicatesRequestStart,
};

export default connect(mapStateToProps, mapDispatchToProps)(Duplicates);

import {SET_DETAILS_FORM_DATA} from "../privilegesReducer";


const initialState = {};

export const detailsParamsReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${SET_DETAILS_FORM_DATA}`:
            return action.payload;

        default:
            return state;
    }
};
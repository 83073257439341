import {SUCCESS} from "../../../../../constants/action-postfix";
import {DISEASED_COVID_REQUEST} from "../diseasedCovidReducer";


const initialState = [];

export const listReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${DISEASED_COVID_REQUEST}${SUCCESS}`:
            return action.payload;

        default:
            return state;
    }
};

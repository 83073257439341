import {combineReducers} from "redux";
import { call, put, select, takeLatest } from 'redux-saga/effects';
import {reportsAPI} from "../../../api/reports";
import { createActions } from "../../factory/actionFactory";
import {START} from "../../../constants/action-postfix";
import { privilegesListReducer } from "./list/privilegesListReducer";
import { privilegesLoaderReducer } from "./loader/privilegesLoaderReducer";
import { privilegesFormReducer } from "./form/privilegesFormReducer";
import { privilegesDetailsReducer } from "./details/privilegesDetailsReducer";
import { dataByOrg, dataByParallel } from "../../../utils/transformPrivilegesData";
import { detailsParamsReducer } from "./detailsParams/detailsParamsReducer";
import { modeReducer } from "./mode/modeReducer";



export const PRIVILEGES_REQUEST = "PRIVILEGES_REQUEST";
export const PRIVILEGES_DETAILS_REQUEST = "PRIVILEGES_DETAILS_REQUEST";
export const SET_FORM_DATA = "SET_PRIVILEGES_FORM_DATA";
export const SET_DETAILS_FORM_DATA = "SET_DETAILS_FORM_DATA";
export const SET_PRIVILEGES_MODE = "SET_PRIVILEGES_MODE";

export const privilegesReducer = combineReducers({
    form: privilegesFormReducer,
    list: privilegesListReducer,
    details: privilegesDetailsReducer,
    details_params: detailsParamsReducer,
    parallel_mode: modeReducer,
    loader: privilegesLoaderReducer
});

//actions
export const privilegesActions = createActions('privileges', PRIVILEGES_REQUEST);
export const privilegesDetailsActions = createActions('privilegesDetails', PRIVILEGES_DETAILS_REQUEST);
export const setFormData = payload => ({ type: SET_FORM_DATA, payload});
export const setDetailsFormData = payload => ({ type: SET_DETAILS_FORM_DATA, payload});
export const setPrivilegesMode = payload => ({ type: SET_PRIVILEGES_MODE, payload});

// SAGAS
export function* privilegesWatcher() {
    yield takeLatest(`${PRIVILEGES_REQUEST}${START}`, privilegesWorker)
    yield takeLatest(`${PRIVILEGES_DETAILS_REQUEST}${START}`, detailsWorker)
}

function* privilegesWorker({ payload }) {
    try {
        yield put(privilegesDetailsActions.privilegesDetailsRequestSuccess([]));
        const response = yield call(reportsAPI.privilegesList, payload);
        const parallelMode = !!payload.groupmode
        const dataModifyFunction = parallelMode ? dataByParallel : dataByOrg
        yield put(setPrivilegesMode(parallelMode))
        yield put(privilegesActions.privilegesRequestSuccess(dataModifyFunction(response.data.Answer.Data)));
    } catch(error) {
        yield put(privilegesActions.privilegesRequestError(error));
    }
}

function* detailsWorker({ payload }) {
    try {
        const {both, ...restParams} = payload
        const auth = yield select(state => state.auth.data)
        const {groupmode, ...form} = yield select(state => state.reports.privileges.form)
        const params = {...auth, ...form, ...restParams}
        const response = yield call(reportsAPI.privilegesDetails, params);
        yield put(privilegesDetailsActions.privilegesDetailsRequestSuccess(response.data.Answer.Data));
        yield put(setDetailsFormData(payload));
    } catch(error) {
        yield put(privilegesDetailsActions.privilegesDetailsRequestError(error));
    }
}
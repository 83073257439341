import {combineReducers} from "redux";
import { call, put, takeLatest } from 'redux-saga/effects';
import {reportsAPI} from "../../../api/reports";
import {START} from "../../../constants/action-postfix";
import { phonebookListReducer } from "./list/phonebookListReducer";
import { phonebookLoaderReducer } from "./loader/phonebookLoaderReducer";
import { phonebookFormReducer } from "./form/phonebookFormReducer";
import { createActions } from "../../factory/actionFactory";



export const PHONEBOOK_REQUEST = "PHONEBOOK_REQUEST";
export const SET_FORM_DATA = "SET_PERIOD_DATA";

export const phonebookReducer = combineReducers({
    list: phonebookListReducer,
    loader: phonebookLoaderReducer,
    form: phonebookFormReducer
});

//actions
export const phonebookActions = createActions('phonebook', PHONEBOOK_REQUEST);
export const setPeriodFormData = data => ({
    type: SET_FORM_DATA,
    payload: data
});

// SAGAS
export function* phonebookWatcher() {
    yield takeLatest(`${PHONEBOOK_REQUEST}${START}`, phonebookWorker)
}

function* phonebookWorker({ payload }) {
    try {
        const response = yield call(reportsAPI.phoneBook, payload);
        yield put(phonebookActions.phonebookRequestSuccess(response.data.Answer.Data_.schools));
    } catch(error) {
        yield put(phonebookActions.phonebookRequestError(error));
    }
}
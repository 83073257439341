import React, { useEffect } from 'react'
import { Tab, Tabs } from 'react-bootstrap'
import { useDispatch, useSelector } from 'react-redux'
import { getAuthData } from '../../../redux/reducers/auth-reducer'
import { organizationList } from '../../../redux/reducers/organization-list-reducer'
import RouteLayout from '../../RouteLayout'
import { FileSharingReceived as Received } from './Received'
import { FileSharingSent as Sent } from './Sent'

export const FileSharing = () => {
  const dispatch = useDispatch()
  const auth = useSelector(getAuthData)

  useEffect(() => {
    dispatch(organizationList.organizationListRequestStart(auth))
  }, [dispatch])

  return (
    <RouteLayout title='Обмен файлами'>
      <Tabs defaultActiveKey='Sent' id='controlled-tab-example'>
        <Tab eventKey='Sent' title='Отправленные'>
          <Sent />
        </Tab>
        <Tab eventKey='Received' title='Полученные'>
          <Received />
        </Tab>
      </Tabs>
    </RouteLayout>
  )
}

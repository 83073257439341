import {SET_YEAR} from "../graduatesReducer";
import moment from 'moment'


const initialState = moment().year();

export const yearReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_YEAR:
            return action.payload;
        default:
            return state;
    }
};
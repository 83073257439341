import React from 'react'
import { useSelector } from 'react-redux'

import RouteLayout from '../../RouteLayout'
import { Buttons, Details, Form, Table } from './Components'
import Spinner from '../../common/Spinner'


export default function Privileges () {
  
  const { loader } = useSelector(state => state.reports.privileges)
  
  
  return (
    <RouteLayout title={'Отчет по льготам переменного состава'}>
        <Form/>
        <Buttons/>
        {loader ? <Spinner /> : <Table/>}
        <Details/>
    </RouteLayout>
  )
}

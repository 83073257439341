import React from 'react'

import { Button, Col, FormCheckbox, FormGroup, FormInput, Row } from 'shards-react'

const VaccinatedReportForm = ({ onSubmit, setFormData, formData }) => {
  const handleInputChange = e => {
    setFormData({
      ...formData,
      [e.target.type]: e.target.value,
    })
  }

  return (
    <Row className={"my-3 justify-content-between"}>
        <Col md={"auto"} className={"d-flex align-items-center"}>
          <FormGroup className={"mr-3"}>
            <label htmlFor="_to">Дата</label>
            <FormInput
              size="sm"
              type={"date"}
              className="mb-2"
              value={formData.date}
              onChange={handleInputChange}
            />
          </FormGroup>
          <FormCheckbox
            onChange={() =>
              setFormData({
                ...formData,
                school: !formData.school,
              })
            }
            name={'school'}
            checked={formData.school}
            className={'mr-3'}
          >
            Школы
          </FormCheckbox>
          <Button onClick={onSubmit}>Создать отчет</Button>
        </Col>
      </Row>
  )
}


export default VaccinatedReportForm

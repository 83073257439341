import { all } from 'redux-saga/effects'
import { authWatcher } from '../reducers/auth-reducer'
import { generalStatisticsOneWatcher } from '../reducers/general-statistic-one-reducer'
import { generalStatisticsAllWatcher } from '../reducers/general-statistic-all-reducer'
import { organizationListWatcher } from '../reducers/organization-list-reducer'
import { bestSchoolWatcher } from '../reducers/BestSchool/bestSchoolReducer'
import { diseasedWeekWatcher } from '../reducers/Diseased/Week/diseasedWeekReducer'
import { diseasedMonthWatcher } from '../reducers/Diseased/Month/diseasedMonthReducer'
import { diseasedPeriodWatcher } from '../reducers/Diseased/Period/diseasedPeriodReducer'
import { candidatesWatcher } from '../reducers/Candidates/candidatesReducer'
import { diseasedCovidWatcher } from '../reducers/Diseased/Covid/diseasedCovidReducer'
import { studentsTotalWatcher } from '../reducers/StudentsTotal/studentsTotalReducer'
import { fileSharingWatcher } from '../reducers/fileSharing/fileSharingReducer'
import { phonebookWatcher } from '../reducers/Phonebook/phonebookReducer'
import { vaccinatedWatcher } from '../reducers/Vaccinated/vaccinatedReducer'
import { duplicatesWatcher } from '../reducers/Duplicates/duplicatesReducer'
import { connectFailsWatcher } from '../reducers/ConnectFails/connectFailsReducer'
import { privilegesWatcher } from '../reducers/Priveleges/privilegesReducer'
import { graduatesWatcher } from '../reducers/Graduates/graduatesReducer'

export default function* rootSaga() {
  yield all([
    fileSharingWatcher(),
    authWatcher(),
    generalStatisticsAllWatcher(),
    generalStatisticsOneWatcher(),
    organizationListWatcher(),
    bestSchoolWatcher(),
    diseasedWeekWatcher(),
    diseasedMonthWatcher(),
    diseasedPeriodWatcher(),
    diseasedCovidWatcher(),
    candidatesWatcher(),
    studentsTotalWatcher(),
    phonebookWatcher(),
    vaccinatedWatcher(),
    duplicatesWatcher(),
    connectFailsWatcher(),
    privilegesWatcher(),
    graduatesWatcher()
  ])
}

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Button, Col, FormRadio, FormGroup, FormSelect, Row, FormCheckbox } from 'shards-react'
import { organizationList } from '../../../../redux/reducers/organization-list-reducer'
import { useEffect } from 'react'
import { privilegesActions, setFormData } from '../../../../redux/reducers/Priveleges/privilegesReducer'
import styles from '../styles.module.css'


export default function Form() {
  
  const orgList = useSelector(state => state.reports.orgList.data)
  const auth = useSelector(state => state.auth.data)
  const { form } = useSelector(state => state.reports.privileges)
  const dispatch = useDispatch()

  useEffect(() => {
    if (orgList !== undefined && orgList.length === 0) {
      dispatch(organizationList.organizationListRequestStart(auth))
    }
  }, [])

  const handleOrgChange = e => {
    const {name, value} = e.target
    dispatch(setFormData({...form, [name]: value}))
  }
  
  const handleModeChange = (name, value) => () => {
    dispatch(setFormData({...form, [name]: value}))
  }
  
  const onSubmit = () => {
    dispatch(privilegesActions.privilegesRequestStart({...auth, ...form}))
  }
  
  return (
    <Row className={'my-3 justify-content-between'}>
      <Col md={'auto'} className={'d-flex align-items-center'}>
        
        <FormGroup className={'mr-3'}>
          <label htmlFor='_idOrg'>Выберите организацию</label>
          <FormSelect
            name='idOrg'
            id='_idOrg'
            size='sm'
            className='mb-2 d-block'
            value={form.idOrg}
            onChange={handleOrgChange}
            style={{ width: 730 }}
          >
            <option value={0}>Все организации</option>
            {!!orgList && orgList.map(item => (
              <option key={item.idOrganization} value={item.idOrganization}>
                {item.orgName}
              </option>
            ))}
          </FormSelect>
        </FormGroup>

        <FormCheckbox
          style={{marginTop: '11px'}}
          onChange={handleModeChange('school', form.school ? 0 : 1)}
          name={'school'}
          checked={form.school}
          className={`mr-3 ${styles.checkboxBlock}`}
        >
          Школы
        </FormCheckbox>

        <FormGroup className={`mr-3 ${styles.radioBlock}`}>
            <FormRadio
              name='groupmode'
              checked={!form.groupmode}
              onChange={handleModeChange('groupmode', 0)}
              >
              По училищам
            </FormRadio>
            
            <FormRadio
              name='groupmode'
              checked={form.groupmode}
              onChange={handleModeChange('groupmode', 1)}
            >
              По параллелям
            </FormRadio>
        </FormGroup>

        <Button onClick={onSubmit}>Создать отчет</Button>
      </Col>
    </Row>
  )
}

import React from "react";
import RouteLayout from "../../RouteLayout";
import VaccinatedTable from "./VaccinatedTable/VaccinatedTable";
import { connect } from "react-redux";
import VaccinatedReportForm from "./Form/VaccinatedReportForm";
import {
  setPeriodFormData,
  vaccinatedActions,
} from "../../../redux/reducers/Vaccinated/vaccinatedReducer";
import Spinner from "../../common/Spinner";

function Vaccinated({
  formData,
  setFormData,
  requestReport,
  auth,
  data,
  isLoading,
}) {
  const requestReportHandler = () => {
    const params = {
      school: formData.school ? 1 : 0,
      onDate: formData.date,
    };
    requestReport({ ...auth, ...params });
  };

  return (
    <RouteLayout title={"Статистика по вакцинации от COVID-19"}>
      <VaccinatedReportForm
        formData={formData}
        setFormData={setFormData}
        onSubmit={requestReportHandler}
      />
      {isLoading ? <Spinner /> : !!data.length && <VaccinatedTable data={data}/>}
    </RouteLayout>
  );
}

const mapStateToProps = (state) => ({
  formData: state.reports.vaccinated.form,
  auth: state.auth.data,
  isLoading: state.reports.vaccinated.loader,
  data: state.reports.vaccinated.list,
});

const mapDispatchToProps = {
  setFormData: setPeriodFormData,
  requestReport: vaccinatedActions.vaccinatedRequestStart,
};

export default connect(mapStateToProps, mapDispatchToProps)(Vaccinated);

import React from 'react'
import { Typography } from 'antd'

import styles from './s.module.css'

const SummaryPDFRow = ({ data }) => {
  const { Text } = Typography

  const sumReducer = (arr, prop) => {
    const reducer = (accumulator, currentValue) => {
      let val = isNaN(currentValue[prop]) ? 0 : currentValue[prop]
      return accumulator + val
    }

    return arr.reduce(reducer, 0)
  }

  return (
    <>
      <tr>
        <td rowSpan='3' colSpan='2'>
          <Text strong>ИТОГО:</Text>
        </td>
        <td>Штатная</td>
        {data.map((item, index) => (
          <React.Fragment key={index}>
            <td className={styles.bold_border_right}>{item.plan || '-'}</td>
          </React.Fragment>
        ))}
        {/* <td>Штатная</td> */}
        <td>{sumReducer(data, 'plan')}</td>
      </tr>
      <tr>
        <td>Списочная</td>
        {data.map((item, index) => (
          <React.Fragment key={index}>
            <td className={styles.bold_border_right}>{item.fact || '-'}</td>
          </React.Fragment>
        ))}
        {/* <td>Списочная</td> */}
        <td>{sumReducer(data, 'fact')}</td>
      </tr>
      <tr className={styles.bold_border_bottom}>
        <td>Некомплект</td>
        {data.map((item, index) => (
          <React.Fragment key={index}>
            <td className={styles.bold_border_right}>{item.fact - item.plan}</td>
          </React.Fragment>
        ))}
        {/* <td>Разница</td> */}
        <td>{sumReducer(data, 'fact') - sumReducer(data, 'plan')}</td>
      </tr>
    </>
  )
}

export default SummaryPDFRow

import {combineReducers} from "redux";
import { call, put, takeLatest } from 'redux-saga/effects';
import {reportsAPI} from "../../../api/reports";
import {START} from "../../../constants/action-postfix";
import { connectFailsListReducer } from "./list/connectFailsListReducer";
import { connectFailsLoaderReducer } from "./loader/connectFailsLoaderReducer";
import { connectFailsFormReducer } from "./form/connectFailsFormReducer";
import { createActions } from "../../factory/actionFactory";
import { connectFailsDetailsReducer } from "./details/connectFailsDetailsReducer";



export const CONNECT_FAILS_REQUEST = "CONNECT_FAILS_REQUEST";
export const SET_FORM_DATA = "SET_CONNECT_FAILS_FORM_DATA";
export const SET_DETAILS = "SET_DETAILS";
export const SET_DETAILS_LOADING = "SET_DETAILS_LOADING";
export const GET_DETAILS_START = "GET_DETAILS_START";


export const connectFailsReducer = combineReducers({
    list: connectFailsListReducer,
    loader: connectFailsLoaderReducer,
    form: connectFailsFormReducer,
    details: connectFailsDetailsReducer
});

//actions
export const connectFailsActions = createActions('connectFails', CONNECT_FAILS_REQUEST);
export const setConnectFailsFormData = data => ({ type: SET_FORM_DATA, payload: data });

export const getDetailsStart = data => ({ type: GET_DETAILS_START, payload: data})

// SAGAS
export function* connectFailsWatcher() {
    yield takeLatest(`${CONNECT_FAILS_REQUEST}${START}`, connectFailsWorker)
    yield takeLatest(GET_DETAILS_START, getDetailsWorker)
}

function* connectFailsWorker({ payload }) {
    try {
        const response = yield call(reportsAPI.connectFails, payload);
        yield put(connectFailsActions.connectFailsRequestSuccess(response.data.Answer.Data));
    } catch(error) {
        yield put(connectFailsActions.connectFailsRequestError(error));
    }
}

function* getDetailsWorker({ payload }) {
    try {
        yield put({type: SET_DETAILS_LOADING, payload: true})
        const response = yield call(reportsAPI.connectFailsDetails, payload);
        yield put({type: SET_DETAILS, payload: response.data.Answer.Data});
    } catch(error) {
        yield put(connectFailsActions.connectFailsRequestError(error));
    } finally {
        yield put({type: SET_DETAILS_LOADING, payload: false})
    }
}
import { api } from "."
import { BASE_PARAMS } from "../constants/api"

export const getPerfGroups = (payload) => {
  const params = {
    ...BASE_PARAMS,
    ...payload,
    action: `${BASE_PARAMS.action}.perf_groups`,
  }
  return api.get(`/`, {params})  
}

export const getPerfoptions = (payload) => {
  const params = {
    ...BASE_PARAMS,
    ...payload,
    action: `${BASE_PARAMS.action}.perf_options_tree`,
  }
  return api.get(`/`, {params})  
}

export const getPerfValues = (authData, formData) => {
  const params = {
    ...BASE_PARAMS,
    ...authData,
    ...formData,
    action: `${BASE_PARAMS.action}.perf_values`,
  }
  return api.get(`/`, {params})  
}

export const setPerfValue = (authData, payload, year) => {
  const params = {
    ...BASE_PARAMS,
    ...authData,
    ...payload,
    year,
    action: `${BASE_PARAMS.action}.set_perf_value`,
  }
  return api.get(`/`, {params})  
}
import {SET_FORM_DATA} from "../duplicatesReducer";

const initialState = {
    'year': new Date().getFullYear(),
    'status': 0,
    'school': 0,
    'stream': 0
};

export const duplicatesFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FORM_DATA:
            return action.payload;
        default:
            return state;
    }
};

import React from 'react'
import { BrowserRouter, Route, Switch } from 'react-router-dom'
import ScrollToTop from '../common/Routing/ScrollToTop/index'
import Layout from './../Layout/'
import Login from './../Login/Login'
import PrivateRoute from './../common/Routing/PrivateRoute/index'
import { routes } from '../../constants/routes'
import BestSchoolReport from '../containers/BestSchool'

const Routes = () => {
  return (
    <BrowserRouter>
      <ScrollToTop />

      <Switch>
        <Route exact path='/reports/best_school/' component={BestSchoolReport} />

        <Layout>
          {routes.map(route => (
            <PrivateRoute exact key={route.path} {...route} />
          ))}
          <Route exact path='/auth' component={Login} />
        </Layout>
      </Switch>
    </BrowserRouter>
  )
}

export default Routes

import {combineReducers} from "redux";
import {createActions} from "../../../factory/actionFactory";
import { call, put, takeLatest } from 'redux-saga/effects';
import {START} from "../../../../constants/action-postfix";
import {reportsAPI} from "../../../../api/reports";
import {formReducer} from "./form/diseasedCovidFormReducer";
import {listReducer} from "./list/diseasedCovidListReducer";
import {loaderReducer} from "./loader/diseasedPeriodLoaderReducer";

export const DISEASED_COVID_REQUEST = "DISEASED_COVID_REQUEST";
export const SET_COVID_FORM_DATA = "SET_COVID_FORM_DATA";


export const diseaseCovidReducer = combineReducers({
    list: listReducer,
    loader: loaderReducer,
    form: formReducer
});

// ACTIONS
export const diseasedCovidActions = createActions('diseasedCovid', DISEASED_COVID_REQUEST);
export const setCovidFormData = period => ({
    type: SET_COVID_FORM_DATA,
    payload: period
});


// SAGAS
export function* diseasedCovidWatcher() {
    yield takeLatest(`${DISEASED_COVID_REQUEST}${START}`, diseasedCovidWorker)
}

function* diseasedCovidWorker({ payload }) {
    try {
        const response = yield call(reportsAPI.diseasedCovidReport, payload);
        yield put(diseasedCovidActions.diseasedCovidRequestSuccess(response.data.Answer.Data_));
    } catch(error) {
        yield put(diseasedCovidActions.diseasedCovidRequestError(error));
    }
}

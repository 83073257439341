import {SET_FORM_DATA} from "../graduatesReducer";
import moment from 'moment'


const initialState = {
    year: moment().year(),
    school: 0,
};

export const formReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FORM_DATA:
            return action.payload;
        default:
            return state;
    }
};

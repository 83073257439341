import Index from '../components/Layout/Index/Index'
import GeneralStatisticsAll from '../components/containers/GeneralStatisticsAll/GeneralStatisticsAll'
import GeneralStatisticsOne from '../components/containers/GeneralStatisticsOne/GeneralStatisticsOne'
import OrganizationList from '../components/containers/OrganizationList/OrganizationList'
import DiseasedWeek from '../components/containers/Diseased/Week'
import DiseasedMonth from '../components/containers/Diseased/Month'
import DiseasedPeriod from '../components/containers/Diseased/Period'
import Candidates from '../components/containers/Сandidates'
import DiseasedCovid from '../components/containers/Diseased/Covid'
import StudentsTotal from '../components/containers/StudentsTotal'
import { CasesNumber } from '../components/containers/CasesNumber'
import { FileSharing } from '../components/containers/FileSharing'
import Phonebook from '../components/containers/Phonebook/Phonebook'
import Vaccinated from '../components/containers/Vaccinated/Vaccinated'
import Duplicates from '../components/containers/Duplicates/Duplicates'
import ConnectionFails from '../components/containers/ConnectionFails/ConnectionFails'
import CandidatesCons from '../components/containers/СandidatesCons'
import Privileges from '../components/containers/Privileges'
import Graduates from '../components/containers/Graduates'

export const routes = [
  {
    path: '/reports/general_statistic_all',
    component: GeneralStatisticsAll,
  },
  {
    path: '/reports/general_statistic_one/:id?',
    component: GeneralStatisticsOne,
  },
  {
    path: '/organization',
    component: OrganizationList,
  },
  {
    path: '/reports/diseased_week',
    component: DiseasedWeek,
  },
  {
    path: '/reports/diseased_month',
    component: DiseasedMonth,
  },
  {
    path: '/reports/diseased_period',
    component: DiseasedPeriod,
  },
  {
    path: '/reports/diseased_covid',
    component: DiseasedCovid,
  },
  {
    path: '/reports/candidates',
    component: Candidates,
  },
  {
    path: '/reports/students_total',
    component: StudentsTotal,
  },
  {
    path: '/reports/cases_number',
    component: CasesNumber,
  },
  {
    path: '/reports/phonebook',
    component: Phonebook
  },
  {
    path: '/reports/vaccinated_covid',
    component: Vaccinated
  },
  {
    path: '/reports/duplicates',
    component: Duplicates
  },
  {
    path: ['/', '/reports', '/publishers'],
    component: Index,
  },
  {
    path: '/file_sharing',
    component: FileSharing,
  },
  {
    path: '/reports/connect_fails',
    component: ConnectionFails,
  },
  {
    path: '/reports/candidates_cons',
    component: CandidatesCons,
  },
  {
    path: '/reports/privileges',
    component: Privileges
  },
  {
    path: '/reports/graduates',
    component: Graduates
  }
]

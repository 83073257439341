import { Button } from 'shards-react'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import React from 'react'
import addFont from '../../../../fonts/fonts'


export const PhonebookPdfPrint = ({i}) => {

  const title = 'Список телефонов руководящего состава'
  const orientation = 'l'

  const createDocData = async () => {
    addFont(jsPDF.API)

    const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: orientation })

    doc.autoTable({
      html: `#PhonebookPDFTable_${i}`,
      styles: { font: 'Montserrat', fontSize: 6, margin: 0 },
      headStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.2,
        lineColor: 100,
        fontSize: 8,
      },
      bodyStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.2,
        lineColor: 100,
        fontSize: 8,
        textColor: '#000',
      },
      columnStyles: {
        0: { cellWidth: 90 },
        1: { cellWidth: 45 },
        2: { cellWidth: 40 },
        3: { cellWidth: 25 },
        4: { cellWidth: 35 },
        5: { cellWidth: 35 }
      },
      margin: {
        left: 5,
      }
    })
    doc.addPage('a4', orientation)
    doc.deletePage(doc.getCurrentPageInfo().pageNumber)
    return doc
  }

  const toPdf = () => {
    createDocData().then(doc => doc.save(`${title}.pdf`))
  }

  const toPrint = () => {
    createDocData().then(doc => {
      doc.autoPrint()
      doc.output('dataurlnewwindow')
    })
  }

  return (
    <>
      <Button theme='danger' className={'mb-2'} onClick={toPrint}>
        Печать .pdf
      </Button>
      &nbsp;
      <Button theme='danger' className={'mb-2'} onClick={toPdf}>
        Скачать .pdf
      </Button>
    </>
  )
}
import React from 'react';
import PropTypes from 'prop-types';
import Table from "../../../../Table";

import styles from './DiseasedWeekTable.module.css';
import ExcelExporter from "../../ExcelExporter";


const DiseasedWeekTable = ({ data, formData }) => {
    const columns = React.useMemo(
        () => [
            {
                Header: 'Параллель',
                columns: [{
                    Header: null,
                    accessor: 'kurs'
                }],
                merged: true
            },
            {
                Header: 'Понедельник',
                columns: [
                    {
                        Header: 'Кол-во обуч-ся',
                        accessor: '2_0',
                    },
                    {
                        Header: 'Кол-во заболевших',
                        accessor: '2_1',
                    },
                ],
            },
            {
                Header: 'Вторник',
                columns: [
                    {
                        Header: 'Кол-во обуч-ся',
                        accessor: '3_0',
                    },
                    {
                        Header: 'Кол-во заболевших',
                        accessor: '3_1',
                    },
                ],
            },
            {
                Header: 'Среда',
                columns: [
                    {
                        Header: 'Кол-во обуч-ся',
                        accessor: '4_0',
                    },
                    {
                        Header: 'Кол-во заболевших',
                        accessor: '4_1',
                    },
                ],
            },
            {
                Header: 'Четверг',
                columns: [
                    {
                        Header: 'Кол-во обуч-ся',
                        accessor: '5_0',
                    },
                    {
                        Header: 'Кол-во заболевших',
                        accessor: '5_1',
                    },
                ],
            },
            {
                Header: 'Пятница',
                columns: [
                    {
                        Header: 'Кол-во обуч-ся',
                        accessor: '6_0',
                    },
                    {
                        Header: 'Кол-во заболевших',
                        accessor: '6_1',
                    },
                ],
            },
            {
                Header: 'Суббота',
                columns: [
                    {
                        Header: 'Кол-во обуч-ся',
                        accessor: '7_0',
                    },
                    {
                        Header: 'Кол-во заболевших',
                        accessor: '7_1',
                    },
                ],
            },
            {
                Header: 'Воскресенье',
                columns: [
                    {
                        Header: 'Кол-во обуч-ся',
                        accessor: '1_0',
                    },
                    {
                        Header: 'Кол-во заболевших',
                        accessor: '1_1',
                    },
                ],
            },
            {
                Header: 'Всего за период',
                columns: [
                    {
                        Header: 'Кол-во обуч-ся',
                        accessor: '65536_0',
                    },
                    {
                        Header: 'Кол-во заболевших',
                        accessor: '65536_1',
                    },
                ],
            },
        ],
        []
    );

    const computeData = (source) => {

        let result = [];
        let currentKurs = '';
        let currentRow = {};

        source.forEach((item, index) => {
            const { kurs, period_order, value, col_order } = item;

           if(currentKurs !== kurs) {
               currentKurs = kurs;
               if(!!Object.entries(currentRow).length) {
                   result.push(currentRow);
                   currentRow = {};
               }
               currentRow.kurs = currentKurs;
           }
           currentRow[`${period_order}_${col_order}`] = value;
        });

        result.push(currentRow);

        return result
    };

    return (
        <div>
            <ExcelExporter>
                {data.organizations && data.organizations.map(org =>
                    <div key={org.idOrganization} className={styles.container}>
                        <h3 className={'text-center mb-2'}>{org.OrgName}</h3>
                        <h4 className={'text-center mb-2'}>Сведения о заболевших за неделю {formData.period && <span>{formData.period.datefrom} - {formData.period.dateto}</span>}</h4>
                        <Table data={computeData(org.Values)} columns={columns}/>
                    </div>
                )}
            </ExcelExporter>
        </div>
    );
};

DiseasedWeekTable.propTypes = {
    period: PropTypes.object,
};

export default DiseasedWeekTable;
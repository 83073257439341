import {combineReducers} from "redux";
import {diseasedWeekListReducer} from "./list/diseasedWeekListReducer";
import {diseasedWeekLoaderReducer} from "./loader/diseasedWeekLoaderReducer";
import {createActions} from "../../../factory/actionFactory";
import { call, put, takeLatest } from 'redux-saga/effects';
import {START} from "../../../../constants/action-postfix";
import {reportsAPI} from "../../../../api/reports";
import {diseasedWeekFormReducer} from "./form/diseasedWeekFormReducer";

export const DISEASED_WEEK_REQUEST = "DISEASED_WEEK_REQUEST";
export const SET_WEEK_PERIOD_DATA = "SET_WEEK_PERIOD_DATA";


export const diseasedWeekReducer = combineReducers({
    list: diseasedWeekListReducer,
    loader: diseasedWeekLoaderReducer,
    form: diseasedWeekFormReducer
});

// ACTIONS
export const diseasedWeekActions = createActions('diseasedWeek', DISEASED_WEEK_REQUEST);
export const setWeekPeriodData = period => ({
    type: SET_WEEK_PERIOD_DATA,
    payload: period
});


// SAGAS
export function* diseasedWeekWatcher() {
    yield takeLatest(`${DISEASED_WEEK_REQUEST}${START}`, diseasedWeekWorker)
}

function* diseasedWeekWorker({ payload }) {

    try {
        const response = yield call(reportsAPI.diseasedWeekReport, payload);
        yield put(diseasedWeekActions.diseasedWeekRequestSuccess(response.data.Answer.Data_));
    } catch(error) {
        yield put(diseasedWeekActions.diseasedWeekRequestError(error));
    }
}
import React from 'react'
import { FileSharingSentForm as Form } from './Form'
import { FileSharingSentTable as Table } from './Table'

export const FileSharingSent = () => {
  return (
    <div className='mt-2'>
      <Form />
      <Table />
    </div>
  )
}

import React from 'react'
import cn from 'classnames'

import styles from './s.module.css'

const SubPDFTable = ({ dataIndex, data, formatDifferencePDF }) => {
  const sumReducer = (arr, prop) => {
    const reducer = (accumulator, currentValue) => {
      if (currentValue[0]) {
        return accumulator + +currentValue[0][prop]
      }
      return accumulator + 0
    }

    return arr.reduce(reducer, 0)
  }

  // data[1].push(...new Array(9 - data[1].length).fill(null).map(e => []))
  return (
    <>
      <tr>
        <td rowSpan='3'>{dataIndex + 1}</td>
        <td rowSpan='3'>{data[0][1]}</td>
        <td>Штатная</td>
        {data[1].map((item, index) => {
          return item[0] ? (
            <React.Fragment key={index}>
              <td className={styles.bold_border_right}>{item[0].plan ? item[0].plan : 0}</td>
            </React.Fragment>
          ) : (
            <td key={index} className={styles.bold_border_right} />
          )
        })}
        <td>{sumReducer(data[1], 'plan')}</td>
      </tr>

      <tr>
        <td>Списочная</td>
        {data[1].map((item, index) => {
          return item[0] ? (
            <React.Fragment key={index}>
              <td className={styles.bold_border_right}>{item[0].fact ? item[0].fact : 0}</td>
            </React.Fragment>
          ) : (
            <td key={index} className={styles.bold_border_right} />
          )
        })}
        <td>{sumReducer(data[1], 'fact')}</td>
      </tr>
      <tr className={styles.bold_border_bottom}>
        <td>Некомплект</td>
        {data[1].map((item, index) => {
          return item[0] ? (
            <React.Fragment key={index}>
              <td className={styles.bold_border_right}>{formatDifferencePDF(item[0].fact, item[0].plan)}</td>
            </React.Fragment>
          ) : (
            <td key={index} className={styles.bold_border_right} />
          )
        })}
        <td>{formatDifferencePDF(sumReducer(data[1], 'fact'), sumReducer(data[1], 'plan'))}</td>
      </tr>
    </>
  )
}

export default SubPDFTable

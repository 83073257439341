import React, { useEffect } from 'react'
import RouteLayout from '../../RouteLayout';
import ConnectionFailsForm from './Form';
import {connect} from 'react-redux'
import { organizationList } from '../../../redux/reducers/organization-list-reducer'
import { setConnectFailsFormData, connectFailsActions, getDetailsStart } from '../../../redux/reducers/ConnectFails/connectFailsReducer'
import Spinner from '../../common/Spinner';
import ConnectionFailsTable from './ConnectionFailsTable/ConnectionFailsTable';




function ConnectionFails({
    organizationListStart,
    orgList,
    auth,
    formData,
    setFormData,
    requestReport,
    getDetailsStart,
    loader,
    data,
    details,
    detailsLoading
}) {
    useEffect(() => {
        if (orgList !== undefined && orgList.length === 0) {
          organizationListStart(auth)
        }
      }, [organizationListStart, auth])
      

      const requestReportHandler = () => {
          const params = {
          school: formData.school ? 1 : 0,
          org: formData.idOrg,
          datefrom: formData.datefrom,
          dateto: formData.dateto
        }
        requestReport({ ...auth, ...params })
      }


    return (
        <RouteLayout
            title={'Количество сбоев при подключении к базам данных'}
        >
            <ConnectionFailsForm onSubmit={requestReportHandler}
                                orgList={orgList}
                                setFormData={setFormData}
                                formData={formData}/>
            {loader ?
                <Spinner />
                :
                <ConnectionFailsTable
                    data={data}
                    getDetailsStart={getDetailsStart}
                    formData={formData}
                    auth={auth}
                    failsList={details}
                    detailsLoading={detailsLoading}
                />
            }
        </RouteLayout>
    )
}


const mapStateToProps = (state) => ({
    auth: state.auth.data,
    orgList: state.reports.orgList.data,
    formData: state.reports.connectFails.form,
    data: state.reports.connectFails.list,
    loader: state.reports.connectFails.loader,
    details: state.reports.connectFails.details.data,
    detailsLoading: state.reports.connectFails.details.loading
});

const mapDispatchToProps = {
    organizationListStart: organizationList.organizationListRequestStart,
    requestReport: connectFailsActions.connectFailsRequestStart,
    setFormData: setConnectFailsFormData,
    getDetailsStart: getDetailsStart
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(ConnectionFails);

import {CANDIDATES_REQUEST} from "../candidatesReducer";
import {ERROR, START, SUCCESS} from "../../../../constants/action-postfix";



const initialState = false;

export const candidatesLoaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${CANDIDATES_REQUEST}${START}`:
            return true;
        case `${CANDIDATES_REQUEST}${SUCCESS}`:
            return false;
        case `${CANDIDATES_REQUEST}${ERROR}`:
            return false;
        default:
            return state;
    }
};
import React from 'react'
import { useSelector } from 'react-redux'
import { BASE_URL } from '../../../../constants/api'
import { getAuthData } from '../../../../redux/reducers/auth-reducer'
import { CaptionTable } from '../../../common/CaptionTable/CaptionTable'
import Spinner from '../../../common/Spinner'
import styles from '../FileSharing.module.css'

export const FileSharingSentTable = () => {
  const { login, pass } = useSelector(getAuthData)
  const { sentList, sentLoading } = useSelector(({ fileSharing }) => fileSharing)

  const statusStyle = status => {
    switch (status) {
      case 'Отправлен':
        return { color: 'blue' }

      case 'Доставлен':
        return { color: 'green' }

      case 'Новый':
        return { color: 'black' }

      default:
        break
    }
  }

  if (sentLoading) return <Spinner />
  return (
    <>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Название файла</th>
            <th>Дата и время отправки</th>
            <th>Статус</th>
          </tr>
        </thead>
        <tbody>
          {sentList.map(e => (
            <tr>
              <td>
                <a
                  href={`${BASE_URL}/?action=consolidated.files.get&format=json&base=cons&login=${login}&pass=${pass}&fileid=${e.idFile}`}
                >
                  {e.filename}
                </a>
              </td>
              <td>{e.loadtime}</td>
              <td>
                {e.schools.map(e => (
                  <div>
                    <span>{e.name} :</span> <span style={statusStyle(e.status)}>{e.status}</span>
                  </div>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {!sentList.length && !sentLoading && <CaptionTable>Список пуст</CaptionTable>}
    </>
  )
}

import React from 'react'
import styles from '../styles.module.css'


const Table = ({ data, year }) => {
  
  if (!data) return null;
  
  const renderRow = (school, index) => {
    const { orgname, total, EnterCivilHighSchool, EnterWarHighSchool, EnterWarHighSchoolOther, EnterWUTS, EnterSPO, NotEnter } = school
    return (
      <tr>
        <td>{index + 1}</td>
        <td style={{ textAlign: 'left' }}>{orgname}</td>
        <td>{total}</td>
        <td>{EnterWarHighSchool}</td>
        <td>{EnterWUTS}</td>
        <td>{EnterWarHighSchoolOther}</td>
        <td>{EnterCivilHighSchool}</td>
        <td>{EnterSPO}</td>
        <td>{NotEnter}</td>
      </tr>
    )
  }

  return (
    <div className={styles.tableBlock}>
      <h3 className={"text-center mb-2"}>Отчет о поступлении выпускников довузовских общеобразовательных организаций</h3>
      <h3 className={"text-center mb-2"}>Министерства обороны Российской Федерации, выпустившихся в {year} году</h3>
      <table id='graduatesTable' className={styles.mainTable}>
        <thead>
          <tr>
            <th rowSpan={2}>№ п/п</th>
            <th rowSpan={2}>Название училища</th>
            <th rowSpan={2}>Кол-во выпускников</th>
            <th colSpan={5}>Поступившие в</th>
            <th rowSpan={2}>Никуда не поступившие</th>
          </tr>
          <tr>
            <th>ВУЗ МО РФ</th>
            <th>ВУЦ</th>
            <th>ВУЗ др. сил. структур</th>
            <th>гражд. ВУЗ</th>
            <th>СПО</th>
          </tr>
        </thead>
        <tbody>
          {data.map(renderRow)}
        </tbody>
      </table>
    </div>
  )
}

export default Table
import React from 'react';
import BootstrapTable from 'react-bootstrap-table-next';
import { headerStyle, cellStyle } from '../../../../utils/configTable';
import { Link } from 'react-router-dom';
import { withRouter } from 'react-router-dom';


const GeneralStatisticsAllTable = ({ data, sortPdfReportTable }) => {

    const formatSumm = (cell, row) => {
        return <Link to={`/reports/general_statistic_one/${row.id}`}>{cell}</Link>
    };

    const NumberSorted = (a, b, order, dataField) => order === 'asc' ? b - a : a - b;

    const columns = [{
        dataField: 'position',
        text: 'Рейтинг',
        headerStyle: headerStyle(10),
        style: cellStyle(),
    }, {
        dataField: 'name',
        text: 'Образовательная организация',
        headerStyle: headerStyle(65),
        sort: true,
        onSort: (field, order) => sortPdfReportTable(field, order)
    }, {
        dataField: 'summ',
        text: 'Суммарный балл',
        headerStyle: headerStyle(),
        style: cellStyle(),
        formatter: formatSumm,
        sort: true,
        sortFunc: NumberSorted,
        onSort: (field, order) => sortPdfReportTable(field, order)
    }];

    return (
        <BootstrapTable keyField='position' data={data} columns={columns}
            striped bootstrap4 />
    );
};

export default withRouter(GeneralStatisticsAllTable);


export const dataByOrg = (list) => {
  const result = {}
  for ( const item of list ) {
    const {idOrganization, Orgname, code, gender_code, student_count} = item
    const orgKey = `_${idOrganization}`
    if (result[orgKey]) {
      if (result[orgKey][gender_code]) {
        result[orgKey][gender_code][code] = student_count
      } else {
        result[orgKey][gender_code] = {[code]: student_count}
      }
      result[orgKey][gender_code][code] = student_count
    } else {
      result[orgKey] = {Orgname, idOrganization}
      result[orgKey][gender_code] = {[code]: student_count}
    }
  }
  return Object.values(result)
}

export const dataByParallel = (list) => {
  const result = {}
  for ( const item of list ) {
    const {idorganization, orgname, code, gender_code, kurs, student_count} = item
    const orgKey = `_${idorganization}`
    if (result[orgKey]) {
      if (result[orgKey].parallels[kurs]) {
        if (result[orgKey].parallels[kurs][gender_code]) {
          result[orgKey].parallels[kurs][gender_code][code] = student_count
        } else {
          result[orgKey].parallels[kurs][gender_code] = {[code]: student_count}
        }
      } else {
        result[orgKey].parallels[kurs] = {[gender_code]: {[code]: student_count}}
      }
    } else {
      result[orgKey] = {orgname, idorganization, parallels: {}}
      result[orgKey].parallels[kurs] = {[gender_code]: {[code]: student_count}}
    }
  }
  return Object.values(result)
}
import {SET_FORM_DATA} from "../privilegesReducer";
import moment from 'moment'


const initialState = {
    idOrg: 0,
    groupmode: 0,
    school: 0,
    date: moment().format('YYYY-MM-DD')
};

export const privilegesFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FORM_DATA:
            return action.payload;
        default:
            return state;
    }
};

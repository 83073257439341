import React from 'react';
import styles from './Footer.module.css';
import { VERSION } from '../../../constants/version';

const Footer = () => {
  return (
    <div className={styles.container}>
      <span>{VERSION}</span>
      © {new Date().getFullYear()} ООО Нинтегра
    </div>
  );
};

Footer.propTypes = {

};

export default Footer;

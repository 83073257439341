import moment from "moment";
import {SET_STUDENTS_TOTAL_FORM_DATA} from "../studentsTotalReducer";



const determineStartDate = () => {
    const startDate = moment(moment().format('YYYY-09-01'));
    const today = moment();
    const result = startDate.isBefore(today) ? startDate : today;
    return result.format('YYYY-MM-DD')
}

const initialState = {
    datefrom: determineStartDate(),
    dateto: moment().format('YYYY-MM-DD'),
    school: 0,
    idOrg: 0
}

export const formReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_STUDENTS_TOTAL_FORM_DATA:
            return action.payload;

        default:
            return state;
    }

}
import { ConfigProvider } from 'antd'
import 'antd/dist/antd.css'
import ruRu from 'antd/es/locale/ru_RU'
import 'bootstrap/dist/css/bootstrap.min.css'
import React from 'react'
import 'react-bootstrap-table-next/dist/react-bootstrap-table2.min.css'
import 'react-datepicker/dist/react-datepicker.css'
import { NotificationContainer } from 'react-notifications'
import 'react-notifications/lib/notifications.css'
import { Provider } from 'react-redux'
import '../../css/global.css'
import { configureStore } from '../../redux/store/index'
import Routes from '../Routes/Routes'

const store = configureStore()

window.__store = store

const App = () => {
  return (
    <Provider store={store}>
      <ConfigProvider locale={ruRu}>
        <Routes />
        <NotificationContainer />
      </ConfigProvider>
    </Provider>
  )
}

export default App

import {ERROR, START, SUCCESS} from "../../../../../constants/action-postfix";
import {DISEASED_COVID_REQUEST} from "../diseasedCovidReducer";


const initialState = false;

export const loaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${DISEASED_COVID_REQUEST}${START}`:
            return true;
        case `${DISEASED_COVID_REQUEST}${SUCCESS}`:
            return false;
        case `${DISEASED_COVID_REQUEST}${ERROR}`:
            return false;
        default:
            return state;
    }
};

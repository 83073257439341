import {Font, StyleSheet} from "@react-pdf/renderer";
import montserrat from "../../fonts/Montserrat-Regular.ttf";

Font.register({ family: 'Montserrat', src: montserrat });

export const pdfStyles = StyleSheet.create({
  page: {marginTop: 20, marginBottom: 20, borderWidth:5},
  text: { fontFamily: 'Montserrat',  fontSize: 12, padding: 10, borderWidth: 0, margin: 20},
  textX: {fontFamily: 'Montserrat', fontSize: 12, paddingLeft: 10, borderWidth: 0, marginLeft: 20, marginRight: 20, textAlign: 'center'},
  textZ: {fontFamily: 'Montserrat', fontSize: 12, paddingLeft: 10, borderWidth: 0, margin: 20, textAlign: 'center'},
  table: { width: "auto", borderStyle: "solid", marginTop: '-20',
    borderWidth: 0, borderRightWidth: 0, borderBottomWidth: 0, margin: 25},
  tableRow: { margin: "auto", flexDirection: "row", height: 30, marginBottom: 0, padding: 0 },
  tableColNum: { width: "10%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 1, borderTopWidth: 1, borderColor: "#ccc" },
  tableCol: { borderStyle: "solid", borderWidth: 1, borderLeftWidth: 1,  borderTopWidth: 1, borderColor: "#ccc" },
  tableColXl: { width: "65%", borderStyle: "solid", borderWidth: 1, borderLeftWidth: 1, borderTopWidth: 1, borderColor: "#ccc" },
  tableCell: { marginTop: 0, fontSize: 10, fontFamily: 'Montserrat', verticalAlign: 'middle', textAlign: 'center' },
  tableCol10: { width: "10%" },
  tableCol15: { width: "15%" },
  tableCol20: { width: "20%" },
  tableCol25: { width: "25%" },
  tableCol30: { width: "30%" },
  tableCol40: { width: "40%" },
  tableCol50: { width: "50%" },
  tableCol60: { width: "60%" },
  tableCol70: { width: "70%" },
  tableCol80: { width: "80%" },
  tableCol90: { width: "90%" },
});
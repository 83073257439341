import { Table } from "antd";
import React from "react";
import { useMemo } from "react";
import styles from "./ConnectionFailsTable.module.css"
import { useState } from "react";
import Modal from "react-modal"
import Spinner from "../../../common/Spinner";


export default function ConnectionFailsTable({
  data,
  getDetailsStart,
  auth,
  formData,
  failsList,
  detailsLoading
}) {
  
  const [details, setDetails] = useState(null)

  const failsColumns = useMemo(() => [
    {
      title: <div className={styles.header}>Дата/время начала</div>,
      dataIndex: "down_start"
    },
    {
      title: <div className={styles.header}>Дата/время окончания</div>,
      dataIndex: "down_stop"
    },
    {
      title: <div className={styles.header}>Продолжительность (мин)</div>,
      dataIndex: "down_time"
    },
  ], [])
  
  const columns = useMemo(() => [
    {
      title: <div className={styles.header}>Название УЗ</div>,
      dataIndex: "name"
    },
    {
      title: <div className={styles.header}>Количество сбоев</div>,
      dataIndex: "nfaults"
    },
    {
      title: <div className={styles.header}>Общая продолжительность (мин)</div>,
      dataIndex: "down_time"
    },
  ], [])

  const openDetailsModal = (data) => {
    setDetails(data)
    const params = {
      org: data.id,
      datefrom: formData.datefrom,
      dateto: formData.dateto
    }
    getDetailsStart({ ...auth, ...params })
  }

  return (
    <div>
      {!!data.length &&
      <Table
        className={styles.table}
        dataSource={data}
        columns={columns}
        pagination={false}
        onRow={(data) => ({onClick: event => openDetailsModal(data)})}
        size="small"
        bordered
        />}
      {details &&
        <Modal
          style={{
            content: { inset: "10vh 30vw" },
            overlay: { zIndex: "11" }
          }}
          isOpen={details}
          onRequestClose={() => setDetails(null)}
        >
          {detailsLoading ? <Spinner top="30%" />
          : <div>
              <Table
                  className={styles.table}
                  dataSource={failsList}
                  pagination={false}
                  columns={failsColumns}
                />
            </div>}
        </Modal>
      }
    </div>
  );
}
import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'

import styles from '../s.module.css'

const SubTable = ({ data, requestDetails, formatDifference, foreignMode }) => {
  const sumReducer = (arr, prop) => {
    const reducer = (accumulator, currentValue) => {
      if (currentValue[0]) {
        return accumulator + +currentValue[0][prop]
      }
      return accumulator + 0
    }

    return arr.reduce(reducer, 0)
  }

  return (
    <>
      <tr className={styles.bold_border_top}>
        {data[1].map((item, index) => (
          <td key={index} colSpan={7} className={styles.bold_border_right}>
            {item[0] && item[0].kurs + (item[0].kurs === item[0].kurs_norm ? '-e классы' : '-й курс')}
          </td>
        ))}
        <td rowSpan={3} colSpan={2}>
          ВСЕГО (чел.)
        </td>
      </tr>
      <tr>
        {data[1].map(item => {
          const nullsArr = []
          const length = Number(7 - item.length)
          for (let i=0; i<length; i++) {
            nullsArr.push(null)
          }
          let extendedItem = item.concat(nullsArr)
          return extendedItem.map((item, index) => (
            <td
              key={index}
              className={cn({
                [styles.kurs_cell]: true,
                [styles.bold_border_right]: index === 6,
                [styles.clickable_row]: item && !!item.realName,
              })}
              onClick={() => {
                item && requestDetails(item.ClassID, item.kurs, item.idOrganization)
              }}
            >
              {item && item.realName}
            </td>
          ))
        })}
      </tr>
      <tr>
        {data[1].map(item => {
          const nullsArr = []
          const length = Number(7 - item.length)
          for (let i=0; i<length; i++) {
            nullsArr.push(null)
          }
          let extendedItem = item.concat(nullsArr)
          return extendedItem.map((item, index) => (
            <td
              key={index}
              className={cn({
                [styles.kurs_cell]: true,
                [styles.bold_border_right]: index === 6,
              })}
            >
              {item && item.student_count}
            </td>
          ))
        })}
      </tr>
      {!foreignMode &&
      <tr>
        {data[1].map((item, index) => {
          return item[0] ? (
            <React.Fragment key={index}>
              <td colSpan={6}>Штатная</td>
              <td className={styles.bold_border_right}>{item[0].plan}</td>
            </React.Fragment>
          ) : (
            <td key={index} colSpan={7} className={styles.bold_border_right} />
          )
        })}
        <td>Штатная</td>
        <td>{sumReducer(data[1], 'plan')}</td>
      </tr>}

      <tr>
        {data[1].map((item, index) => {
          return item[0] ? (
            <React.Fragment key={index}>
              <td colSpan={6}>Списочная</td>
              <td className={styles.bold_border_right}>{item[0].fact}</td>
            </React.Fragment>
          ) : (
            <td key={index} colSpan={7} className={styles.bold_border_right} />
          )
        })}
        <td>Списочная</td>
        <td>{sumReducer(data[1], 'fact')}</td>
      </tr>
      {!foreignMode &&
      <tr className={styles.bold_border_bottom}>
        {data[1].map((item, index) => {
          return item[0] ? (
            <React.Fragment key={index}>
              <td colSpan={6}>Некомплект</td>
              <td className={styles.bold_border_right}>{formatDifference(item[0].plan, item[0].fact)}</td>
            </React.Fragment>
          ) : (
            <td key={index} colSpan={7} className={styles.bold_border_right} />
          )
        })}
        <td>Некомплект</td>
        <td>{formatDifference(sumReducer(data[1], 'plan'), sumReducer(data[1], 'fact'))}</td>
      </tr>}
    </>
  )
}

SubTable.propTypes = {
  requestDetails: PropTypes.func,
}

export default SubTable

import React from 'react'
import { useDispatch, useSelector } from 'react-redux'
import styles from '../styles.module.css'
import { privilegesDetailsActions } from '../../../../redux/reducers/Priveleges/privilegesReducer'
import moment from 'moment'

const categories = ['А', 'Б', 'В', 'Г', 'Д', 'Е', 'Ж', 'З', 'И', 'К', 'Л', 'Л1', 'М']


export default function TableComponent () {
  
  
  const { form, list, parallel_mode } = useSelector(state => state.reports.privileges)
  const orgList = useSelector(state => state.reports.orgList.data)
  const dispatch = useDispatch()
  
  const parallelsMode = parallel_mode
  
  const renderCategories = (data, gender, idOrg, kurs, both) => categories.map(privilege => {
    const detailsPayload = { gender, kurs: kurs || 0, privilege, idOrg, both }
    return (
      <td
        key={privilege}
        onClick={onCellClick(detailsPayload)}
      >
        {data[privilege]}
      </td>
    )
  })
  
  const getRowTotal = (data) => Object.values(data).reduce((a, b) => a + parseInt(b), 0)
  
  const getParallelColumnsTotal = (letter) => list.reduce((total, item) => total + getColumnTotal(letter, Object.values(item.parallels)), 0)
  

  const getColumnTotal = (letter, incomeList) => (incomeList || list).reduce((total, item) => {
    const mensNumber = (item['М'] && item['М'][letter]) ? parseInt(item['М'][letter]) : 0
    const womensNumber = (item['Ж'] && item['Ж'][letter]) ? parseInt(item['Ж'][letter]) : 0
    return total + mensNumber + womensNumber
  }, 0);
  
  const onCellClick = (payload) => () => {
    const { privilegesDetailsRequestStart } = privilegesDetailsActions
    dispatch(privilegesDetailsRequestStart(payload))
  }

  const renderRow = (item, index) => parallelsMode
    ? rowByParallel(item, index)
    : rowByOrg(item, index)


  const rowByOrg = (item, index) => {
    const {Orgname, idOrganization, М: mens, Ж: womens} = item
    const both = !!mens && !!womens
    const data = mens || womens
    const prefix = mens ? 'М' : 'Ж'
    return (
      <>
        <tr>
          <td rowSpan={both ? 2 : 1}>{index+1}</td>
          <td style={{textAlign: 'left'}} rowSpan={both ? 2 : 1}>{Orgname}</td>
          <td>{prefix}</td>
          {renderCategories(data, prefix, idOrganization, 0, both)}
          <td>{getRowTotal(data)}</td>
        </tr>
        {both &&
          <tr>
            <td>Ж</td>
            {renderCategories(womens, 'Ж', idOrganization, 0, both)}
            <td>{getRowTotal(womens)}</td>
          </tr>
        }
      </>
    )
  }
  
  const rowByParallel = (item, index) => {
    const {idorganization, orgname, parallels} = item
    
    const renderParallelRow = (key, index) => {
      const {М: mens, Ж: womens} = parallels[key]
      const both = !!mens && !!womens
      const data = mens || womens
      const prefix = mens ? 'М' : 'Ж'
      return (
        <>
          <tr>
            <td style={{textAlign: 'left'}} rowSpan={both ? 2 : 1}>{orgname}</td>
            <td rowSpan={both ? 2 : 1}>{key}</td>
            <td>{prefix}</td>
            {renderCategories(data, prefix, idorganization, key, both)}
            <td>{getRowTotal(data)}</td>
          </tr>
          {both &&
            <tr>
              <td>Ж</td>
              {renderCategories(womens, 'Ж', idorganization, key, both)}
              <td>{getRowTotal(womens)}</td>
            </tr>
          }
        </>
      )
    }
    return Object.keys(parallels).map(renderParallelRow)
  }



  const columnsTotal = categories.map(category => {
    const callback = parallelsMode ? getParallelColumnsTotal : getColumnTotal
    return callback(category)
  })
  
  const orgObject = orgList.find(org => org.idOrganization == form.idOrg) || {}
  const orgName = orgObject.orgName
  const schooolTitle = orgName ? `'${orgName}'` : 'довузовских общеобразовательных организаций Министерства обороны Российской Федерации'

  return (
    <div>
      {
        !!list.length &&
          <>
            <h3 className={"text-center mb-2"}>Наличие льгот у обучающихся</h3>
            <h3 className={"text-center mb-2"}>{schooolTitle}</h3>
            <h3 className={"text-center mb-2"}>на {moment(form.date).format('DD.MM.YYYY')}</h3>
            <table id='privilegesTable' className={styles.mainTable}>
              <thead>
                <tr>
                  {!parallelsMode && <th rowSpan={2}>№ п/п</th>}
                  <th rowSpan={2}>Название училища</th>
                  {parallelsMode && <th rowSpan={2}>Параллель</th>}
                  <th rowSpan={2}>Пол</th>
                  <th colSpan={13}>Льготы</th>
                  <th rowSpan={2}>Итого</th>
                </tr>
                <tr>
                  {categories.map(category => <th key={category}>{category}</th>)}
                </tr>
              </thead>
              <tbody>
                {list.map(renderRow)}
                <tr>
                  <td colSpan={3}>Итого:</td>
                  {columnsTotal.map(count => <td key={count}>{count}</td>)}
                  <td>{columnsTotal.reduce((a, b) => a + b, 0)}</td>
                </tr>
              </tbody>
            </table>
          </>
      }
    </div>
  )
}

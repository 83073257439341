import {SUCCESS} from "../../../../constants/action-postfix";
import {GRADUATES_REQUEST} from "../graduatesReducer";


const initialState = null;

export const dataReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${GRADUATES_REQUEST}${SUCCESS}`:
            return action.payload;
        default:
            return state;
    }
};
import React, { useMemo, useState } from 'react'
import styles from './BestSchoolForm.module.css'

import Select from 'react-select'
import { Col, Container, Row } from 'react-bootstrap'
import { Button, FormSelect } from 'shards-react'

const BestSchoolForm = ({ onSubmit, formData, setFormData, specparams }) => {
  const [isError, setError] = useState(false)

  const START_YEAR = 2009
  const currentYear = new Date().getFullYear() + 1

  let years = useMemo(() => {
    let yearsArray = []
    for (let i = START_YEAR; i <= currentYear; i++) {
      yearsArray.push(i + '-05-31')
    }
    return yearsArray
  }, [START_YEAR, currentYear])

  const onChange = e => {
    setFormData({ ...formData, [e.target.name]: e.target.value })
  }

  const checkSubmit = () => {
    if (formData.date) {
      setError(false)
      onSubmit()
    } else {
      setError(true)
    }
  }

  const specparamsToOptions = specparams => {
    return specparams.map(item => ({ value: item.paramId, label: item.name }))
  }

  return (
    <Container fluid>
      <Row className={'mt-3'}>
        <Col md={'auto'}>
          <FormSelect onChange={onChange} name='date' value={formData.date}>
            <option value='' selected disabled>
              Выберите дату отчета
            </option>
            {[...years].reverse().map(item => (
              <option value={item}>{item.split('-').reverse().join('.')}</option>
            ))}
          </FormSelect>
        </Col>
        <Col md={4} style={{ zIndex: '1000' }}>
          <Select
            placeholder='Укажите тип ОУ'
            options={specparamsToOptions(specparams)}
            onChange={val =>
              setFormData({
                ...formData,
                specparamsId: !!val.length ? val.map(item => item.value).join(',') : 0,
              })
            }
            isMulti
          />
        </Col>
        <Col md={'auto'}>
          <Button onClick={checkSubmit}>Составить</Button>
        </Col>
      </Row>

      {isError && <div className={styles.error}>Укажите дату отчета</div>}
    </Container>
  )
}

export default BestSchoolForm

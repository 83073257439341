import {ERROR, START, SUCCESS} from "../../../../constants/action-postfix";
import {DUPLICATES_REQUEST} from "../duplicatesReducer";


const initialState = false;

export const duplicatesLoaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${DUPLICATES_REQUEST}${START}`:
            return true;
        case `${DUPLICATES_REQUEST}${SUCCESS}`:
            return false;
        case `${DUPLICATES_REQUEST}${ERROR}`:
            return false;
        default:
            return state;
    }
};
import React from 'react'
import PropTypes from 'prop-types'

import styles from '../s.module.css'

const SummaryRow = ({ data, formatDifference, foreignMode }) => {
  const sumReducer = (arr, prop) => {
    const reducer = (accumulator, currentValue) => {
      let val = isNaN(currentValue[prop]) ? 0 : currentValue[prop]
      return accumulator + val
    }

    return arr.reduce(reducer, 0)
  }

  return (
    <>
      {!foreignMode &&
      <tr>
        {data.map((item, index) => (
          <React.Fragment key={index}>
            <td colSpan={6}>Штатная</td>
            <td className={styles.bold_border_right}>{item.plan || '-'}</td>
          </React.Fragment>
        ))}
        <td>Штатная</td>
        <td>{sumReducer(data, 'plan')}</td>
      </tr>}
      <tr>
        {data.map((item, index) => (
          <React.Fragment key={index}>
            <td colSpan={6}>Списочная</td>
            <td className={styles.bold_border_right}>{item.fact || '-'}</td>
          </React.Fragment>
        ))}
        <td>Списочная</td>
        <td>{sumReducer(data, 'fact')}</td>
      </tr>
      {!foreignMode &&
      <tr className={styles.bold_border_bottom}>
        {data.map((item, index) => (
          <React.Fragment key={index}>
            <td colSpan={6}>Некомплект</td>
            <td className={styles.bold_border_right}>{formatDifference(item.plan, item.fact)}</td>
          </React.Fragment>
        ))}
        <td>Некомплект</td>
        <td>{formatDifference(sumReducer(data, 'plan'), sumReducer(data, 'fact'))}</td>
      </tr>}
    </>
  )
}

SummaryRow.propTypes = {
  data: PropTypes.array,
}

export default SummaryRow

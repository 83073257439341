import { Button } from 'shards-react'
import jsPDF from 'jspdf'
import 'jspdf-autotable'
import React from 'react'
import addFont from '../../../../fonts/fonts'
import printIcon from '../../../../img/icons/pdf.png'
import styles from './PDFPrint.module.css'

export const StudentsTotalPdfPrint = ({ data, searchFormValue, librariesList }) => {
  // const monthsList = [
  //   '',
  //   ' Январь',
  //   ' Февраль',
  //   ' Март',
  //   ' Апрель',
  //   ' Май',
  //   ' Июнь',
  //   ' Июль',
  //   ' Август',
  //   ' Сентябрь',
  //   ' Октябрь',
  //   ' Ноябрь',
  //   ' Декабрь',
  // ]

  const title = 'Численность переменного состава'
  const orientation = 'portrait'

  const createDocData = async () => {
    addFont(jsPDF.API)

    const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: orientation })

    // doc.setFont('Montserrat')
    // doc.setFontType('bold')
    // doc.setFontSize('16')

    // doc.text('ДНЕВНИК БИБЛИОТЕКИ', doc.internal.pageSize.width / 2, 10, null, null, 'center')

    // doc.setFontType('normal')
    // doc.setFontSize('13')

    // doc.text('высшего профессионального образования', doc.internal.pageSize.width / 2, 26, null, null, 'center')

    doc.autoTable({
      html: '#studentsTotalPDFTable',
      styles: { font: 'Montserrat', fontSize: 6, margin: 0 },
      headStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.2,
        lineColor: 100,
        fontSize: 7,
      },
      bodyStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.2,
        lineColor: 100,
        fontSize: 8,
        textColor: '#000',
      },
      columnStyles: {
        0: { cellWidth: 8 },
        1: { cellWidth: 45 },
        2: { cellWidth: 22 },
        3: { cellWidth: 12 },
        4: { cellWidth: 12 },
        5: { cellWidth: 12 },
        6: { cellWidth: 12 },
        7: { cellWidth: 12 },
        8: { cellWidth: 15 },
        9: { cellWidth: 15 },
        10: { cellWidth: 12 },
        11: { cellWidth: 12 },
        12: { cellWidth: 12 },
      },
      didParseCell: function (table) {
        // if (table.row.raw[0].content === 'Разница') {
        //   table.cell.styles.lineWidth = { bottom: 0.5, right: 0.2 }
        // }
        // if (table.row.raw[table.row.raw.length - 1].content === table.cell.text[0] && table.section === 'body') {
        //   table.cell.styles.lineWidth = { bottom: 0.2, right: 0.5 }
        // }
        // if (table.row.raw[table.row.raw.length - 1].content === table.cell.text[0] && table.row.raw[0].content === 'Разница') {
        //   table.cell.styles.lineWidth = { bottom: 0.4, right: 0.5 }
        // }

        // if (table.row.raw[0].content > 0 && table.row.raw[1].content === table.cell.text.join(' ')) {
        //   table.cell.styles.lineWidth = { bottom: 0.4, right: 0.2 }
        //   console.log(table)
        // }

        // if (table.row.raw[0].content > 0 && table.row.raw[0].content === table.cell.text.join(' ')) {
        //   table.cell.styles.lineWidth = { bottom: 0.4, right: 0.2, left: 0.5 }
        // }

        if (table.cell.text[0] === '') {
          table.cell.styles.fillColor = '#d1d1d1'
        }
        // && (table.column.dataKey === 10 || table.column.dataKey === 11)
        if (table.cell.text[0] < 0 && table.row.raw[0].content === 'Некомплект') {
          table.cell.styles.textColor = 'green'
        }
        if (table.cell.text[0] > 0 && table.row.raw[0].content === 'Некомплект') table.cell.styles.textColor = 'red'
        if (
          table.cell.text[0] == 0 &&
          (table.row.raw[0].content > 0 || table.row.raw[0].content === 'Списочная' || table.row.raw[0].content === 'Некомплект')
        ) {
          table.cell.text[0] = ''
        }
      },
      // startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 70,
      margin: {
        left: 5,
      },
    })
    doc.addPage('a4', orientation)
    doc.deletePage(doc.getCurrentPageInfo().pageNumber)
    return doc
  }

  const toPdf = () => {
    createDocData().then(doc => doc.save(`${title}.pdf`))
  }

  const toPrint = () => {
    createDocData().then(doc => {
      doc.autoPrint()
      doc.output('dataurlnewwindow')
    })
  }

  return (
    <>
      {/* <span onClick={toPdf}>PDF</span>
      <div>|</div> */}
      {/* <button onClick={toPrint} title={'Печать'} style={{ backgroundImage: 'url(' + printIcon + ')' }} /> */}
      <Button theme='danger' className={'mb-2'} onClick={toPrint}>
        Печать .pdf
      </Button>
      &nbsp;
      <Button theme='danger' className={'mb-2'} onClick={toPdf}>
        Скачать .pdf
      </Button>
    </>
  )
}

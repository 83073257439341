import React from 'react'

export const TableRow = ({ rowData, gender }) => {
  

  return (
    <>
    <td>{gender === 'guys' ? 'м' : gender === 'girls' ? 'ж' : ''}</td>

    {[5, 6, 7, 8, 9, 10, 11].map(elem => (
      <td>
        {rowData[gender] &&
          rowData[gender].find(e => e.class_to_enter == elem) &&
          rowData[gender].find(e => e.class_to_enter == elem).cnt}
      </td>
    ))}
    <td>
      {rowData[gender] &&
        rowData[gender].reduce((a, c) => {
          return a + +c.cnt
        }, 0)}
    </td>
  </>
  )
    
}

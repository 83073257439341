import React, { useEffect, useState } from 'react'
import ReactDOM from 'react-dom'
import { menuStructure } from '../../../constants/menuStructure'
import styles from './Menu.module.css'
import MenuItem from './Item'
import MobileMenuItem from './MobileItem'
import { connect } from 'react-redux'
import { Col, Row } from 'react-bootstrap'
import menuIcon from '../../../img/icons/menu.png'
import { BASE_URL } from '../../../constants/api'

const mapStateToProps = ({ auth: { data } }) => ({
  authData: data,
})
const Menu = ({ authData }) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const [change, setChange] = useState('');

  useEffect(() => {  
    fetch(`${BASE_URL}/?action=consolidated.access&base=cons&format=json&token=Обмен файлами&mode=Режимы&access=2&login=${authData.login}&pass=${authData.pass}`)
      .then((response) => response.json())
      .then((data) => setChange(data.Answer.Result));
  }, [change])
  
  const getFilteredMenu = (menu) => {
    if (change !== 'Error') {
      return menu;
    } else {
    return menu.filter((el, index) => index != 3);
  }
}

  return (
    <>
      <div className={styles.container}>
        <Row noGutters>
          {getFilteredMenu(menuStructure).map((item, index) => (
            <Col key={index}>
              <MenuItem authData={authData} {...item} />
            </Col>
          ))}
        </Row>
      </div>

      <button onClick={() => setMenuOpen(!menuOpen)} className={styles.mobileMenuButton}>
        <img src={menuIcon} alt='' />
      </button>
      {menuOpen && (
        <div className={styles.mobile}>
          {getFilteredMenu(menuStructure).map((item, index) => (
            <div key={`menuitem${index}`}>
              <MobileMenuItem authData={authData} onSelectItem={() => setMenuOpen(false)} {...item} />
            </div>
          ))}
        </div>
      )}
    </>
  )
}

export default connect(mapStateToProps)(Menu)

import { START, SUCCESS, ERROR } from '../../constants/action-postfix'
import { call, put, takeLatest, select } from 'redux-saga/effects'
import { authAPI } from './../../api/auth'
import { startSubmit, stopSubmit } from 'redux-form'
import { NotificationManager } from 'react-notifications'


const AUTH_REQUEST = 'AUTH_REQUEST'
const CHECK_AUTH_REQUEST = 'CHECK_AUTH_REQUEST'
const RESET_AUTH_DATA = 'RESET_AUTH_DATA'

const initialState = {
  data:
    localStorage.getItem('login') && localStorage.getItem('pass')
      ? {
          login: localStorage.getItem('login'),
          pass: localStorage.getItem('pass'),
        }
      : undefined,
  error: null,
}

export const authReducer = (state = initialState, action) => {
  switch (action.type) {
    case RESET_AUTH_DATA:
      return {
        data: undefined,
        error: null,
      }

    case `${AUTH_REQUEST}${SUCCESS}`:
      return {
        data: action.payload,
        error: null,
      }

    case `${CHECK_AUTH_REQUEST}${SUCCESS}`:
      return {
        data: action.payload,
        error: null,
      }

    case `${AUTH_REQUEST}${ERROR}`:
      return {
        data: null,
        error: action.payload,
      }

    case `${CHECK_AUTH_REQUEST}${ERROR}`:
      return {
        data: null,
        error: null,
      }

    default:
      return state
  }
}

// ACTIONS

export const authRequestStart = (login, pass) => ({
  type: `${AUTH_REQUEST}${START}`,
  payload: { login, pass },
})

export const authRequestSuccess = (data, authData) => ({

  type: `${AUTH_REQUEST}${SUCCESS}`,
  payload: { ...data, ...authData },
})

export const authRequestError = data => ({
  type: `${AUTH_REQUEST}${ERROR}`,
  payload: data,
})

export const resetAuthData = () => ({
  type: RESET_AUTH_DATA,
})

export const checkAuthRequestStart = () => ({
  type: `${CHECK_AUTH_REQUEST}${START}`,
})

export const checkAuthRequestSuccess = data => ({
  type: `${CHECK_AUTH_REQUEST}${SUCCESS}`,
  payload: data,
})

export const checkAuthRequestError = data => ({
  type: `${CHECK_AUTH_REQUEST}${ERROR}`,
  payload: data,
})

// SAGAS

export function* authWatcher() {
  yield takeLatest(`${AUTH_REQUEST}${START}`, authWorker)
  yield takeLatest(`${RESET_AUTH_DATA}`, logoutWorker)
  yield takeLatest(`${CHECK_AUTH_REQUEST}${START}`, checkAuthWorker)
}

function* authWorker({ payload }) {
  
  yield put(startSubmit('login'))
  try {
    const res = yield call(authAPI.login, payload)
    const { Result, Message, Data } = res.data.Answer
    if (Result === 'Error') return NotificationManager.error(Message)
    const data = Data[0]
    yield put(authRequestSuccess(payload, data))
    yield put(stopSubmit('login'))
    const { login, pass } = yield select(({ auth }) => auth.data)
    localStorage.setItem('login', login)
    localStorage.setItem('pass', pass)
    localStorage.setItem('fio', data.FIO)
  } catch (error) {
    if (error.response) {
      yield put(authRequestError(error.response.data))
      const messageError = error.response.data.Answer.Message
      const message = messageError.length > 0 ? messageError : 'Возникла непредвиденная ошибка'
      yield put(stopSubmit('login', { _error: message }))
    }
  } finally {
    yield put(stopSubmit('login'))
  }
}

function* logoutWorker() {
  localStorage.removeItem('login')
  localStorage.removeItem('pass')
  localStorage.removeItem('fio')
  try {
    yield call(authAPI.logout)
    yield put(resetAuthData())
  } catch (e) {
    console.log(e)
  }
}

function* checkAuthWorker() {
  try {
    const response = yield call(authAPI.checkAuth)
    yield put(checkAuthRequestSuccess(response.data))
  } catch (error) {
    yield put(checkAuthRequestError(error))
  }
}

export const getAuthData = state => state.auth.data

import React, { useEffect } from 'react'
import RouteLayout from '../../RouteLayout'
import { CasesNumberTable } from './Table'
import { CasesNumberForm } from './Form'
import { useDispatch, useSelector } from 'react-redux'
import { casesNumberRequestThunk as requestReport } from '../../../redux/reducers/casesNumber/casesNumberReducer'
import Spinner from '../../common/Spinner'

export const CasesNumber = () => {
  const auth = useSelector(({ auth }) => auth.data)
  const { list, isLoading } = useSelector(({ reports }) => reports.casesNumber)

  const dispatch = useDispatch()

  const requestReportHandler = formData => {
    dispatch(requestReport({ ...auth, ...formData }))
  }

  return (
    <RouteLayout title={'Количество личных дел кандидатов на поступление'}>
      <CasesNumberForm onSubmit={requestReportHandler} />
      {isLoading ? <Spinner /> : <CasesNumberTable data={list} />}
    </RouteLayout>
  )
}

import {SET_FORM_DATA} from "../connectFailsReducer";
import moment from "moment";


const initialState = {
    'school': false,
    'idOrg': "0",
    'datefrom': moment().startOf('month').format("YYYY-MM-DD"),
    'dateto': moment().format("YYYY-MM-DD")
};

export const connectFailsFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FORM_DATA:
            return action.payload;
        default:
            return state;
    }
};

import { SET_DETAILS, SET_DETAILS_LOADING } from "../connectFailsReducer";



const initialState = {
    data: null,
    loading: false
};

export const connectFailsDetailsReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_DETAILS:
            return {
                ...state,
                data: action.payload
            };
        case SET_DETAILS_LOADING:
            return {
                ...state,
                loading: action.payload
            };

        default:
            return state;
    }
};
import { call, put, takeLatest, select } from 'redux-saga/effects'
import { reportsAPI } from '../../../api/reports'
import { createActions } from '../../factory/actionFactory'
import { START } from '../../../constants/action-postfix'
import { combineReducers } from 'redux'
import { formReducer } from './form/formReducer'
import { listReducer } from './list/listReducer'
import { moveReducer } from './move/moveReducer'
import {SET_FOREIGN_MODE} from '../../types'


export const STUDENTS_TOTAL_REQUEST = 'STUDENTS_TOTAL_REQUEST'
export const STUDENTS_MOVE_REQUEST = 'STUDENTS_MOVE_REQUEST'
export const SET_STUDENTS_TOTAL_FORM_DATA = 'SET_STUDENTS_TOTAL_FORM_DATA'

export const studentsTotalReducer = combineReducers({
  list: listReducer,
  form: formReducer,
  details: moveReducer,
})

// ACTIONS

export const studentsTotalAction = createActions('studentsTotal', STUDENTS_TOTAL_REQUEST)
export const studentsMoveAction = createActions('studentsMove', STUDENTS_MOVE_REQUEST)
export const setStudentsTotalFormData = formData => ({
  type: SET_STUDENTS_TOTAL_FORM_DATA,
  payload: formData,
})

// SAGAS

export function* studentsTotalWatcher() {
  yield takeLatest(`${STUDENTS_TOTAL_REQUEST}${START}`, totalWorker)
  yield takeLatest(`${STUDENTS_MOVE_REQUEST}${START}`, moveWorker)
}

function* totalWorker({ payload }) {
  try {
    const res = yield call(reportsAPI.studentsTotal, payload)
    yield put(studentsTotalAction.studentsTotalRequestSuccess(res.data.Answer.Data_.classes))
    const foreignMode = !!payload.foreign
    yield put({type: SET_FOREIGN_MODE, payload: foreignMode})
    // yield put(stopSubmit('generalStatAll'))  // // для разблокировки кнопки после загрузки
  } catch (error) {
    yield put(studentsTotalAction.studentsTotalRequestError(error))
  }
}

function* moveWorker({ payload }) {
  try {
    const foreign = Number(yield select(state => state.reports.studentsTotal.list.foreign))
    const res = yield call(reportsAPI.studentsMove, {...payload, foreign})
    yield put(studentsMoveAction.studentsMoveRequestSuccess(res.data.Answer))
  } catch (error) {
    yield put(studentsMoveAction.studentsMoveRequestError(error))
  }
}

import React from 'react'
import classNames from 'classnames'
import styles from './Modal.module.css'

export const Modal = ({ title, isVisible, children, className, contentClassName }) => {
  if (!isVisible) return null

  return (
    <>
      <div className={styles.background} />
      <div className={styles.container}>
        <div className={classNames(styles.modal, className)}>
          <div className={styles.modalHead}>{title}</div>
          <div className={classNames(styles.modalContent, contentClassName)}>{children}</div>
        </div>
      </div>
    </>
  )
}

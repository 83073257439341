import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import styles from './GeneralStatisticsAll.module.css'
import { generalStatAllAction } from '../../../redux/reducers/general-statistic-all-reducer'
import Spinner from '../../common/Spinner/index'
import GeneralStatisticsAllTable from './GeneralStatisticsAllTable/GeneralStatisticsAllTable'
import GeneralStatisticsAllForm from './GeneralStatisticsAllForm/GeneralStatisticsAllForm'
import GeneralStatisticsAllChart from './DataChart'
import { organizationList } from '../../../redux/reducers/organization-list-reducer'
import { setCurrentDate } from './../../../redux/reducers/initialize-values-in-form'
import { startSubmit } from 'redux-form'
import { setGeneralStatisticAllPdfData } from '../../../redux/reducers/PDFReports/generalStatisticsAllPdfReducer'

const GeneralStatisticsAll = ({
  auth,
  data,
  generalStatisticsAllStart,
  isLoading,
  orgList,
  organizationListStart,
  setCurrentDate,
  pdfReportData,
  setPdfReportData,
}) => {
  const replacePdfReportSumm = data => {
    return data.map(item => ({
      ...item,
      summ: item.summ.replace('.', ','),
    }))
  }

  const sortPdfReportTable = (field, order) => {
    const checkOrder = (a, b) => {
      if (field === 'summ') {
        return order === 'asc' ? b.summ - a.summ : a.summ - b.summ
      }

      if (field === 'name') {
        if (a.name > b.name) {
          return 1
        }
        if (a.name < b.name) {
          return -1
        }
        return 0
      }
    }

    let updatedReportData = [...data].sort((a, b) => checkOrder(a, b))
    updatedReportData = replacePdfReportSumm(updatedReportData)
    setPdfReportData(updatedReportData)
  }

  useEffect(() => {
    if (orgList !== undefined && orgList.length === 0) {
      organizationListStart(auth)
    }
  }, [organizationListStart, auth])

  useEffect(() => {
    let updatedData = [...data]
    updatedData = replacePdfReportSumm(updatedData)

    setPdfReportData(updatedData)
  }, [data])

  const onSubmit = ({ date }) => {
    // startSubmit('generalStatAll') // для блокировки кнопки во время загрузки
    setCurrentDate(date)
    generalStatisticsAllStart({ ...auth, date })
  }

  return (
    <div className='main'>
      <GeneralStatisticsAllForm onSubmit={onSubmit} data={data} pdfReportData={pdfReportData} />

      {isLoading && <Spinner />}
      {!!data.length && (
        <>
          <GeneralStatisticsAllTable data={data} sortPdfReportTable={sortPdfReportTable} />
          <GeneralStatisticsAllChart data={data} />
        </>
      )}
    </div>
  )
}

const mapState = state => ({
  auth: state.auth.data,
  data: state.reports.generalAll.data,
  isLoading: state.reports.generalAll.isLoading,
  orgList: state.reports.orgList.data,
  pdfReportData: state.pdfReports.generalStatistic,
})

export default connect(mapState, {
  generalStatisticsAllStart: generalStatAllAction.generalStatisticsAllRequestStart,
  organizationListStart: organizationList.organizationListRequestStart,
  setCurrentDate: setCurrentDate,
  setPdfReportData: setGeneralStatisticAllPdfData,
  startSubmit,
})(GeneralStatisticsAll)

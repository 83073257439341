import {ERROR, START, SUCCESS} from "../../../../../constants/action-postfix";
import {DISEASED_WEEK_REQUEST} from "../diseasedWeekReducer";

const initialState = [];

export const diseasedWeekListReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${DISEASED_WEEK_REQUEST}${SUCCESS}`:
            return action.payload;

        default:
            return state;
    }
};
import {SET_FORM_DATA} from "../vaccinatedReducer";
import moment from "moment";

const initialState = {
    'date': moment().format('YYYY-MM-DD'),
    'school': false
};

export const vaccinatedFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_FORM_DATA:
            return action.payload;
        default:
            return state;
    }
};

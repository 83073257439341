import { reportsAPI } from '../../../api/reports'

const SET_CASES_NUMBER = 'SET_CASES_NUMBER'
const CASES_NUMBER_TOGGLE_LOADING = 'CASES_NUMBER_TOGGLE_LOADING'

const initialState = {
  list: [],
  isLoading: false,
}

export const casesNumberReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_CASES_NUMBER:
      return { ...state, list: action.payload || [] }

    case CASES_NUMBER_TOGGLE_LOADING:
      return { ...state, isLoading: !state.isLoading }

    default:
      return state
  }
}

export const casesNumberToggleLoading = () => ({ type: CASES_NUMBER_TOGGLE_LOADING })

export const setCasesNumber = payload => ({ type: SET_CASES_NUMBER, payload })

export const casesNumberRequestThunk = searchData => async dispatch => {
  try {
    await dispatch(casesNumberToggleLoading())
    const response = await reportsAPI.casesNumber(searchData)
    await dispatch(setCasesNumber(response.data.Answer.Data))
  } catch {
    dispatch(setCasesNumber([]))
  } finally {
    await dispatch(casesNumberToggleLoading())
  }
}

import {CANDIDATES_REQUEST} from "../candidatesReducer";
import {SUCCESS} from "../../../../constants/action-postfix";


const initialState = [];

export const candidatesListReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${CANDIDATES_REQUEST}${SUCCESS}`:
            return action.payload;

        default:
            return state;
    }
};
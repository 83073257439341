import { api } from './index'
import { BASE_PARAMS } from '../constants/api'
import { formatDate } from '../utils/formatDate'

export const reportsAPI = {
  generalStatisticsAll: payload => {
    const params = {
      ...BASE_PARAMS,
      ...payload,
      date: formatDate(payload.date),
      action: `${BASE_PARAMS.action}.report.GeneralStatisticsAll`,
    }
    return api.get('/', { params })
  },
  generalStatisticsOne: payload => {
    const params = {
      ...BASE_PARAMS,
      ...payload,
      date: formatDate(payload.date),
      action: `${BASE_PARAMS.action}.report.GeneralStatisticsOne`,
    }
    return api.get('/', { params })
  },
  organizationList: ({ login, pass }) => {
    const params = {
      ...BASE_PARAMS,
      login,
      pass,
      action: `${BASE_PARAMS.action}.list.organizations`,
    }
    return api.get('/', { params })
  },
  bestSchoolReport: (authData, formData) => {
    const params = {
      ...BASE_PARAMS,
      ...authData,
      specparamsId: 0,
      ...formData,
      action: `${BASE_PARAMS.action}.report.GeneralStatisticsFull`,
    }

    return api.get('/', { params })
  },
  diseasedWeekReport: ({ login, pass, datefrom, dateto, idOrg, school }) => {
    const params = {
      ...BASE_PARAMS,
      action: `${BASE_PARAMS.action}.report.Covid19_X`,
      login,
      pass,
      datefrom,
      dateto,
      idOrg,
      school,
      type: 0,
    }

    return api.get(`/`, { params })
  },
  diseasedMonthReport: ({ login, pass, datefrom, dateto, idOrg, school }) => {
    const params = {
      ...BASE_PARAMS,
      action: `${BASE_PARAMS.action}.report.Covid19_X`,
      login,
      pass,
      datefrom,
      dateto,
      idOrg,
      school,
      type: 1,
    }

    return api.get(`/`, { params })
  },
  diseasedPeriodReport: ({ login, pass, datefrom, dateto, idOrg, school }) => {
    const params = {
      ...BASE_PARAMS,
      action: `${BASE_PARAMS.action}.report.Covid19`,
      login,
      pass,
      datefrom,
      dateto,
      idOrg,
      school,
    }

    return api.get(`/`, { params })
  },

  diseasedCovidReport: ({ login, pass, date, idOrg, school }) => {
    const params = {
      ...BASE_PARAMS,
      action: `${BASE_PARAMS.action}.report.Covid19_detailed`,
      login,
      pass,
      date,
      idOrg,
      school,
    }

    return api.get(`/`, { params })
  },
  candidatesReport: ({ login, pass, year, date, status, idOrg }) => {
    const params = {
      ...BASE_PARAMS,
      action: `${BASE_PARAMS.action}.report.ApplicantSubjects`,
      login,
      pass,
      year,
      date,
      status,
      idOrg,
    }

    return api.get(`/`, { params })
  },
  studentsTotal: ({ login, pass, dateto, school, idOrg, foreign }) => {
    const params = {
      ...BASE_PARAMS,
      action: `${BASE_PARAMS.action}.report.students_total`,
      login,
      pass,
      school,
      foreign,
      date: dateto,
      idOrg,
    }

    return api.get(`/`, { params })
  },

  casesNumber: ({ login, pass, fromdate, todate, stream, school, status }) => {
    const params = {
      ...BASE_PARAMS,
      action: `${BASE_PARAMS.action}.report.ApplicantCount`,
      login,
      pass,
      fromdate,
      todate,
      stream,
      status,
      school,
    }

    return api.get(`/`, { params })
  },

  studentsMove: ({ login, pass, datefrom, dateto, idOrg, kurs, groupid, foreign }) => {
    const params = {
      ...BASE_PARAMS,
      action: `${BASE_PARAMS.action}.report.students_move`,
      login,
      pass,
      datefrom,
      dateto,
      idOrg,
      kurs,
      groupid,
      foreign
    }

    return api.get(`/`, { params })
  },

  phoneBook: ({login, pass, formData, idOrg, school}) => {
    const params = {
      ...BASE_PARAMS,
      action: `${BASE_PARAMS.action}.phonebook`,
      login,
      school,
      idOrg,
      pass,
      ...formData
    }
  
  return api.get(`/`, {params})
  },

  vaccinated: ({login, pass, onDate, school }) => {

    const params = {
      ...BASE_PARAMS,
      action: `${BASE_PARAMS.action}.vaccination`,
      login,
      school,
      onDate,
      pass,
    }
  return api.get(`/`, {params})
  },

  duplicates: ({login, pass, status, school, year, stream}) => {

    const params = {
      ...BASE_PARAMS,
          action: `${BASE_PARAMS.action}.report.clones`,
          login,
          pass,
          status,
          school,
          year,
          stream
    }
  return api.get(`/`, {params})  
  },
  
  connectFails: ({login, pass, org, school, datefrom, dateto}) => {

    const params = {
      ...BASE_PARAMS,
          action: `${BASE_PARAMS.action}.faults`,
          login,
          pass,
          org,
          school,
          from: datefrom,
          to: dateto
    }
  return api.get(`/`, {params})  
  },

  connectFailsDetails: ({login, pass, org, datefrom, dateto}) => {
    const params = {
      ...BASE_PARAMS,
          action: `${BASE_PARAMS.action}.faults.list`,
          login,
          pass,
          org,
          from: datefrom,
          to: dateto
    }
    return api.get(`/`, {params})  
  },

  privilegesList: (payload) => {
    const params = {
      ...BASE_PARAMS,
      ...payload,
      action: `${BASE_PARAMS.action}.report.privilege`,
    }
    return api.get(`/`, {params})  
  },

  privilegesDetails: (payload) => {
    const params = {
      ...BASE_PARAMS,
      ...payload,
      action: `${BASE_PARAMS.action}.report.privilege.detail`,
    }
    return api.get(`/`, {params})  
  },

  graduates: (payload) => {
    const params = {
      ...BASE_PARAMS,
      ...payload,
      action: `${BASE_PARAMS.action}.report_graduates`,
    }
    return api.get(`/`, {params})
  }
}

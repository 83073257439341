import React, { useState, useEffect } from "react";
import { connect } from "react-redux";
import styles from "./BestSchoolReport.module.css";
import BestSchoolDataTable from "./BestSchoolTableNew";
import {
  bestSchoolActions,
  specparamsActions,
} from "../../../redux/reducers/BestSchool/bestSchoolReducer";
import { setBestSchoolFormData } from "../../../redux/reducers/BestSchool/Form/bestSchoolFormReducer";
import Spinner from "../../common/Spinner";
import BestSchoolForm from "./Form";
import { Redirect } from "react-router-dom";

const mapStateToProps = (state) => ({
  data: state.reports.bestSchool.data,
  loader: state.reports.bestSchool.isLoading,
  formData: state.reports.bestSchoolForm,
  specparams: state.specparams,
});

const mapDispatchToProps = {
  requestData: bestSchoolActions.bestSchoolRequestStart,
  setFormData: setBestSchoolFormData,
  requestSpecparams: specparamsActions.specparamsRequestStart,
};

const BestSchoolReport = ({
  requestData,
  data,
  loader,
  formData,
  setFormData,
  requestSpecparams,
  specparams,
}) => {
  
  useEffect(() => {
    requestSpecparams()
  }, []);

  useEffect(() => {
    window.scrollTo(0, 0)
  },[loader])


  if (window.location.search !== "?authorized") {
    return <Redirect to={"/auth"} />;
  }

  return (
    <div className={styles.container}>
      <div className={styles.fixed}>
        <div className={styles.title_outer}>
          <div className={styles.title_first}>Объемные показатели</div>
          <div className={styles.title_second}>
            по определению лучшего училища (кадетского корпуса) для награждения
            Вымпелом Министерства обороны Российской Федерации
          </div>
        </div>
        <BestSchoolForm
          formData={formData}
          setFormData={setFormData}
          onSubmit={requestData}
          specparams={specparams}
        />
      </div>
      <div className={styles.table}>
        {loader ? <Spinner /> : (data && <BestSchoolDataTable/>)}
      </div>  
      
    </div>
  );
};

export default connect(mapStateToProps, mapDispatchToProps)(BestSchoolReport);

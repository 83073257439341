import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import RouteLayout from '../../RouteLayout'
import CandidatesForm from './Form'
import { organizationList } from '../../../redux/reducers/organization-list-reducer'
import { setCandidatesFormData, setCandidatesParamsData } from '../../../redux/reducers/Candidates/candidatesReducer'
import Spinner from '../../common/Spinner'
import CandidatesTable from './Table'
import { candidatesActions } from '../../../redux/reducers/Candidates/candidatesReducer'
import moment from 'moment'

const mapStateToProps = state => ({
  auth: state.auth.data,
  orgList: state.reports.orgList.data,
  formData: state.reports.candidates.form,
  paramsData: state.reports.candidates.params,
  loader: state.reports.candidates.loader,
  list: state.reports.candidates.list,
})

const mapDispatchToProps = {
  organizationListStart: organizationList.organizationListRequestStart,
  setFormData: setCandidatesFormData,
  requestReport: candidatesActions.candidatesRequestStart,
  setParamsData: setCandidatesParamsData,
}

export const STATUS_LIST = [
  {
    value: 0,
    name: 'Все',
  },
  {
    value: 1,
    name: 'Допущенные к вступительным испытаниям',
  },
  {
    value: 2,
    name: 'Не допущенные к вступительным испытаниям',
  },
  {
    value: 3,
    name: 'Прошедшие вступительные испытания',
  },
  {
    value: 4,
    name: 'Не прошедшие вступительные испытания',
  },
]

const Candidates = ({
  auth,
  orgList,
  organizationListStart,
  formData,
  setFormData,
  loader,
  requestReport,
  list,
  paramsData,
  setParamsData,
}) => {
  useEffect(() => {
    if (orgList !== undefined && orgList.length === 0) {
      organizationListStart(auth)
    }
  }, [organizationListStart, auth])

  const requestReportHandler = () => {
    requestReport({ ...auth, ...formData })
    setParamsData({
      status: STATUS_LIST.find(item => item.value == formData.status),
      idOrg: formData.idOrg,
      year: formData.year,
      date: moment(formData.date).format('DD.MM.YYYY'),
    })
  }

  return (
    <RouteLayout title={'КАНДИДАТЫ НА ПОСТУПЛЕНИЕ ПО СУБЪЕКТАМ РФ'}>
      <CandidatesForm orgList={orgList} onSubmit={requestReportHandler} formData={formData} setFormData={setFormData} />

      {loader ? <Spinner /> : <CandidatesTable data={list} paramsData={paramsData} orgList={orgList} />}
    </RouteLayout>
  )
}

export default connect(mapStateToProps, mapDispatchToProps)(Candidates)

import React, {useState} from 'react';
import PropTypes from 'prop-types';

import {Button, Col, FormCheckbox, FormGroup, FormSelect, Row} from "shards-react";
import moment from "moment";

import {MONTHS} from "../monthsList";

const INITIAL_FORM_VALUES = {
    'school': false,
    'idOrg': "0",
    'month': moment().format('MM'),
    'year': new Date().getFullYear()
};



const DiseasedMonthForm = ({ orgList, onSubmit }) => {
    const { year: currentYear } = INITIAL_FORM_VALUES;

    const yearsList = [currentYear - 1, currentYear, currentYear + 1];


    const [formValues, setFormValues] = useState(INITIAL_FORM_VALUES);


    const handleInputChange = (e) => {
        setFormValues({
            ...formValues,
            [e.target.name]: e.target.value
        })
    };


    return (
        <Row className={'my-3'}>
            <Col md={'auto'} className={'d-flex align-items-center'}>
                <FormGroup
                    className={'mr-3'}
                >
                    <label htmlFor="_year">Выберите год</label>
                    <FormSelect
                        name="year"
                        id='_year'
                        size="sm"
                        className="mb-2"
                        onChange={handleInputChange}
                    >
                        {yearsList.map(item =>
                            <option value={item} selected={item === currentYear}>{item}</option>
                        )}
                    </FormSelect>
                </FormGroup>
                <FormGroup
                    className={'mr-3'}
                >
                    <label htmlFor="_month">Выберите месяц</label>
                    <FormSelect
                        name='month'
                        id={"_month"}
                        size="sm"
                        className="mb-2"
                        onChange={handleInputChange}
                    >
                        {MONTHS.map(item =>
                            <option key={item.name} value={item.num} selected={item.num === formValues.month}>{item.name}</option>
                        )}
                    </FormSelect>
                </FormGroup>
                <FormGroup
                    className={'mr-3'}
                    style={{maxWidth: '300px'}}
                >
                    <label htmlFor="_idOrg">Выберите организацию</label>
                    <FormSelect
                        name={'idOrg'}
                        id='_idOrg'
                        size="sm"
                        className="mb-2"
                        onChange={handleInputChange}
                    >
                        <option value={0}>Все организации</option>
                        {orgList.map(item =>
                            <option key={item.idOrganization} value={item.idOrganization}>{item.orgName}</option>
                        )}
                    </FormSelect>
                </FormGroup>
                {formValues.idOrg === "0" &&
                <FormCheckbox
                    onChange={() => setFormValues({
                        ...formValues,
                        'school': !formValues.school
                    })}
                    name={'school'}
                    checked={formValues.school}
                    className={'mr-3'}
                >
                    Школы
                </FormCheckbox>
                }
                <Button
                    onClick={() => onSubmit({...formValues})}
                >Создать отчет</Button>
            </Col>
        </Row>
    );
};

DiseasedMonthForm.propTypes = {
    orgList: PropTypes.array,
    onSubmit: PropTypes.func,
};

export default DiseasedMonthForm;
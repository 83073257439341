import React, { useMemo } from 'react'
import styles from './CasesNumberTable.module.css'
import ExcelExporter from '../../Diseased/ExcelExporter'
import { TableRow } from './TableRow'
import { TableFooter } from './TableFooter'

export const CasesNumberTable = ({ data }) => {
  const GENDER = {
    GUYS: 'guys',
    GIRLS: 'girls',
  }

  const formattedData = useMemo(() => {
    const newData = []
    data.forEach(e => {
      if (newData.length && newData[newData.length - 1].idOrganization === e.idOrganization) {
        e.gender === 'м'
          ? newData[newData.length - 1].guys.push({ class_to_enter: e.class_to_enter, cnt: e.cnt })
          : newData[newData.length - 1].girls.push({ class_to_enter: e.class_to_enter, cnt: e.cnt })
      } else {
        newData.push({
          idOrganization: e.idOrganization,
          orgName: e.orgName,
          guys: e.gender === 'м' ? [{ class_to_enter: e.class_to_enter, cnt: e.cnt }] : [],
          girls: e.gender === 'ж' ? [{ class_to_enter: e.class_to_enter, cnt: e.cnt }] : [],
        })
      }
    })
    return newData
  }, [])
  



  if (!data.length) return <></>
  return (
    <ExcelExporter title="Количество личных дел кандидатов.xlsx" sheetName='Переменный состав'>
      <div>
        <table className={styles.table}>
          <thead>
            <tr>
              <th>№</th>
              <th rowSpan='2'>Училище</th>
              <th rowSpan='2'>
                Пол <br /> кандидатов
              </th>
              <th colSpan='8'>Кол-во личных дел кандидатов</th>
            </tr>
            <tr>
              <th></th>
              <th>5 класс</th>
              <th>6 класс</th>
              <th>7 класс</th>
              <th>8 класс</th>
              <th>9 класс</th>
              <th>
                10 класс <br /> (l курс)
              </th>
              <th>
                11 класс
                <br /> (ll курс)
              </th>
              <th>ВСЕГО</th>
            </tr>
          </thead>
          <tbody>
            {formattedData.map((e, i) => {
              return e.guys.length && e.girls.length ? (
                <>
                  <tr>
                    <td rowSpan='2'>{i + 1}</td>
                    <td style={{ textAlign: 'left' }} rowSpan='2'>
                      {e.orgName}
                    </td>
                    <TableRow rowData={e} gender={GENDER.GUYS} />
                  </tr>
                  <tr>
                    <TableRow rowData={e} gender={GENDER.GIRLS} />
                  </tr>
                </>
              ) : (
                <tr>
                  <td>{i + 1}</td>
                  <td style={{ textAlign: 'left' }}>{e.orgName}</td>
                  <TableRow rowData={e} gender={e.guys.length ? GENDER.GUYS : e.girls.length ? GENDER.GIRLS : null} />
                </tr>
              )
            })}
            <TableFooter data={data} />
          </tbody>
        </table>
      </div>
      <div></div>
    </ExcelExporter>
  )
}

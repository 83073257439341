import React, { useMemo } from 'react'
import PropTypes from 'prop-types'
import { Typography, Table } from 'antd'
import ExcelExporter from '../../Diseased/ExcelExporter'

const CandidatesTable = ({ data, paramsData, orgList }) => {
  const { Text, Link } = Typography

  const updatedData = useMemo(() => {
    const a = [...data]
    const b = a.pop()
    return [a, b]
  }, [data])

  const columns = [
    {
      title: 'Субъект РФ',
      dataIndex: 'Subject',
    },
    {
      title: 'Количество кандидатов',
      dataIndex: 'Total',
    },

    {
      title: 'Категории детей, имеющих преимущественное право зачисления *',
      children: [
        {
          title: 'A',
          dataIndex: 'cnt_A',
          width: 50,
        },
        {
          title: 'Б',
          dataIndex: 'cnt_B',
        },
        {
          title: 'В',
          dataIndex: 'cnt_V',
        },
        {
          title: 'Г',
          dataIndex: 'cnt_G',
        },
        {
          title: 'Д',
          dataIndex: 'cnt_D',
        },
        {
          title: 'Е',
          dataIndex: 'cnt_E',
        },
        {
          title: 'Ж',
          dataIndex: 'cnt_Zh',
        },
        {
          title: 'З',
          dataIndex: 'cnt_Z',
        },
        {
          title: 'И',
          dataIndex: 'cnt_I',
        },
        {
          title: 'К',
          dataIndex: 'cnt_K',
        },
        {
          title: 'Л',
          dataIndex: 'cnt_L',
        },
        {
          title: 'Л1',
          dataIndex: 'cnt_L1',
        },
        {
          title: 'М',
          dataIndex: 'cnt_M',
        },
      ],
    },
  ]

  const mainColumns = useMemo(
    () =>
      paramsData.idOrg == 0
        ? [
            ...columns,
            {
              title: 'Наименование образовательной организации',
              dataIndex: 'orgname',
            },
          ]
        : columns,
    [paramsData.idOrg]
  )

  return (
    <div>
      {!!data.length && (
        <React.Fragment>
          <ExcelExporter title="Кандидаты на поступление по субъектам РФ.xlsx">
            <div>
              <h3 className={'text-center mb-2'}>
                {paramsData.idOrg != 0 && (
                  <React.Fragment>
                    {orgList.find(item => item.idOrganization == paramsData.idOrg).orgName}
                    <br />
                  </React.Fragment>
                )}
                {paramsData.idOrg != 0 ? (
                  <React.Fragment>
                    Кандидаты на поступление в {paramsData.year} году,
                    <br />{' '}
                    {!!paramsData.status.value && paramsData.status.value != 0 && (
                      <React.Fragment> {paramsData.status.name.toLowerCase()},</React.Fragment>
                    )}{' '}
                    по состоянию на {paramsData.date} г.
                  </React.Fragment>
                ) : (
                  <React.Fragment>
                    Кандидаты на поступление в довузовские образовательные организации Министерства обороны Российской Федерации в{' '}
                    {paramsData.year} году,
                    <br />
                    {!!paramsData.status.value && paramsData.status.value != 0 && <React.Fragment> {paramsData.status.name.toLowerCase()}, </React.Fragment>}
                    по состоянию на {paramsData.date} г.
                  </React.Fragment>
                )}
              </h3>
            </div>

            <div>
              <Table
                summary={() => {
                  let { Total, cnt_A, cnt_B, cnt_D, cnt_E, cnt_G, cnt_I, cnt_K, cnt_L, cnt_L1, cnt_V, cnt_Z, cnt_Zh, cnt_M } = updatedData[1]

                  return (
                    <Table.Summary.Row>
                      <Table.Summary.Cell>
                        <Text strong>ИТОГИ:</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text strong>{Total}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text strong>{cnt_A}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text strong>{cnt_B}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text strong>{cnt_V}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text strong>{cnt_G}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text strong>{cnt_D}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text strong>{cnt_E}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text strong>{cnt_Zh}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text strong>{cnt_Z}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text strong>{cnt_I}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text strong>{cnt_K}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text strong>{cnt_L}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text strong>{cnt_L1}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell>
                        <Text strong>{cnt_M}</Text>
                      </Table.Summary.Cell>
                      <Table.Summary.Cell></Table.Summary.Cell>
                    </Table.Summary.Row>
                  )
                }}
                dataSource={updatedData[0]}
                columns={mainColumns}
                pagination={false}
                bordered
              />
            </div>
          </ExcelExporter>
          <br />
          <p>
            <strong>А</strong> - дети-сироты и дети, оставшиеся без попечения родителей;
          </p>
          <p>
            <strong>Б</strong> - дети военнослужащих, проходящих военную службу по контракту;
          </p>
          <p>
            <strong>В</strong> - дети государственных гражданских служащих и гражданского персонала федеральных органов
            исполнительной власти, в которых федеральным законом предусмотрена военная служба;
          </p>
          <p>
            <strong>Г</strong> - дети граждан, которые уволены с военной службы по достижении ими предельного возраста пребывания
            на военной службе, по состоянию здоровья или в связи с организационно-штатными мероприятиями и общая продолжительность
            военной службы которых составляет 20 лет и более;
          </p>
          <p>
            <strong>Д</strong> - дети военнослужащих, погибших при исполнении ими обязанностей военной службы или умерших
            вследствие увечья (ранения, травмы, контузии) или заболевания, полученных при исполнении обязанностей военной службы;
          </p>
          <p>
            <strong>Е</strong> - дети Героев Советского Союза, Героев Российской Федерации и полных кавалеров ордена Славы;
          </p>
          <p>
            <strong>Ж</strong> - дети сотрудников органов внутренних дел, погибших или умерших вследствие увечья или иного
            повреждения здоровья, полученных в связи с исполнением служебных обязанностей, либо вследствие заболевания,
            полученного в период прохождения службы в органах внутренних дел;
          </p>
          <p>
            <strong>З</strong> - дети, находящиеся на иждивении указанных лиц;
          </p>
          <p>
            <strong>И</strong> - дети прокурорских работников, погибших или умерших вследствие увечья или иного повреждения
            здоровья, полученных ими в период службы в органах прокуратуры либо после увольнения вследствие причинения вреда
            здоровью в связи с их служебной деятельностью;
          </p>
          <p>
            <strong>К</strong> - иные лица в случаях, установленных законодательством Российской Федерации.
          </p>
          <p>
            <strong>Л</strong> - дети военнослужащих и сотрудников федеральных органов исполнительной власти и федеральных государственных
            органов, в которых федеральным законом предусмотрена военная служба, сотрудников органов внутренних дел Российской Федерации,
            принимающих (принимавших) участие в специальной военной операции на территориях Донецкой Народной Республики, Луганской Народной
            Республики и Украины, погибших (умерших), получивших увечье (ранение, травму, контузию) или заболевание при исполнении обязанностей военной службы (службы).
          </p>
          <p>
            <strong>М</strong> - дети Героев Российской Федерации, лица, награжденные тремя орденами Мужества; дети принимавших участие в специальной
            военной операции на территориях Украины, Донецкой Народной Республики, Луганской Народной Республики, Запорожской области и Херсонской области;
            дети военнослужащих, сотрудников федеральных органов исполнительной власти и федеральных государственных органов, в которых федеральным законом
            предусмотрена военная служба, сотрудников органов внутренних дел Российской Федерации, направленных в другие государства органами государственной
            власти Российской Федерации и принимавших участие в боевых действиях при исполнении служебных обязанностей в этих государствах
          </p>
        </React.Fragment>
      )}
    </div>
  )
}

CandidatesTable.propTypes = {
  paramsData: PropTypes.object,
  data: PropTypes.array,
  orgList: PropTypes.array,
}

export default CandidatesTable

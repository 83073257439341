import React from "react";
import TableItem from "./TableItem";



export default function PhonebookTable({ data }) {
  

  return (
    <div>
      {data.length ?
        data.map((school, i) => <TableItem key={i} school={school} i={i} data={data}/>) : null}
    </div>
  );
}

import React from "react";

import {
  Button,
  Col,
  FormCheckbox,
  FormGroup,
  FormInput,
  FormSelect,
  Row,
} from "shards-react";
import { STATUS_LIST } from "../Duplicates";

const year = new Date().getFullYear();
const yearsList = [Number(year) - 1, Number(year), Number(year) + 1];

const STREAMS = [
  { value: 'Основной набор', id: 1 },
  { value: 'Донабор', id: 2 },
  { value: 'Все', id: 0 }
]

const VaccinatedReportForm = ({ onSubmit, setFormData, formData }) => {
  const { year: currentYear } = formData;

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  return (
    <Row className={"my-3 d-flex align-items-center"}>
      <Col md={"auto"} className={"d-flex align-items-center"}>
        <FormGroup>
          <label htmlFor="_year">Выберите год</label>
          <FormSelect
            name="year"
            id="_year"
            size="sm"
            className="mb-2 d-block"
            onChange={handleInputChange}
          >
            {yearsList.map((item) => (
              <option key={item} value={item} selected={item === currentYear}>
                {item}
              </option>
            ))}
          </FormSelect>
        </FormGroup>
      </Col>
      <Col md={3}>
                <FormGroup>
                    <label htmlFor="_status">Статус кандидатов</label>
                    <FormSelect
                        name={'status'}
                        id='_status'
                        size="sm"
                        className="mb-2"
                        onChange={handleInputChange}
                        value={formData.status}
                    >
                        {STATUS_LIST.map(item =>
                            <option key={item.value} value={item.value}>{item.name}</option>
                        )}
                    </FormSelect>
                </FormGroup>
      </Col>
      <Col md={3}>
      <FormGroup className={'mr-3'}>
          <label htmlFor='stream'>Тип набора</label>
          <FormSelect
            name={'stream'}
            id='stream'
            size='sm'
            className='mb-2'
            onChange={handleInputChange}
            value={formData.stream}
          >
            {STREAMS.map(({ value, id }) => (
              <option key={id} value={id} selected={id === 1}>
                {value}
              </option>
            ))}
          </FormSelect>
        </FormGroup>
      </Col>
      <Col md={"auto"} className={"d-flex align-items-end"}>
        <FormGroup>
          <FormCheckbox
            onChange={() =>
              setFormData({
                ...formData,
                school: !formData.school,
              })
            }
            name={"school"}
            checked={formData.school}
            className={"mr-3"}
          >
            Школы
          </FormCheckbox>
        </FormGroup>
      </Col>
      <Col md={"auto"} className={"d-flex align-items-end"}>
        <FormGroup>
          <Button onClick={onSubmit}>Создать отчет</Button>
        </FormGroup>
      </Col>
    </Row>
  );
};

export default VaccinatedReportForm;

import React, { useMemo, useRef } from "react";
import { Table } from "antd";
import styles from "./VaccinatedTable.module.css";
import { Button } from "shards-react";
import XLSX from "xlsx";
import { saveAs } from "file-saver";
import { cols } from "../../../../constants/vaccinated-table";

export default function VaccinatedTable({ data }) {

  const table = useRef()

  /* const parse = () => {
    const newEl = {}
    cols.split(" ").map((item, i) => (newEl[item] = colNames[i]))
    const newArr = data.map((item, i) => ({...item , idOrganization : i+1}))
    newArr.unshift(newEl)
    const wb = XLSX.utils.book_new();
    wb.SheetNames.push("test");
    const ws = XLSX.utils.json_to_sheet(newArr, {header:cols.split(" "), skipHeader:true});
    wb.Sheets["test"] = ws;
    const wbout = XLSX.write(wb, {
      bookType: "xlsx",
      bookSST: true,
      type: "binary",
    });
    const s2ab = (s) => {
      let buf = new ArrayBuffer(s.length);
      let view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    };
    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      "Статистика вакцинации от COVID-19.xlsx"
    );
  }; */

  const parse = () => {
    const wb = XLSX.utils.book_new();
    wb.SheetNames.push("test");
    const ws = XLSX.utils.table_to_sheet(document.getElementById("table"));
    wb.Sheets["test"] = ws;
    const wbout = XLSX.write(wb, {
      bookType: "xlsx",
      bookSST: true,
      type: "binary",
    });
    const s2ab = (s) => {
      let buf = new ArrayBuffer(s.length);
      let view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    };
    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      "Статистика вакцинации от COVID-19.xlsx"
    );
  }

  const columns = useMemo(
    () => [
      {
        title: "",
        children: [
          {
            title: (
              <p>
                No <nobr>п/п</nobr>
              </p>
            ),
            render: (text, record, index) => index + 1,
            width: 45,
          },
        ],
      },
      {
        title: "",
        children: [
          {
            title: "Наименование образовательной организации",
            dataIndex: "orgName",
            width: 330,
          },
        ],
      },
      {
        title: "Постоянный состав",
        children: [
          {
            title: "Подлежит вакцинации",
            children: [
              {
                title: <div className={styles.rotate}>Всего (чел.)</div>,
                dataIndex: "vaccinate_staff",
              },
            ],
          },
          {
            title: (
              <div className={styles.rotate}>
                Отказано по медицинским показаниям (чел.)
              </div>
            ),
            dataIndex: "excluded_staff",
          },
          {
            title: "Подлежит ревакцинации",
            children: [
              {
                title: <div className={styles.rotate}>Всего (чел.)</div>,
                dataIndex: "revaccinate_staff",
              },
            ],
          },
          {
            title: <div className={styles.rotate}>Выделено вакцин (шт.)</div>,
            dataIndex: "doses_staff",
          },
          {
            title: "Вакцинировано за сутки (чел.)",
            children: [
              {
                title: "I компонентом",
                children: [
                  {
                    title: "Постоянного состава (чел.)",
                    dataIndex: "perday1_staff",
                  },
                ],
              },
              {
                title: "II компонентом",
                children: [
                  {
                    title: "Постоянного состава (чел.)",
                    dataIndex: "perday2_staff",
                  },
                ],
              },
            ],
          },
          {
            title: (
              <div className={styles.rotate}>
                Процент полностью вакцинированных за сутки
              </div>
            ),
            dataIndex: "share_staff_day",
          },
          {
            title: "Вакцинировано всего (чел.)",
            children: [
              {
                title: "I компонентом",
                children: [
                  {
                    title: "Постоянного состава (чел.)",
                    dataIndex: "total1_staff",
                  },
                ],
              },
              {
                title: "II компонентом",
                children: [
                  {
                    title: "Постоянного состава (чел.)",
                    dataIndex: "total2_staff",
                  },
                ],
              },
            ],
          },
          {
            title: (
              <div className={styles.rotate}>
                Процент полностью вакцинированных
              </div>
            ),
            dataIndex: "share_staff_total",
          },
          {
            title: "Ревакцинировано за сутки (чел.)",
            children: [
              {
                title: "I компонентом",
                children: [
                  {
                    title: "Постоянного состава (чел.)",
                    dataIndex: "perday1r_staff",
                  },
                ],
              },
              {
                title: "II компонентом",
                children: [
                  {
                    title: "Постоянного состава (чел.)",
                    dataIndex: "perday2r_staff",
                  },
                ],
              },
            ],
          },
          {
            title: (
              <div className={styles.rotate}>
                Процент ревакцинорованных за сутки
              </div>
            ),
            dataIndex: "r_share_staff_day",
          },
          {
            title: "Ревакцинировано всего (чел.)",
            children: [
              {
                title: "I компонентом",
                children: [
                  {
                    title: "Постоянного состава (чел.)",
                    dataIndex: "total1r_staff",
                  },
                ],
              },
              {
                title: "II компонентом",
                children: [
                  {
                    title: "Постоянного состава (чел.)",
                    dataIndex: "total2r_staff",
                  },
                ],
              },
            ],
          },
          {
            title: (
              <div className={styles.rotate}>Процент ревакцинорованных</div>
            ),
            dataIndex: "r_share_staff_total",
          },
        ],
      },
      {
        title: "Переменный состав",
        children: [
          {
            title: "Подлежит вакцинации",
            children: [
              {
                title: <div className={styles.rotate}>Всего (чел.)</div>,
                dataIndex: "vaccinate_student",
              },
            ],
          },
          {
            title: (
              <div className={styles.rotate}>
                Отказано по медицинским показаниям (чел.)
              </div>
            ),
            dataIndex: "excluded_student",
          },
          {
            title: "Подлежит ревакцинации",
            children: [
              {
                title: <div className={styles.rotate}>Всего (чел.)</div>,
                dataIndex: "revaccinate_student",
              },
            ],
          },
          {
            title: <div className={styles.rotate}>Выделено вакцин (шт.)</div>,
            dataIndex: "doses_student",
          },
          {
            title: "Вакцинировано за сутки (чел.)",
            children: [
              {
                title: "I компонентом",
                children: [
                  {
                    title: "Переменного состава (чел.)",
                    dataIndex: "perday1_student",
                  },
                ],
              },
              {
                title: "II компонентом",
                children: [
                  {
                    title: "Переменного состава (чел.)",
                    dataIndex: "perday2_student",
                  },
                ],
              },
            ],
          },
          {
            title: (
              <div className={styles.rotate}>
                Процент полностью вакцинированных за сутки
              </div>
            ),
            dataIndex: "share_student_day",
          },
          {
            title: "Вакцинировано всего (чел.)",
            children: [
              {
                title: "I компонентом",
                children: [
                  {
                    title: "Переменного состава (чел.)",
                    dataIndex: "total1_student",
                  },
                ],
              },
              {
                title: "II компонентом",
                children: [
                  {
                    title: "Переменного состава (чел.)",
                    dataIndex: "total2_student",
                  },
                ],
              },
            ],
          },
          {
            title: (
              <div className={styles.rotate}>
                Процент полностью вакцинированных
              </div>
            ),
            dataIndex: "share_staff_total1",
          },
          {
            title: "Ревакцинировано за сутки (чел.)",
            children: [
              {
                title: "I компонентом",
                children: [
                  {
                    title: "Переменного состава (чел.)",
                    dataIndex: "perday1r_student",
                  },
                ],
              },
              {
                title: "II компонентом",
                children: [
                  {
                    title: "Переменного состава (чел.)",
                    dataIndex: "perday2r_student",
                  },
                ],
              },
            ],
          },
          {
            title: (
              <div className={styles.rotate}>
                Процент ревакцинорованных за сутки
              </div>
            ),
            dataIndex: "r_share_student_day",
          },
          {
            title: "Ревакцинировано всего (чел.)",
            children: [
              {
                title: "I компонентом",
                children: [
                  {
                    title: "Переменного состава (чел.)",
                    dataIndex: "total1r_student",
                  },
                ],
              },
              {
                title: "II компонентом",
                children: [
                  {
                    title: "Переменного состава (чел.)",
                    dataIndex: "total2r_student",
                  },
                ],
              },
            ],
          },
          {
            title: (
              <div className={styles.rotate}>Процент ревакцинорованных</div>
            ),
            dataIndex: "r_share_student_total",
          },
        ],
      },
    ],
    []
  );

  return (
    <div>
      <Button theme="success" className={"mb-2"} onClick={() => parse()}>
        Скачать xlsx
      </Button>
      <Table
        className={styles.table}
        bordered
        columns={columns}
        pagination={false}
        dataSource={data}
      />
      <table id="table" ref={table} style={{display: "none"}}>
        <tr>
          <th></th>
          <th></th>
          <th colSpan={16}>Постоянный состав</th>
          <th colSpan={16}>Переменный состав</th>
        </tr>
        <tr>
          <th rowSpan={3}>No п/п</th>
          <th rowSpan={3}>Наименование образовательной организации</th>
          <th>Подлежит вакцинации</th>
          <th rowSpan={3} className={styles.rotate}>Отказано по медицинским показаниям (чел.)</th>
          <th>Подлежит ревакцинации</th>
          <th rowSpan={3}>Выделено вакцин (шт.)</th>
          <th colSpan={2}>Вакцинировано за сутки (чел.)</th>
          <th rowSpan={3}>Процент полностью вакцинированных за сутки</th>
          <th colSpan={2}>Вакцинировано всего (чел.)</th>
          <th rowSpan={3}>Процент полностью вакцинированных</th>
          <th colSpan={2}>Ревакцинировано за сутки (чел.)</th>
          <th rowSpan={3}>Процент ревакцинорованных за сутки</th>
          <th colSpan={2}>Ревакцинировано всего (чел.)</th>
          <th rowSpan={3}>Процент ревакцинорованных</th>
          <th>Подлежит вакцинации</th>
          <th rowSpan={3}>Отказано по медицинским показаниям (чел.)</th>
          <th>Подлежит ревакцинации</th>
          <th rowSpan={3}>Выделено вакцин (шт.)</th>
          <th colSpan={2}>Вакцинировано за сутки (чел.)</th>
          <th rowSpan={3}>Процент полностью вакцинированных за сутки</th>
          <th colSpan={2}>Вакцинировано всего (чел.)</th>
          <th rowSpan={3}>Процент полностью вакцинированных</th>
          <th colSpan={2}>Ревакцинировано за сутки (чел.)</th>
          <th rowSpan={3}>Процент ревакцинорованных за сутки</th>
          <th colSpan={2}>Ревакцинировано всего (чел.)</th>
          <th rowSpan={3}>Процент ревакцинорованных</th>
        </tr>
        <tr>
          <th rowSpan={2}>Всего (чел.)</th>
          <th rowSpan={2}>Всего (чел.)</th>
          <th>I компонентом</th>
          <th>II компонентом</th>
          <th>I компонентом</th>
          <th>II компонентом</th>
          <th>I компонентом</th>
          <th>II компонентом</th>
          <th>I компонентом</th>
          <th>II компонентом</th>
          <th rowSpan={2}>Всего (чел.)</th>
          <th rowSpan={2}>Всего (чел.)</th>
          <th>I компонентом</th>
          <th>II компонентом</th>
          <th>I компонентом</th>
          <th>II компонентом</th>
          <th>I компонентом</th>
          <th>II компонентом</th>
          <th>I компонентом</th>
          <th>II компонентом</th>
        </tr>
        <tr>
          <th>Постоянного состава (чел.)</th>
          <th>Постоянного состава (чел.)</th>
          <th>Постоянного состава (чел.)</th>
          <th>Постоянного состава (чел.)</th>
          <th>Постоянного состава (чел.)</th>
          <th>Постоянного состава (чел.)</th>
          <th>Постоянного состава (чел.)</th>
          <th>Постоянного состава (чел.)</th>
          <th>Переменного состава (чел.)</th>
          <th>Переменного состава (чел.)</th>
          <th>Переменного состава (чел.)</th>
          <th>Переменного состава (чел.)</th>
          <th>Переменного состава (чел.)</th>
          <th>Переменного состава (чел.)</th>
          <th>Переменного состава (чел.)</th>
          <th>Переменного состава (чел.)</th>
        </tr>
        {data.map((it,i) => <tr>
          <td>{i+1}</td>
          {cols.split(" ").slice(1).map((item,i) => <td>{it[item]}</td>)}
        </tr>)}
      </table>
    </div>
  );
}

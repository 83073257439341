import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import DiseasedWeekTable from "./Table";
import DiseasedWeekForm from "./Form";
import {organizationList} from "../../../../redux/reducers/organization-list-reducer";
import {diseasedWeekActions, setWeekPeriodData} from "../../../../redux/reducers/Diseased/Week/diseasedWeekReducer";
import Spinner from "../../../common/Spinner";
import RouteLayout from "../../../RouteLayout";



const DiseasedWeek = ({ auth, organizationListStart, orgList, requestReport, list, loader, formData, setFormData }) => {

    useEffect(() => {
        if (orgList !== undefined && orgList.length === 0) {
            organizationListStart(auth)
        }
    }, [organizationListStart, auth]);

    const requestReportHandler = ({ school, idOrg, weekData }) => {
        const params = {
            school: school ? 1 : 0,
            idOrg,
            datefrom: weekData['start'].split('.').reverse().join('-'),
            dateto: weekData['end'].split('.').reverse().join('-'),
            weekData
        };

        setFormData({
            ...formData,
            period: {datefrom:  weekData['start'], dateto: weekData['end']}
        });

        requestReport({ ...auth,  ...params});

    };


    return (
        <RouteLayout
            title={'Сведения о заболевших за неделю'}
        >
            <DiseasedWeekForm orgList={orgList} onSubmit={requestReportHandler} formData={formData} setFormData={setFormData}/>
            {loader ?
                <Spinner />
                :
                <DiseasedWeekTable data={list} formData={formData}/>
            }
        </RouteLayout>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth.data,
    orgList: state.reports.orgList.data,
    list: state.reports.diseased.week.list,
    loader: state.reports.diseased.week.loader,
    formData: state.reports.diseased.week.form
});

const mapDispatchToProps = {
    organizationListStart: organizationList.organizationListRequestStart,
    requestReport: diseasedWeekActions.diseasedWeekRequestStart,
    setFormData: setWeekPeriodData
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DiseasedWeek);
import React, { useEffect } from 'react'
import { reduxForm, Field } from 'redux-form'
import Button from '../../../common/Button/index'
import Date from './../../../common/Date/Date'
import { Row, Col } from 'react-bootstrap'
import { connect } from 'react-redux'
import { compose } from 'redux'
import { PDFDownloadLink, Text, View } from '@react-pdf/renderer'
import PdfReports from '../../../PdfReports'
import { pdfStyles } from '../../../PdfReports/PdfStyles'
import * as docx from 'docx'
import { saveAs } from 'file-saver'
import pdfIcon from '../../../../img/icons/pdf.png'
import docIcon from '../../../../img/icons/doc.png'
import csvIcon from '../../../../img/icons/csv.png'
import { CSVLink } from 'react-csv'
import styles from './GeneralStatisticsAllForm.module.css'

/* PDF REPORTS */
const reportTableHeader = () => (
  <>
    <View fixed style={pdfStyles.tableRow}>
      <View style={{ ...pdfStyles.tableCol, ...pdfStyles.tableCol10 }}>
        <Text style={pdfStyles.tableCell}>Рейтинг</Text>
      </View>
      <View style={{ ...pdfStyles.tableCol, ...pdfStyles.tableCol70 }}>
        <Text style={pdfStyles.tableCell}>Образовательная организация</Text>
      </View>
      <View style={{ ...pdfStyles.tableCol, ...pdfStyles.tableCol20 }}>
        <Text style={pdfStyles.tableCell}>Суммарный балл</Text>
      </View>
    </View>
  </>
)

const reportTableItem = ({ item }) => (
  <>
    <View wrap={false} style={pdfStyles.tableRow}>
      <View style={{ ...pdfStyles.tableCol, ...pdfStyles.tableCol10 }}>
        <Text style={pdfStyles.tableCell}>{item.position}</Text>
      </View>
      <View style={{ ...pdfStyles.tableCol, ...pdfStyles.tableCol70 }}>
        <Text style={{ ...pdfStyles.tableCell, textAlign: 'left', paddingLeft: 10 }}>{item.name}</Text>
      </View>
      <View style={{ ...pdfStyles.tableCol, ...pdfStyles.tableCol20 }}>
        <Text style={pdfStyles.tableCell}>{item.summ}</Text>
      </View>
    </View>
  </>
)

/* DOCX REPORTS */
const DOCXReportTableHeader = () => {
  return new docx.TableRow({
    tableHeader: true,
    children: [
      new docx.TableCell({
        children: [
          new docx.Paragraph({
            text: 'Рейтинг',
            alignment: docx.AlignmentType.CENTER,
          }),
        ],
      }),
      new docx.TableCell({
        children: [
          new docx.Paragraph({
            text: 'Школа',
            alignment: docx.AlignmentType.CENTER,
          }),
        ],
      }),
      new docx.TableCell({
        children: [
          new docx.Paragraph({
            text: 'Суммарный балл',
            alignment: docx.AlignmentType.CENTER,
          }),
        ],
      }),
    ],
  })
}

const DOCXReportTableItem = item => {
  return new docx.TableRow({
    children: [
      new docx.TableCell({
        children: [
          new docx.Paragraph({
            text: item.position.toString(),
            alignment: docx.AlignmentType.CENTER,
          }),
        ],
      }),
      new docx.TableCell({
        children: [new docx.Paragraph(item.name)],
      }),
      new docx.TableCell({
        children: [
          new docx.Paragraph({
            text: item.summ,
            alignment: docx.AlignmentType.CENTER,
          }),
        ],
      }),
    ],
  })
}

/* CSV REPORTS */
const CsvHeaders = [
  { label: 'Рейтинг', key: 'position' },
  { label: 'Образовательная организация', key: 'name' },
  { label: 'Суммарный балл', key: 'summ' },
]

const GeneralStatisticsAllForm = ({ handleSubmit, submitting, pdfReportData }) => {
  const PDF_REPORT_NAME = 'Показатели оценки довузовских общеобразовательных организаций (общий)'

  const generateDoc = () => {
    const doc = new docx.Document({
      creator: '',
      description: PDF_REPORT_NAME,
      title: PDF_REPORT_NAME,
    })

    const tableData = pdfReportData.map(item => DOCXReportTableItem(item))

    const title = new docx.Paragraph({
      text: PDF_REPORT_NAME,
      heading: docx.HeadingLevel.HEADING_2,
    })

    const reportTable = new docx.Table({
      width: {
        size: 100,
        type: docx.WidthType.AUTO,
      },
      columnWidths: [1000, 5500, 2000],
      rows: [DOCXReportTableHeader(), ...tableData],
    })

    doc.addSection({
      children: [title],
    })

    doc.addSection({
      children: [reportTable],
    })

    docx.Packer.toBlob(doc).then(blob => {
      saveAs(blob, 'отчет.docx')
    })
  }

  return (
    <form onSubmit={handleSubmit} className={styles.container}>
      <Row className={'justify-content-between'}>
        <Col md='auto' className={'d-flex'}>
          <Field name='date' component={Date} type='date' />
          <Button type='submit' label={'Составить'} submitting={submitting} />
        </Col>

        {!!pdfReportData.length && (
          <>
            <Col md={'auto'}>
              <div className={styles.loadOuter}>
                <PDFDownloadLink
                  document={
                    <PdfReports
                      name={PDF_REPORT_NAME}
                      data={pdfReportData}
                      TableHeader={reportTableHeader}
                      TableItem={reportTableItem}
                    />
                  }
                  fileName='report.pdf'
                >
                  {({ blob, url, loading, error }) =>
                    loading ? (
                      'Формирование отчета...'
                    ) : (
                      <span className={'load-button'} title={'Выгрузить в pdf'}>
                        <img src={pdfIcon} alt='' />
                      </span>
                    )
                  }
                </PDFDownloadLink>
              </div>

              <div className={styles.loadOuter}>
                <span className={'load-button'} title={'Выгрузить в docx'} onClick={generateDoc}>
                  <img src={docIcon} alt='' />
                </span>
              </div>

              <div className={styles.loadOuter}>
                <CSVLink headers={CsvHeaders} data={pdfReportData} filename={'отчет.csv'}>
                  <span className={'load-button'} title={'Выгрузить в excel'}>
                    <img src={csvIcon} alt='' />
                  </span>
                </CSVLink>
              </div>
            </Col>
          </>
        )}
      </Row>
    </form>
  )
}

export default compose(
  connect(state => ({ initialValues: state.initForm.data })),
  reduxForm({ form: 'generalStatAll' })
)(GeneralStatisticsAllForm)

import React, { useState, useEffect, useMemo } from 'react'
import { Table } from 'antd'
import ExcelExporter from '../../Diseased/ExcelExporter'
import { modifyCandidatesData } from '../../../../utils/modifyData'
import styles from './styles.module.css'

const CandidatesConsTable = ({ data, paramsData }) => {
  
  const [_data, setData] = useState(null)

  useEffect(() => {
    data.length &&
      setData(modifyCandidatesData(data))
  }, [data])
  
  const columns = useMemo(() => _data && [
    {
      title: 'Субъект РФ',
      dataIndex: 'subject',
      width: '320px'
    },
    ..._data[0].values.map((org, ind) =>({
      title: org.organization,
      render: (data) => data.values[ind].count
    })),
    {
      title: 'Всего',
      render: (data) => data.values.reduce((sum, val) => sum + parseInt(val.count||0), 0)
    }
  ], [_data])

  return (
    <div>
      {_data && (
        <>
          <ExcelExporter title="Кандидаты на поступление по субъектам РФ.xlsx">
            <div className={styles.title}>
              <h3 className={'text-center mb-2'}>
                Сводный отчет по субъектам РФ
                <br/>
                Кандидаты на поступление в довузовские образовательные организации Министерства обороны Российской Федерации в {paramsData.year} году
                <br/>
                {!!paramsData.status.value && <span>{paramsData.status.name}</span> } по состоянию на {paramsData.date} г.
              </h3>
            </div>

            <div className={styles.tableWrap}>
              <Table
                dataSource={_data}
                columns={columns}
                pagination={false}
                bordered
              />
            </div>
          </ExcelExporter>
          
        </>
      )}
    </div>
  )
}

export default CandidatesConsTable

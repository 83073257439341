import React from 'react'
import PropTypes from 'prop-types'
import { Button, Col, FormGroup, FormInput, FormSelect, FormCheckbox, Row } from 'shards-react'

const StudentsTotalForm = ({ setFormData, formData, onSubmit, orgList }) => {
  
  const handleInputChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <Row className={'my-3 justify-content-between'}>
      <Col md={'auto'} className={'d-flex align-items-center'}>
        <FormGroup className={'mr-3'}>
          <label htmlFor='_to'>C (дата)</label>
          <FormInput
            size='sm'
            type={'date'}
            name={'datefrom'}
            className='mb-2'
            value={formData.datefrom}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup className={'mr-3'}>
          <label htmlFor='_to'>По (дата)</label>
          <FormInput
            size='sm'
            type={'date'}
            name={'dateto'}
            className='mb-2'
            value={formData.dateto}
            onChange={handleInputChange}
          />
        </FormGroup>

        <FormGroup className={'mr-3'}>
          <label htmlFor='_idOrg'>Выберите организацию</label>
          <FormSelect
            name={'idOrg'}
            id='_idOrg'
            size='sm'
            className='mb-2 d-block'
            onChange={handleInputChange}
            style={{ width: 730 }}
          >
            <option value={0}>Все организации</option>
            {orgList &&
              orgList.map(item => (
                <option key={item.idOrganization} value={item.idOrganization}>
                  {item.orgName}
                </option>
              ))}
          </FormSelect>
        </FormGroup>

        <FormCheckbox
            onChange={() =>
              setFormData({...formData,
                foreign: Number(!formData.foreign),
              })
            }
            name={'foreign'}
            checked={formData.foreign}
            className={'mr-3'}
          >
            Иностранные граждане
          </FormCheckbox>

        <Button onClick={onSubmit}>Создать отчет</Button>
      </Col>
    </Row>
  )
}

StudentsTotalForm.propTypes = {
  orgList: PropTypes.array,
}

export default StudentsTotalForm

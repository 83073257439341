import {SUCCESS} from "../../../../constants/action-postfix";
import {VACCINATED_REQUEST} from "../vaccinatedReducer";


const initialState = [];

export const vaccinatedListReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${VACCINATED_REQUEST}${SUCCESS}`:
            return action.payload;

        default:
            return state;
    }
};
import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import Chart from 'chart.js';
import styles from './GeneralStatisticsAllChart.module.css';

const GeneralStatisticsAllChart = ({ data }) => {
  let chart, canvas;

  useEffect(() => {

    const institutionsNames = data.map(item => item.name);
    const summaryPoints = data.map(item => Number(item.summ));

    chart = new Chart(canvas.getContext('2d'), {
      type: "bar",
      data: {
        labels: institutionsNames,
        datasets: [{
          label: 'Суммарный балл по образовательным организациям',
          backgroundColor: 'rgba(51,71,172,0.91)',
          hoverBackgroundColor: 'rgb(77,116,255)',
          data: summaryPoints
        }]
      }
    });
  });

  return (
    <div className={styles.container}>
      <canvas
        ref={el => canvas = el}
      />
    </div>
  );
};

GeneralStatisticsAllChart.propTypes = {
  data: PropTypes.array,
};

export default GeneralStatisticsAllChart;
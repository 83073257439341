import React, {useState} from "react";
import PropTypes from "prop-types";
import classNames from "classnames";
import {Link, NavLink} from "react-router-dom";

import styles from "./MenuItem.module.css";

const MenuItem = ({link, title, dropdown}) => {
    const [open, setOpen] = useState(false);
    // const [openClick, setOpenClick] = useState(false);

    // const toggleDropdownList = () => setOpenClick(!openClick);
    // const disbleDropdownList = () => setOpen(false);


    return (
        <div
            className={styles.container}
            onPointerEnter={() => setOpen(true)}
            // onPointerLeave={() => !openClick ? setOpen(false) : undefined}
            onPointerLeave={() => setOpen(false)}
        >
            <NavLink
                to={link}
                exact
                activeClassName={styles.open}
                className={classNames(styles.menuItem, {[styles.open]: open})}
                onClick={() => setOpen(false)}
                // onClick={toggleDropdownList}
                // onBlur={disbleDropdownList}
            >
                {title}
            </NavLink>
            {dropdown && open && (
                <div
                    // onClick={disbleDropdownList}
                     className={classNames(styles.dropdown, {
                         [styles.dropdownOpen]: open
                     })}
                >
                    {dropdown.map((item, index) =>
                        <Link to={item.link} key={index} target={item.blank ? '_blank' : ''}>
                            <div className={styles.dropdownItem}>{item.title}</div>
                        </Link>
                    )}
                </div>
            )}
        </div>
    );
};

MenuItem.propTypes = {
    label: PropTypes.string,
    link: PropTypes.string,
    dropdown: PropTypes.array
};

export default MenuItem;

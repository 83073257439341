export const cols = "idOrganization orgName vaccinate_staff excluded_staff revaccinate_staff doses_staff perday1_staff perday2_staff share_staff_day total1_staff total2_staff share_staff_total perday1r_staff perday2r_staff r_share_staff_day total1r_staff total2r_staff r_share_staff_total vaccinate_student excluded_student revaccinate_student doses_student perday1_student perday2_student share_student_day total1_student total2_student share_staff_total1 perday1r_student perday2r_student r_share_student_day total1r_student total2r_student r_share_student_total"
export const colNames = [
    "№ п/п",
    "Наименование образовательной организации",
    "Подлежит вакцинации (пост. состав)",
    "Отказано по медицинским показаниям",
    "Подлежит ревакцинации (пост. состав)",
    "Выделено вакцин (шт.)",
    "Вакцинировано за сутки 1 комп. (пост. состав)",
    "Вакцинировано за сутки 2 комп. (пост. состав)",
    "Процент полностью вакцинированных за сутки",
    "Вакцинировано всего 1 комп. (пост. состав)",
    "Вакцинировано всего 2 комп. (пост. состав)",
    "Процент полностью вакцинированных",
    "Ревакцинировано за сутки 1 комп. (пост. состав)",
    "Ревакцинировано за сутки 2 комп. (пост. состав)",
    "Процент ревакцинорованных за сутки",
    "Ревакцинировано всего 1 комп. (пост. состав)",
    "Ревакцинировано всего 2 комп. (пост. состав)",
    "Процент ревакцинированных",
    "Подлежит вакцинации (перем. состав)",
    "Отказано по медицинским показаниям",
    "Подлежит ревакцинации (перем. состав)",
    "Выделено вакцин (шт.)",
    "Вакцинировано за сутки 1 комп. (перем. состав)",
    "Вакцинировано за сутки 2 комп. (перем. состав)",
    "Процент полностью вакцинированных за сутки",
    "Вакцинировано всего 1 комп. (перем. состав)",
    "Вакцинировано всего 2 комп. (перем. состав)",
    "Процент полностью вакцинированных",
    "Ревакцинировано за сутки 1 комп. (перем. состав)",
    "Ревакцинировано за сутки 2 комп. (перем. состав)",
    "Процент ревакцинорованных за сутки",
    "Ревакцинировано всего 1 комп. (перем. состав)",
    "Ревакцинировано всего 2 комп. (перем. состав)",
    "Процент ревакцинированных",

]
import React, { useMemo, useRef } from "react";
import { Table } from "antd";
import styles from "./DuplicatesTable.module.css";
import { Button } from "shards-react";
import XLSX from "xlsx";
import { saveAs } from "file-saver";
import { cols } from "../../../../constants/vaccinated-table";
import moment from "moment";

export default function DuplicatesTable({ data }) {

  const table = useRef()

  
  const parse = () => {
    const wb = XLSX.utils.book_new();
    wb.SheetNames.push("test");
    const ws = XLSX.utils.table_to_sheet(document.getElementById("table"));
    wb.Sheets["test"] = ws;
    const wbout = XLSX.write(wb, {
      bookType: "xlsx",
      bookSST: true,
      type: "binary",
    });
    const s2ab = (s) => {
      let buf = new ArrayBuffer(s.length);
      let view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    };
    saveAs(
      new Blob([s2ab(wbout)], { type: "application/octet-stream" }),
      "Отчет по двойникам из числа кандидатов на поступление.xlsx"
    );
  }

  const columns = useMemo(
    () => [
      {
        title: "№ п/п",
        render: (value, item, index) => index + 1
      },
      {
        title: <div className={styles.rotate}>Наименование субъекта Российской Федерации</div>,
        dataIndex: "Subject"
      },
      {
        title: "Фамилия, имя, отчество",
        render: (value, item, index) => `${item.lastname} ${item.secondname}`
      },
      {
        title: <div className={styles.rotate}>Дата рождения</div>,
        dataIndex: "child_birth",
        render: (value, item, index) => value.split(" ")[0]
      },
      {
        title: <div className={styles.rotate}>В какой класс поступает кандидат</div>,
        dataIndex: "child_class"
      },
      {
        title: "Сведения о родителях",
        dataIndex: "Parents",
        render: (value, item, index) => {
          const newval = value.split("\n")
          const jsx = <div>{newval.map(it => <div>{it}</div>)}</div>
          return jsx
        }
      },
      {
        title: "Контакты родителей",
        dataIndex: "Address",
        render: (value, item, index) => {
          const newval = value.split("\n")
          const jsx = <div>{newval.map(it => <div>{it}</div>)}</div>
          return jsx
        }
      },
      {
        title: "Наличие оснований для льготного зачисления",
        dataIndex: "privilege",
        render: (value, item, index) => {
          const newval = value.split("\n")
          const jsx = <div>{newval.map(it => <div>{it}</div>)}</div>
          return jsx
        }
      },
      {
        title: "Результаты конкусртных вступительных испытаний",
        children: [
          {
            title: "Общеобразовательная подготовка",
            children: [
              {
                title: <div className={styles.rotate}>Русский язык</div>,
                dataIndex: "Child_Mark_Russ"
              },
              {
                title: <div className={styles.rotate}>Математика</div>,
                dataIndex: "Child_Mark_Math"
              },
              {
                title: <div className={styles.rotate}>Иностранный язык</div>,
                dataIndex: "Child_Mark_Lang"
              }
            ]
          },
          {
            title: "Физическая подготовленность",
            children: [
              {
                title: <div className={styles.rotate}>Общая оценка</div>,
                dataIndex: "Child_Mark_Sport"
              }
            ]
          },
          {
            title: "Психологическая подготовленность к обучению",
            children: [
              {
                title: <div className={styles.rotate}>Иоговое заключение</div>,
                dataIndex: "conclusion"
              }
            ]
          },
          {
            title: <div className={styles.rotate}>Единая балльная оценка</div>,
            dataIndex: "ball"
          }
        ]
      },
      {
        title: "Училище для поступления",
        dataIndex: "orgName"
      },
      {
        title: "Статус",
        dataIndex: "status"
      },
    ],
    []
  );

  return (
    <div>
      <Button theme="success" className={"mb-2"} onClick={() => parse()}>
        Скачать xlsx
      </Button>
      <Table
        className={styles.table}
        bordered
        columns={columns}
        pagination={false}
        dataSource={data}
      />
      <table id="table" ref={table} style={{display: "none"}}>
        <tr>
          <th>№ п/п</th>
          <th>Наименование субъекта Российской Федерации</th>
          <th>Фамилия, имя, отчество</th>
          <th>Дата рождения</th>
          <th>В какой класс поступает кандидат</th>
          <th>Сведения о родителях</th>
          <th>Адрес проживания родителей</th>
          <th>Наличие оснований для льготного зачисления</th>
          <th>Русский язык</th>
          <th>Математика</th>
          <th>Иностранный язык</th>
          <th>Физическая подготовленность</th>
          <th>Психологическая готовность</th>
          <th>Единая балльная оценка</th>
          <th>Училище для поступления</th>
          <th>Статус</th>
        </tr>
        {data.map((it,i) => <tr>
              <td>{i+1}</td>
              <td>{it.Subject}</td>
              <td>{`${it.lastname} ${it.secondname}`}</td>
              <td>{it.child_birth}</td>
              <td>{it.child_class}</td>
              <td>{it.Parents}</td>
              <td>{it.Address}</td>
              <td>{it.privilege}</td>
              <td>{it.Child_Mark_Russ}</td>
              <td>{it.Child_Mark_Math}</td>
              <td>{it.Child_Mark_Lang}</td>
              <td>{it.Child_Mark_Sport}</td>
              <td>{it.conclusion}</td>
              <td>{it.ball}</td>
              <td>{it.orgName}</td>
              <td>{it.status}</td>
        </tr>)}
      </table>
    </div>
  );
}

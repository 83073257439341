import {combineReducers} from "redux";
import {diseasedWeekReducer} from "./Week/diseasedWeekReducer";
import {diseasedPeriodReducer} from "./Period/diseasedPeriodReducer";
import {diseasedMonthReducer} from "./Month/diseasedMonthReducer";
import {diseaseCovidReducer} from "./Covid/diseasedCovidReducer";

export const diseasedReducer = combineReducers({
    month: diseasedMonthReducer,
    period: diseasedPeriodReducer,
    week: diseasedWeekReducer,
    covid: diseaseCovidReducer
});

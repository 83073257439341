import {ERROR, START, SUCCESS} from "../../../../constants/action-postfix";
import {STUDENTS_MOVE_REQUEST, STUDENTS_TOTAL_REQUEST} from "../studentsTotalReducer";

const initialState = {
    data: [],
    isLoading: false,
    error: null
}

export const moveReducer = (state = initialState, action) => {
    switch (action.type) {

        case `${STUDENTS_MOVE_REQUEST}${START}`:
            return {
                data: [],
                isLoading: true,
                error: null
            };
        case `${STUDENTS_MOVE_REQUEST}${SUCCESS}`:
            return {
                data: action.payload,
                isLoading: false,
                error: null
            };

        case `${STUDENTS_MOVE_REQUEST}${ERROR}`:
            return {
                data: null,
                isLoading: false,
                error: action.payload
            };

        case `${STUDENTS_TOTAL_REQUEST}${START}`:
            return {
                data: [],
                isLoading: false,
                error: null
            };

        default:
            return state;
    }
};
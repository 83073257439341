import {combineReducers} from "redux";
import { call, put, takeLatest } from 'redux-saga/effects';
import {reportsAPI} from "../../../api/reports";
import {START} from "../../../constants/action-postfix";
import { duplicatesListReducer } from "./list/duplicatesListReducer";
import { duplicatesLoaderReducer } from "./loader/duplicatesLoaderReducer";
import { duplicatesFormReducer } from "./form/duplicatesFormReducer";
import { createActions } from "../../factory/actionFactory";



export const DUPLICATES_REQUEST = "DUPLICATES_REQUEST";
export const SET_FORM_DATA = "SET_DUPLICATES_DATA";

export const duplicatesReducer = combineReducers({
    list: duplicatesListReducer,
    loader: duplicatesLoaderReducer,
    form: duplicatesFormReducer
});

//actions
export const duplicatesActions = createActions('duplicates', DUPLICATES_REQUEST);
export const setDuplicatesFormData = data => ({
    type: SET_FORM_DATA,
    payload: data
});

// SAGAS
export function* duplicatesWatcher() {
    yield takeLatest(`${DUPLICATES_REQUEST}${START}`, duplicatesWorker)
}

function* duplicatesWorker({ payload }) {
    try {
        const response = yield call(reportsAPI.duplicates, payload);
        yield put(duplicatesActions.duplicatesRequestSuccess(response.data.Answer.Data));
    } catch(error) {
        yield put(duplicatesActions.duplicatesRequestError(error));
    }
}
import React from 'react';
import PropTypes from 'prop-types';

import {Button, Col, FormGroup, FormInput, FormSelect, Row} from "shards-react";
import {STATUS_LIST} from "../index";


const year = new Date().getFullYear();
const yearsList = [Number(year) - 1, Number(year), Number(year) + 1];

const CandidatesForm = ({ orgList, onSubmit, formData, setFormData }) => {
    const { year: currentYear } = formData;



    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    };



    return (
        <Row className={'my-3'}>
            <Col md={3}>
                <FormGroup>
                    <label htmlFor="_year">Выберите год</label>
                    <FormSelect
                        name="year"
                        id='_year'
                        size="sm"
                        className="mb-2 d-block"
                        onChange={handleInputChange}
                    >
                        {yearsList.map(item =>
                            <option key={item} value={item} selected={item === currentYear}>{item}</option>
                        )}
                    </FormSelect>
                </FormGroup>
            </Col>
            <Col md={3}>
                <FormGroup>
                    <label htmlFor="_to">Дата</label>
                    <FormInput size="sm" type={'date'}
                               name={'date'}
                               className="mb-2"
                               value={formData.date}
                               onChange={e => setFormData({
                                   ...formData,
                                   date: e.target.value,
                               })}
                    />

                </FormGroup>
            </Col>
            <Col md={3}>
                <FormGroup>
                    <label htmlFor="_status">Статус кандидатов</label>
                    <FormSelect
                        name={'status'}
                        id='_status'
                        size="sm"
                        className="mb-2"
                        onChange={handleInputChange}
                    >
                        {STATUS_LIST.map(item =>
                            <option key={item.value} value={item.value}>{item.name}</option>
                        )}
                    </FormSelect>
                </FormGroup>
            </Col>
            <Col md={9}>
                <FormGroup>
                    <label htmlFor="_idOrg">Выберите организацию</label>
                    <FormSelect
                        name={'idOrg'}
                        id='_idOrg'
                        size="sm"
                        className="mb-2"
                        onChange={handleInputChange}
                    >
                        <option value={0}>Все организации</option>
                        {orgList.filter(item => item.isSchool === "0").map(item =>
                            <option key={item.idOrganization} value={item.idOrganization}>{item.orgName}</option>
                        )}
                    </FormSelect>
                </FormGroup>
            </Col>
            <Col md={'auto'} className={'d-flex align-items-end justify-content-end'}>
                <FormGroup>
                    <Button
                        onClick={onSubmit}
                    >Создать отчет</Button>
                </FormGroup>
            </Col>
        </Row>
    );
};

CandidatesForm.propTypes = {
    orgList: PropTypes.array,
    onSubmit: PropTypes.func,
};

export default CandidatesForm;
import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { organizationList } from './../../../redux/reducers/organization-list-reducer'
import Spinner from '../../common/Spinner'
import OrganizationListTable from './OrganizationListTable/OrganizationListTable'

const OrganizationList = ({ auth, orgList, organizationListStart, isLoading }) => {
  
  useEffect(() => {
    !orgList.length && organizationListStart(auth)
  }, [])

  return (
    <div className='main'>
      {isLoading && <Spinner />}
      {!isLoading && <OrganizationListTable data={orgList} />}
    </div>
  )
}

const mapState = ({ reports, auth }) => ({
  orgList: reports.orgList.data,
  isLoading: reports.orgList.isLoading,
  auth: auth.data,
})

export default connect(mapState, {
  organizationListStart: organizationList.organizationListRequestStart,
})(OrganizationList)

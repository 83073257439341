import React, {useEffect, useState} from 'react';
import PropTypes from 'prop-types';
import {Button, Col, FormCheckbox, FormGroup, FormSelect, Row} from "shards-react";
import moment from "moment";



const combineWeekDates = (year) => {
    const weeksCount = weeksInYear(year);
    let result = [];


    for(let i = 0; i < weeksCount; i++) {
        const template = {
            'start': moment(year).add(i, 'weeks').startOf('week').add(1, 'days').format('DD.MM.YYYY'),
            'end': moment(year).add(i, 'weeks').endOf('week').add(1, 'days').format('DD.MM.YYYY'),
            'name': `Неделя ${i + 1}`,
            'value': i + 1
        };

        result.push(template)
    }

    return result
};

const weeksInYear = (year) => {
    return Math.max(
        moment(new Date(year, 11, 31)).isoWeek(),
        moment(new Date(year, 11, 31-7)).isoWeek()
    );
};



const DiseasedWeekForm = ({ orgList, onSubmit, formData, setFormData }) => {
    const currentYear = new Date().getFullYear();
    const yearsList = [currentYear - 1, currentYear, currentYear + 1];

    const [yearWeeks, setYearWeeks] = useState([]);

    useEffect(() => {
        setYearWeeks(combineWeekDates(currentYear.toString()))
    }, [currentYear]);

    const selectYearHandler = (year) => {
        setYearWeeks(combineWeekDates(year));
        setFormData({
            ...formData,
            year
        })
    };

    const handleInputChange = (e) => {
        setFormData({
            ...formData,
            [e.target.name]: e.target.value
        })
    };


    return (
        <Row className={'my-3'}>
            <Col md={'auto'} className={'d-flex align-items-center'}>
                <FormGroup
                    className={'mr-3'}
                >
                    <label htmlFor="_year">Выберите год</label>
                    <FormSelect id='_year' size="sm" className="mb-2" name={'year'}
                                onChange={e => selectYearHandler(e.target.value)}>
                        {yearsList.map(item =>
                            <option value={item} selected={item === currentYear}>{item}</option>
                        )}
                    </FormSelect>
                </FormGroup>
                <FormGroup
                    className={'mr-3'}
                    style={{maxWidth: '300px'}}
                >
                    <label htmlFor="_week">Выберите неделю</label>
                    <FormSelect
                        name={'week'}
                        id='_week'
                        size="sm"
                        className="mb-2"
                        value={formData.week}
                        onChange={handleInputChange}
                    >
                        {yearWeeks.map(item =>
                            <option value={item.value}>{`${item.name} (${item.start} - ${item.end})`}</option>
                        )}
                    </FormSelect>
                </FormGroup>
                <FormGroup
                    className={'mr-3'}
                    style={{maxWidth: '300px'}}
                >
                    <label htmlFor="_idOrg">Выберите организацию</label>
                    <FormSelect
                        name={'idOrg'}
                        id='_idOrg'
                        size="sm"
                        className="mb-2"
                        onChange={handleInputChange}
                    >
                        <option value={0}>Все организации</option>
                        {orgList.map(item =>
                            <option key={item.idOrganization} value={item.idOrganization}>{item.orgName}</option>
                        )}
                    </FormSelect>
                </FormGroup>
                {formData.idOrg === "0" &&
                <FormCheckbox
                    onChange={e => setFormData({
                        ...formData,
                        'school': !formData.school
                    })}
                    name={'school'}
                    checked={formData.school}
                    className={'mr-3'}
                >
                    Школы
                </FormCheckbox>
                }
                <Button
                    onClick={() => onSubmit({...formData, weekData: yearWeeks[formData.week - 1]})}
                >Создать отчет</Button>
            </Col>
        </Row>
    );
};

DiseasedWeekForm.propTypes = {
    orgList: PropTypes.array,
    onSubmit: PropTypes.func,
    setFormData: PropTypes.func,
    formData: PropTypes.object,
};

export default DiseasedWeekForm;
import {SET_MONTH_PERIOD_DATA} from "../diseasedMonthReducer";


const initialState = null;

export const diseasedMonthPeriodReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_MONTH_PERIOD_DATA:
            return action.payload;
        default:
            return state;
    }
};
import jsPDF from "jspdf"
import addFont from "../../../../fonts/fonts"

const orientation = 'l'

export const getPdfData = async () => {
    addFont(jsPDF.API)
    const doc = new jsPDF({ putOnlyUsedFonts: true, orientation })

    const {width, height} = doc.internal.pageSize
    let lastY = 10

    const getY = (indent, newValue) => {
      let newY = newValue ? newValue + indent : lastY + indent
      if (newY > height - 15) {
        doc.addPage('a4', orientation)
        newY = 10
      }
      lastY = newY
      return newY
    }

    doc.setFont('Montserrat')
    doc.setFontSize('10')

    doc.text('Объемные показатели', width/2, lastY, 'center')
    doc.text('по определению лучшего училища (кадетского корпуса)', width/2, getY(7), 'center')
    doc.text('для награждения Вымпелом Министерства обороны Российской Федерации', width/2, getY(7), 'center')

    doc.autoTable({
      html: '#bestSchoolTable',
      styles: { font: 'Montserrat', fontSize: 4 },
      headStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.25,
        lineColor: 200,
      },
      bodyStyles: {
        halign: 'center',
        lineWidth: 0.25,
        lineColor: 200,
      },
      columnStyles: {
        1: { halign: 'left', width: 100 },
      },

      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : getY(7),
    })

    doc.addPage('a4', orientation)
    doc.deletePage(doc.getCurrentPageInfo().pageNumber)

    return doc
}
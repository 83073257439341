import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import RouteLayout from '../../../RouteLayout'

import { organizationList } from '../../../../redux/reducers/organization-list-reducer'
import Spinner from '../../../common/Spinner'

import CovidReportForm from './Form'
import CovidReportTable from './Table'
import { diseasedCovidActions, setCovidFormData } from '../../../../redux/reducers/Diseased/Covid/diseasedCovidReducer'

const DiseasedCovid = ({ auth, organizationListStart, orgList, requestReport, loader, list, setFormData, formData }) => {
  useEffect(() => {
    if (orgList !== undefined && orgList.length === 0) {
      organizationListStart(auth)
    }
  }, [organizationListStart, auth])

  const requestReportHandler = () => {
    const params = {
      school: formData.school ? 1 : 0,
      idOrg: formData.idOrg,
      date: formData.date,
    }

    requestReport({ ...auth, ...params })
  }

  return (
    <RouteLayout title={'Статистика по заболевшим COVID-19'}>
      <CovidReportForm onSubmit={requestReportHandler} orgList={orgList} setFormData={setFormData} formData={formData} />

      {loader ? <Spinner /> : <CovidReportTable data={list} orgList={orgList} />}
    </RouteLayout>
  )
}

const mapStateToProps = state => ({
  auth: state.auth.data,
  orgList: state.reports.orgList.data,
  list: state.reports.diseased.covid.list,
  loader: state.reports.diseased.covid.loader,
  formData: state.reports.diseased.covid.form,
})

const mapDispatchToProps = {
  organizationListStart: organizationList.organizationListRequestStart,
  requestReport: diseasedCovidActions.diseasedCovidRequestStart,
  setFormData: setCovidFormData,
}

export default connect(mapStateToProps, mapDispatchToProps)(DiseasedCovid)

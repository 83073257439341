import React from 'react'

import RouteLayout from '../../RouteLayout'
import { Buttons, Form, Table } from './Components'
import Spinner from '../../common/Spinner'
import { useSelector } from 'react-redux'


export default function Graduates () {
  
  const { loader, form, data, year } = useSelector(state => state.reports.graduates)
  
  return (
    <RouteLayout title={'Отчет о поступлении выпускников'}>
      <Form form={form}/>
      <Buttons visible={!!data} year={year}/>
      {loader
        ? <Spinner />
        : <Table data={data} year={year}/>
      }
    </RouteLayout>
  )
}

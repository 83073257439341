import React, { useEffect } from 'react'
import RouteLayout from '../../RouteLayout';
import PhonebookForm from './Form';
import {connect} from 'react-redux'
import { organizationList } from '../../../redux/reducers/organization-list-reducer'
import { setPeriodFormData, phonebookActions } from '../../../redux/reducers/Phonebook/phonebookReducer'
import Spinner from '../../common/Spinner';
import PhonebookTable from './PhonebookTable/PhonebookTable';




function Phonebook({organizationListStart, orgList, auth, formData, setFormData, requestReport, loader, phoneData}) {
    useEffect(() => {
        if (orgList !== undefined && orgList.length === 0) {
          organizationListStart(auth)
        }
      }, [organizationListStart, auth])
    

      const requestReportHandler = () => {
          const params = {
          school: formData.school ? 1 : 0,
          idOrg: formData.idOrg,
        }
        requestReport({ ...auth, ...params })
      }


    return (
        <RouteLayout
            title={'Телефонный справочник руководящего состава'}
        >
            <PhonebookForm onSubmit={requestReportHandler}
                                orgList={orgList}
                                setFormData={setFormData}
                                formData={formData}/>
            {loader ?
                <Spinner />
                :
                <PhonebookTable data={phoneData}/>
            }
        </RouteLayout>
    )
}


const mapStateToProps = (state) => ({
    auth: state.auth.data,
    orgList: state.reports.orgList.data,
    formData: state.phoneBook.form,
    phoneData: state.phoneBook.list,
    loader: state.phoneBook.loader
});

const mapDispatchToProps = {
    organizationListStart: organizationList.organizationListRequestStart,
    requestReport: phonebookActions.phonebookRequestStart,
    setFormData: setPeriodFormData
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(Phonebook);
import React, {useMemo} from 'react';
import PropTypes from 'prop-types';
import {Table, Typography} from 'antd';
import styles from "../../Week/Table/DiseasedWeekTable.module.css";
import ExcelExporter from "../../ExcelExporter";
import Cell from '../../../../common/CellChanger/Cell';



const CovidReportTable = ({data, orgList}) => {

    const {Text} = Typography;

    const updatedData = useMemo(() => {

        if (data.summary) {
            if(data.summary.length > 1) {
                let a = [...data.summary];
                let b = a.pop();
                return [a, b];
            } else {
                return [data.summary, null]
            }
        }

        return [[], {}]

    }, [data]);

    updatedData[0].map(item => item.kurs_5+=':'+item.dynamic_5)
    updatedData[0].map(item => item.kurs_6+=':'+item.dynamic_6)
    updatedData[0].map(item => item.kurs_7+=':'+item.dynamic_7)
    updatedData[0].map(item => item.kurs_8+=':'+item.dynamic_8)
    updatedData[0].map(item => item.kurs_9+=':'+item.dynamic_9)
    updatedData[0].map(item => item.kurs_10+=':'+item.dynamic_10)
    updatedData[0].map(item => item.kurs_11+=':'+item.dynamic_11)
    updatedData[0].map(item => item.other_count+=':'+item.dynamic_other)
    updatedData[0].map(item => item.teacher_count+=':'+item.dynamic_teacher)
    updatedData[0].map(item => item.tutor_count+=':'+item.dynamic_tutor)
        

    const columnsInitial = [
        {
            title: "Администрация",
            dataIndex: "admin_count"
        },
        {
            title: 'Преподаватели',
            dataIndex: 'teacher_count',
            render: text => {
                const sick = text.split(':')[0]
                const dynamic = text.split(':')[1]
                let color;
                const val = Number(dynamic);
                if(val < 0) color = "#CCFFCC"
                else if(val > 0) color = "#FFCCCC"

                return ({
                    props: {
                        style: { background: color }
                    },
                    children: sick
                })
            }
        },
        {
            title: 'Воспитатели',
            dataIndex: 'tutor_count',
            render: text => {
                const sick = text.split(':')[0]
                const dynamic = text.split(':')[1]
                let color;
                const val = Number(dynamic);
                if(val < 0) color = "#CCFFCC"
                else if(val > 0) color = "#FFCCCC"

                return ({
                    props: {
                        style: { background: color }
                    },
                    children: sick
                })
            }
        },
        {
            title: 'Другие',
            dataIndex: 'other_count',
            render: text => {
                const sick = text.split(':')[0]
                const dynamic = text.split(':')[1]
                let color;
                const val = Number(dynamic);
                if(val < 0) color = "#CCFFCC"
                else if(val > 0) color = "#FFCCCC"

                return ({
                    props: {
                        style: { background: color }
                    },
                    children: sick
                })
            }
        },
        {
            title: () => <strong>Всего постоянного состава</strong>,
            render: row => ({
                props: {
                    style: { background: "#fafafa" }
                },
                children: <strong>{row.staff_count}</strong>
            }),
        },
        {
            title: "Обучающиеся",
            children: [
                {
                    title: '5 класс',
                    dataIndex: 'kurs_5',
                    render: text => {
                        const sick = text.split(':')[0]
                        const dynamic = text.split(':')[1]
                        let color;
                        const val = Number(dynamic);
                        if(val < 0) color = "#CCFFCC"
                        else if(val > 0) color = "#FFCCCC"
        
                        return ({
                            props: {
                                style: { background: color }
                            },
                            children: sick
                        })
                    }
                },
                {
                    title: '6 класс',
                    dataIndex: 'kurs_6',
                    render: text => {
                        const sick = text.split(':')[0]
                        const dynamic = text.split(':')[1]
                        let color;
                        const val = Number(dynamic);
                        if(val < 0) color = "#CCFFCC"
                        else if(val > 0) color = "#FFCCCC"
        
                        return ({
                            props: {
                                style: { background: color }
                            },
                            children: sick
                        })
                    }
                },
                {
                    title: '7 класс',
                    dataIndex: 'kurs_7',
                    render: text => {
                        const sick = text.split(':')[0]
                        const dynamic = text.split(':')[1]
                        let color;
                        const val = Number(dynamic);
                        if(val < 0) color = "#CCFFCC"
                        else if(val > 0) color = "#FFCCCC"
        
                        return ({
                            props: {
                                style: { background: color }
                            },
                            children: sick
                        })
                    }
                },
                {
                    title: '8 класс',
                    dataIndex: 'kurs_8',
                    render: text => {
                        const sick = text.split(':')[0]
                        const dynamic = text.split(':')[1]
                        let color;
                        const val = Number(dynamic);
                        if(val < 0) color = "#CCFFCC"
                        else if(val > 0) color = "#FFCCCC"
        
                        return ({
                            props: {
                                style: { background: color }
                            },
                            children: sick
                        })
                    }
                },
                {
                    title: '9 класс',
                    dataIndex: 'kurs_9',
                    render: text => {
                        const sick = text.split(':')[0]
                        const dynamic = text.split(':')[1]
                        let color;
                        const val = Number(dynamic);
                        if(val < 0) color = "#CCFFCC"
                        else if(val > 0) color = "#FFCCCC"
        
                        return ({
                            props: {
                                style: { background: color }
                            },
                            children: sick
                        })
                    }
                },
                {
                    title: '10 класс',
                    dataIndex: 'kurs_10',
                    render: text => {
                        const sick = text.split(':')[0]
                        const dynamic = text.split(':')[1]
                        let color;
                        const val = Number(dynamic);
                        if(val < 0) color = "#CCFFCC"
                        else if(val > 0) color = "#FFCCCC"
        
                        return ({
                            props: {
                                style: { background: color }
                            },
                            children: sick
                        })
                    }
                },
                {
                    title: '11 класс',
                    dataIndex: 'kurs_11',
                    render: text => {
                        const sick = text.split(':')[0]
                        const dynamic = text.split(':')[1]
                        let color;
                        const val = Number(dynamic);
                        if(val < 0) color = "#CCFFCC"
                        else if(val > 0) color = "#FFCCCC"
        
                        return ({
                            props: {
                                style: { background: color }
                            },
                            children: sick
                        })
                    }
                },
                {
                    title: () => <strong>Всего обучающихся</strong>,
                    render: row => ({
                        props: {
                            style: { background: "#fafafa" }
                        },
                        children: <strong>{row.student_count}</strong>
                    }),
                },
            ],
        },
        {
            title: "Динамика",
            dataIndex: "dynamic",
            render: text => {
                let color;
                const val = Number(text);
                if(val < 0) color = "#CCFFCC"
                else if(val > 0) color = "#FFCCCC"

                return ({
                    props: {
                        style: { background: color }
                    },
                    children: text
                })
            }
        },
        {
            title: "Количество обучающихся в училище",
            dataIndex: 'student_total'
        },
        {
            title: "% заболевших (переменный состав)",
            dataIndex: "ill_percent"
        },
        {
            title: "Режим противодействия COVID-19",
            dataIndex: "covid_state"
        }
    ];

    const columns = data.idOrg === "0" ? [
        {
            title: "№ п/п",
            render: (text, record, index) => index + 1,
            width: 65
        },
        {
            title: "Наименование образовательной организации",
            dataIndex: "orgName",
            width: 380
        },
        ...columnsInitial,

    ] : columnsInitial;

    const additionalColumns = [
        {
            title: 'Фамилия, имя, отчество',
            dataIndex: 'fio',
        },
        {
            title: 'Класс обучения/Должность',
            dataIndex: 'pos',
        },
        {
            title: 'Из какого региона (населенного пункта) прибыл на обучение',
            dataIndex: 'region',
        },
        {
            title: 'С какого числа болеет',
            render: row => row.started.split(' ')[0] + ' г.',
        },
        {
            title: "Течение заболевания",
            children: [
                {
                    title: 'симптомно',
                    dataIndex: 'symptom',
                },
                {
                    title: 'бессимптомно',
                    dataIndex: 'asymptomatic',
                },
            ],
        },
        {
            title: 'Причина заболевания',
            dataIndex: 'cause',
        },
        {
            title: 'Где находится на лечении',
            dataIndex: 'place',
        },
    ]

    return (
        <ExcelExporter isChief>

            {data.summary &&
            <div className={styles.container}>
                <h3 className={'text-center mb-2'}>Справка</h3>
                <div className={'d-flex justify-content-center'}>
                    <h4 className={'text-center mb-2 w-50'}>о заболевших коронавирусной инфекцией
                        в довузовских образовательных организациях Министерства обороны Российской Федерации
                        по состоянию на {data.report_date.split(" ")[0]} г. <br/>
                        {data.idOrg !== "0" && orgList.find(org => org.idOrganization === data.idOrg).orgName}
                    </h4>
                </div>

                {!!data.summary.length ?
                    <Table
                        columns={columns}
                        dataSource={updatedData[0]}
                        pagination={false}
                        summary={() => {
                            if(!updatedData[1]) {
                                return null
                            }
                            const {
                                admin_count,
                                teacher_count,
                                tutor_count,
                                other_count,
                                staff_count,
                                kurs_5,
                                kurs_6,
                                kurs_7,
                                kurs_8,
                                kurs_9,
                                kurs_10,
                                kurs_11,
                                student_count,
                                dynamic,
                                student_total,
                                ill_percent,
                                covid_state
                            } = updatedData[1];

                            return (
                                <Table.Summary.Row>
                                    <Table.Summary.Cell>

                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <Text strong>ИТОГО</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <Text strong>{admin_count}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <Text strong>{teacher_count}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <Text strong>{tutor_count}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <Text strong>{other_count}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <Text strong>{staff_count}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <Text strong>{kurs_5}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <Text strong>{kurs_6}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <Text strong>{kurs_7}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <Text strong>{kurs_8}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <Text strong>{kurs_9}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <Text strong>{kurs_10}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <Text strong>{kurs_11}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <Text strong>{student_count}</Text>
                                    </Table.Summary.Cell>

                                    <Table.Summary.Cell>
                                        <Text strong>{dynamic}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <Text strong>{student_total}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <Text strong>{ill_percent}</Text>
                                    </Table.Summary.Cell>
                                    <Table.Summary.Cell>
                                        <Text strong>{covid_state}</Text>
                                    </Table.Summary.Cell>
                                </Table.Summary.Row>
                            )
                        }}
                        bordered
                    />
                    :
                    <h2 className={'text-center'}>Нет данных</h2>
                }
                <div>
                    Режимы противодействия COVID-19 (% заболевших): <br/>
                    0% - Благополучный <br/>
                    от 0 до 1% - Неустойчивый <br/>
                    от 1 до 3% - Неблагополучный <br/>
                    больше 3% - Чрезвычайный

                </div>
            </div>
            }

            {data.detailed && data.idOrg !== "0" &&
            <div className={styles.container}>
                <h4 className={'text-right mb-2'}>Приложение к справке</h4>
                {!!data.detailed.length &&
                <Table columns={additionalColumns} dataSource={data.detailed} pagination={false} bordered/>
                }
            </div>
            }
        </ExcelExporter>
    );
};

CovidReportTable.propTypes = {
    formData: PropTypes.object,
    data: PropTypes.array,
};

export default CovidReportTable;
import {combineReducers} from "redux";
import { call, put, takeLatest } from 'redux-saga/effects';
import {candidatesFormReducer} from "./form/candidatesFormReducer";
import {candidatesLoaderReducer} from "./loader/candidatesLoaderReducer";
import {createActions} from "../../factory/actionFactory";
import {START} from "../../../constants/action-postfix";
import {reportsAPI} from "../../../api/reports";
import {candidatesListReducer} from "./list/candidatesListReducer";
import {candidatesParamsReducer} from "./params/candidatesParamsReducer";


export const SET_CANDIDATES_FORM_DATA = "SET_CANDIDATES_FORM_DATA";
export const SET_CANDIDATES_PARAMS_DATA = "SET_CANDIDATES_PARAMS_DATA";
export const CANDIDATES_REQUEST = "CANDIDATES_REQUEST";

export const candidatesActions = createActions('candidates', CANDIDATES_REQUEST);
export const setCandidatesFormData = data => ({
    type: SET_CANDIDATES_FORM_DATA,
    payload: data
});

export const setCandidatesParamsData = data => ({
    type: SET_CANDIDATES_PARAMS_DATA,
    payload: data
});

export const candidatesReducer = combineReducers({
    form: candidatesFormReducer,
    loader: candidatesLoaderReducer,
    list: candidatesListReducer,
    params: candidatesParamsReducer
});


//sagas

export function* candidatesWatcher() {
    yield takeLatest(`${CANDIDATES_REQUEST}${START}`, candidatesWorker)
}

function* candidatesWorker({ payload }) {
    try {
        const response = yield call(reportsAPI.candidatesReport, payload);
        yield put(candidatesActions.candidatesRequestSuccess(response.data.Answer.Data));
    } catch(error) {
        yield put(candidatesActions.candidatesRequestError(error));
    }
}
import {combineReducers} from "redux";
import {diseasedPeriodListReducer} from "./list/diseasedPeriodListReducer";
import {diseasedPeriodLoaderReducer} from "./loader/diseasedPeriodLoaderReducer";
import {createActions} from "../../../factory/actionFactory";
import { call, put, takeLatest } from 'redux-saga/effects';
import {reportsAPI} from "../../../../api/reports";
import {START} from "../../../../constants/action-postfix";
import {diseasedPeriodFormReducer} from "./form/diseasedPeriodFormReducer";

export const DISEASED_PERIOD_REQUEST = "DISEASED_PERIOD_REQUEST";
export const SET_PERIOD_DATA = "SET_PERIOD_DATA";

export const diseasedPeriodReducer = combineReducers({
    list: diseasedPeriodListReducer,
    loader: diseasedPeriodLoaderReducer,
    form: diseasedPeriodFormReducer
});

//actions
export const diseasedPeriodActions = createActions('diseasedPeriod', DISEASED_PERIOD_REQUEST);
export const setPeriodFormData = data => ({
    type: SET_PERIOD_DATA,
    payload: data
});

// SAGAS
export function* diseasedPeriodWatcher() {
    yield takeLatest(`${DISEASED_PERIOD_REQUEST}${START}`, diseasedPeriodWorker)
}

function* diseasedPeriodWorker({ payload }) {
    try {
        const response = yield call(reportsAPI.diseasedPeriodReport, payload);
        yield put(diseasedPeriodActions.diseasedPeriodRequestSuccess(response.data.Answer.Data_));
    } catch(error) {
        yield put(diseasedPeriodActions.diseasedPeriodRequestError(error));
    }
}
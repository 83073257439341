import React, {useEffect, useState} from 'react';
import {reduxForm, Field, formValueSelector} from 'redux-form';
import Button from '../../../common/Button/index';
import {Select} from '../../../common/FormsControls/FormsControls';
import InputDate from './../../../common/Date/Date';
import {Row, Col} from 'react-bootstrap';
import {connect} from 'react-redux';
import {setCurrentOrganization} from '../../../../redux/reducers/initialize-values-in-form';
import {compose} from 'redux';
import {PDFDownloadLink, Text, View} from "@react-pdf/renderer";
import PdfReports from "../../../PdfReports";
import {pdfStyles} from "../../../PdfReports/PdfStyles";
import pdfIcon from "../../../../img/icons/pdf.png";
import styles from "./GeneralStatisticsOneForm.module.css";
import * as docx from "docx";
import {saveAs} from "file-saver";
import docIcon from "../../../../img/icons/doc.png";
import {CSVLink} from "react-csv";
import csvIcon from "../../../../img/icons/csv.png";

const reportTableHeader = () => (
    <>
        <View fixed style={pdfStyles.tableRow}>
            <View style={{...pdfStyles.tableCol, ...pdfStyles.tableCol15}}>
                <Text style={pdfStyles.tableCell}>№ показателя</Text>
            </View>
            <View style={{...pdfStyles.tableCol, ...pdfStyles.tableCol40}}>
                <Text style={pdfStyles.tableCell}>Показатель</Text>
            </View>
            <View style={{...pdfStyles.tableCol, ...pdfStyles.tableCol15}}>
                <Text style={pdfStyles.tableCell}>Единица измерения</Text>
            </View>
            <View style={{...pdfStyles.tableCol, ...pdfStyles.tableCol10}}>
                <Text style={pdfStyles.tableCell}>Значение</Text>
            </View>
            <View style={{...pdfStyles.tableCol, ...pdfStyles.tableCol10}}>
                <Text style={pdfStyles.tableCell}>Коэф</Text>
            </View>
            <View style={{...pdfStyles.tableCol, ...pdfStyles.tableCol10}}>
                <Text style={pdfStyles.tableCell}>Результат</Text>
            </View>
        </View>
    </>
);

const reportTableItem = ({item, index}) => (<>
    <View wrap={false} style={pdfStyles.tableRow}>
        <View style={{...pdfStyles.tableCol, ...pdfStyles.tableCol15}}>
            <Text style={pdfStyles.tableCell}>{item.num === "0" ? "" : item.num}</Text>
        </View>
        <View style={{...pdfStyles.tableCol, ...pdfStyles.tableCol40}}>
            <Text style={{...pdfStyles.tableCell, textAlign: 'left', paddingLeft: 10}}>{item.unit_text}</Text>
        </View>
        <View style={{...pdfStyles.tableCol, ...pdfStyles.tableCol15}}>
            <Text style={pdfStyles.tableCell}>{item.num === "0" ? "" : item.unit_type}</Text>
        </View>
        <View style={{...pdfStyles.tableCol, ...pdfStyles.tableCol10}}>
            <Text style={pdfStyles.tableCell}>{item.num === "0" ? "" : item.source_value}</Text>
        </View>
        <View style={{...pdfStyles.tableCol, ...pdfStyles.tableCol10}}>
            <Text style={pdfStyles.tableCell}>{item.num === "0" ? "" : item.coef}</Text>
        </View>
        <View style={{...pdfStyles.tableCol, ...pdfStyles.tableCol10}}>
            <Text style={pdfStyles.tableCell}>{item.num === "0" ? "" : item.value}</Text>
        </View>
    </View>
</>);

/* DOCX REPORTS */
const DOCXReportTableHeader = () => {
    return new docx.TableRow({
        tableHeader: true,
        children: [
            new docx.TableCell({
                children: [new docx.Paragraph({
                    text: "№ показателя",
                    alignment: docx.AlignmentType.CENTER,
                })],
            }),
            new docx.TableCell({
                children: [new docx.Paragraph({
                    text: "Показатель",
                    alignment: docx.AlignmentType.CENTER,
                })],
            }),
            new docx.TableCell({
                children: [new docx.Paragraph({
                    text: "Единица измерения",
                    alignment: docx.AlignmentType.CENTER,
                })],
            }),
            new docx.TableCell({
                children: [new docx.Paragraph({
                    text: "Значение",
                    alignment: docx.AlignmentType.CENTER,
                })],
            }),
            new docx.TableCell({
                children: [new docx.Paragraph({
                    text: "Коэф",
                    alignment: docx.AlignmentType.CENTER,
                })],
            }),
            new docx.TableCell({
                children: [new docx.Paragraph({
                    text: "Результат",
                    alignment: docx.AlignmentType.CENTER,
                })],
            }),
        ],
    })
};

const DOCXReportTableItem = (item) => {
    return new docx.TableRow({
        children: [
            new docx.TableCell({
                children: [new docx.Paragraph(item.num === "0" ? "" : item.num)],
            }),
            new docx.TableCell({
                children: [new docx.Paragraph({
                    children: [new docx.TextRun({
                        text: item.unit_text,
                        bold: item.num === "0"
                    })]
                })],
            }),
            new docx.TableCell({
                children: [new docx.Paragraph(item.num === "0" ? "" : item.unit_type)],
            }),
            new docx.TableCell({
                children: [new docx.Paragraph({
                    text: item.num === "0" ? "" : item.source_value,
                    alignment: docx.AlignmentType.CENTER,
                })],
            }),
            new docx.TableCell({
                children: [new docx.Paragraph({
                    text: item.num === "0" ? "" : item.coef,
                    alignment: docx.AlignmentType.CENTER,
                })],
            }),
            new docx.TableCell({
                children: [new docx.Paragraph({
                    text: item.num === "0" ? "" : item.value,
                    alignment: docx.AlignmentType.CENTER,
                })],
            }),
        ],
    });
};

/* CSV REPORTS */
const CsvHeaders = [
    {label: "№ показателя", key: "num"},
    {label: "Показатель", key: "unit_text"},
    {label: "Единица измерения", key: "unit_type"},
    {label: "Значение", key: "source_value"},
    {label: "Коэф", key: "coef"},
    {label: "Результат", key: "value"}
];


let GeneralStatisticsOneForm =
    ({
         error, handleSubmit, orgList, formData,
         setCurrentOrganization, id, data
     }) => {

        const formValues = formData.generalStatOne || {};
        const orgListForSelect = orgList.map(i => ({id: i.idOrganization, value: i.orgName}));
        let PDF_REPORT_NAME = "";

        if (data.length) {
            PDF_REPORT_NAME = `Показатели оценки ${(orgList.find(item => formValues.values && formValues.values.organization === item.idOrganization) || {}).orgName} за ${formValues.values && formValues.values.date}`;
        }

        useEffect(() => {
            setCurrentOrganization(id)
        }, [setCurrentOrganization, id]);

        const generateDoc = () => {
            const doc = new docx.Document({
                creator: "",
                description: PDF_REPORT_NAME,
                title: PDF_REPORT_NAME,
            });

            const tableData = data.map(item => DOCXReportTableItem(item));

            const title = new docx.Paragraph({
                text: PDF_REPORT_NAME,
                heading: docx.HeadingLevel.HEADING_2,
            });


            const reportTable = new docx.Table({
                width: {
                    size: 100,
                    type: docx.WidthType.AUTO,
                },
                columnWidths: [1000, 4100, 1000, 1000, 1000, 1000],
                rows: [
                    DOCXReportTableHeader(),
                    ...tableData
                ],
            });

            doc.addSection({
                children: [title],
            });

            doc.addSection({
                children: [reportTable],
            });

            docx.Packer.toBlob(doc).then((blob) => {
                saveAs(blob, "отчет.docx");
            });

        };

        return (
            <form onSubmit={handleSubmit}>
                <Row className={"justify-content-between"}>
                    <Col md="auto" className={'d-flex'}>
                        <Field name='organization'
                               component={Select}
                               data={orgListForSelect}
                               type='select'
                               label='Выберите образовательную организацию'
                               style={{maxWidth: 350}}
                        />
                        <Field name='date'
                               component={InputDate}
                               type='date'
                        />
                        <Button type='submit' label={"Составить"}/>
                    </Col>

                    <Col md="auto">
                        {data && !!data.length && <>
                            <div className={styles.loadOuter}>
                                <PDFDownloadLink
                                    document={
                                        <PdfReports
                                            name={PDF_REPORT_NAME}
                                            data={data}
                                            TableHeader={reportTableHeader}
                                            TableItem={reportTableItem}
                                        />
                                    }
                                    fileName="report.pdf"
                                >
                                    {({blob, url, loading, error}) => (loading ? 'Генерация отчета...' :
                                            <span className={'load-button'} title={"Выгрузить в pdf"}>
                    <img src={pdfIcon} alt=""/>
                  </span>
                                    )}
                                </PDFDownloadLink>
                            </div>
                            <div className={styles.loadOuter}>
                  <span
                      className={'load-button'}
                      title={"Выгрузить в docx"}
                      onClick={generateDoc}
                  >
                    <img src={docIcon} alt=""/>
                  </span>
                            </div>
                            <div className={styles.loadOuter}>
                                <CSVLink headers={CsvHeaders} data={data} filename={'отчет.csv'}>
                  <span className={'load-button'} title={"Выгрузить в excel"}>
                    <img src={csvIcon} alt=""/>
                  </span>
                                </CSVLink>
                            </div>
                        </>}
                    </Col>
                    {error && <span>{error}</span>}
                </Row>
            </form>
        );
    };


export default compose(
    connect(state => ({initialValues: state.initForm.data}),
        {setCurrentOrganization}),
    reduxForm({form: 'generalStatOne', enableReinitialize: true}),
)(GeneralStatisticsOneForm)
import { Button } from 'shards-react'
import React from 'react'
import { useSelector } from 'react-redux'
import addFont from '../../../../fonts/fonts'
import jsPDF from 'jspdf'
import moment from 'moment'
import XLSX from "xlsx";
import { saveAs } from "file-saver";

const orientation = 'l'
const fileTitle = `Отчет по льготам переменного состава ${moment().format('DD.MM.YYYY')}`

export default function Buttons() {

  const { form, list } = useSelector(state => state.reports.privileges)
  const orgList = useSelector(state => state.reports.orgList.data)

  const orgObject = orgList.find(org => org.idOrganization == form.idOrg) || {}
  const orgName = orgObject.orgName
  const schooolTitle = orgName ? `${orgName}` : 'довузовских общеобразовательных организаций Министерства обороны Российской Федерации'

  const getXLSdata = () => {
    const wb = XLSX.utils.book_new();
    wb.SheetNames.push("privileges");
    const table = document.getElementById("privilegesTable")
    const ws = XLSX.utils.table_to_sheet(table, {raw: false});
    wb.Sheets["privileges"] = ws;
    const wbout = XLSX.write(wb, {
      bookType: "xlsx",
      bookSST: true,
      type: "binary",
    });
    const s2ab = (s) => {
      let buf = new ArrayBuffer(s.length);
      let view = new Uint8Array(buf);
      for (let i = 0; i < s.length; i++) view[i] = s.charCodeAt(i) & 0xff;
      return buf;
    };
    saveAs(new Blob([s2ab(wbout)], { type: "application/octet-stream" }), `${fileTitle}.xlsx`);
  }

  const getPdfData = async () => {
    
    addFont(jsPDF.API)
    const doc = new jsPDF({ putOnlyUsedFonts: true, orientation: orientation })

    const {width, height} = doc.internal.pageSize
    let lastY = 10

    const getY = (indent, newValue) => {
      let newY = newValue ? newValue + indent : lastY + indent
      if (newY > height - 15) {
        doc.addPage('a4', orientation)
        newY = 10
      }
      lastY = newY
      return newY
    }

    doc.setFont('Montserrat')

    doc.setFontSize('12').setFont(undefined, 'bold')
    doc.text('Наличие льгот у обучающихся', width/2, lastY, 'center')
    doc.text(schooolTitle, width/2, getY(7), 'center')
    const offset = schooolTitle.length > 85 ? 15 : 7
    doc.text(`на ${moment(form.date).format('DD.MM.YYYY')}`, width/2, getY(offset), 'center')

    doc.autoTable({
      html: '#privilegesTable',
      styles: { font: 'Montserrat', fontSize: 9 },
      headStyles: {
        halign: 'center',
        valign: 'middle',
        lineWidth: 0.25,
        lineColor: 200,
      },
      bodyStyles: {
        halign: 'center',
        lineWidth: 0.25,
        lineColor: 200,
      },
      columnStyles: {
        1: { halign: 'left' },
      },

      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : getY(7),
    })

    return doc
  }

  const toPdf = () => {
    getPdfData().then(doc => doc.save(`${fileTitle}.pdf`))
  }
  
  const toExcel = () => {
    getXLSdata()
  }
  
  return (
    <>
      {
        !!list.length &&
        <div>
          <Button theme='success' className={'mb-2'} onClick={toExcel}>
            Скачать .xlsx
          </Button>
          &nbsp;
          <Button theme='danger' className={'mb-2'} onClick={toPdf}>
            Скачать .pdf
          </Button>
        </div>
      }
    </>
  )
}

const SET_BEST_SCHOOL_FORM_DATA = "SET_BEST_SCHOOL_FORM_DATA";

const initialState = {
    date: '',
    specparamsId: 0
};

export const bestSchoolFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_BEST_SCHOOL_FORM_DATA:
            return action.payload;
        default:
            return state;
    }
};

export const setBestSchoolFormData = (data) => ({
    type: SET_BEST_SCHOOL_FORM_DATA,
    payload: data
});
import React from 'react'
import PropTypes from 'prop-types'

import { Button, Col, FormCheckbox, FormGroup, FormInput, FormSelect, Row } from 'shards-react'

const ConnectionFailsForm = ({ orgList, onSubmit, setFormData, formData }) => {
  
  const handleInputChange = e => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    })
  }

  return (
    <Row className={'my-3 justify-content-between'}>
      <Col md={'auto'} className={'d-flex align-items-center'}>
        <FormGroup className={'mr-3'}>
          <label htmlFor='_to'>C (дата)</label>
          <FormInput
            size='sm'
            type={'date'}
            name={'datefrom'}
            className='mb-2'
            value={formData.datefrom}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup className={'mr-3'}>
          <label htmlFor='_to'>По (дата)</label>
          <FormInput
            size='sm'
            type={'date'}
            name={'dateto'}
            className='mb-2'
            value={formData.dateto}
            onChange={handleInputChange}
          />
        </FormGroup>
        <FormGroup className={'mr-3'}>
          <label htmlFor='_idOrg'>Выберите организацию</label>
          <FormSelect
            name={'idOrg'}
            id='_idOrg'
            size='sm'
            className='mb-2 d-block'
            onChange={handleInputChange}
            style={{ width: 730 }}
          >
            <option value={0}>Все организации</option>
            {orgList &&
              orgList.map(item => (
                <option key={item.idOrganization} value={item.idOrganization}>
                  {item.orgName}
                </option>
              ))}
          </FormSelect>
        </FormGroup>
        {formData.idOrg === '0' && (
          <FormCheckbox
            onChange={() =>
              setFormData({
                ...formData,
                school: !formData.school,
              })
            }
            name={'school'}
            checked={formData.school}
            className={'mr-3'}
          >
            Школы
          </FormCheckbox>
        )}
        <Button onClick={onSubmit}>Создать отчет</Button>
      </Col>
    </Row>
  )
}

ConnectionFailsForm.propTypes = {
  orgList: PropTypes.array,
  onSubmit: PropTypes.func,
}

export default ConnectionFailsForm

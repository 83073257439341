export const modifyCandidatesData = (data) => {
    const subjects = Array.from(new Set(data.map(item => item.Subject).filter(subj => subj!=='ИТОГО'))).sort((a, b) => a.toLowerCase() > b.toLowerCase() ? 1 : -1)
    const organizations = Array.from(new Set(data.map(item => item.orgname).filter(org => org)))
    const newData = subjects.map(subject => {
        const values = organizations.map(org => {
            const object = data.find(item => item.orgname===org && item.Subject===subject)
            const total = object ? object.Total : null
            return {organization: org, count: total}
        })
        return {subject, values}
    })
    const resRow = {subject: 'ИТОГО', values: organizations.map((_, ind) => ({count: newData.reduce((sum, row) => sum + parseInt(row.values[ind].count||0), 0)}))}
    newData.push(resRow)
    return newData
}
import React from 'react'
import { useSelector } from 'react-redux'
import styles from '../styles.module.css'
import moment from 'moment'


export default function Details () {
  
  const { details: data, details_params: form  } = useSelector(state => state.reports.privileges)
  const orgList = useSelector(state => state.reports.orgList.data)

  const renderRow = (item, index) => {
    const { fio, classname, orgname } = item
    return (
      <tr>
        <td>{index+1}</td>
        <td>{fio}</td>
        <td>{classname}</td>
        <td>{orgname}</td>
      </tr>
    )
  }
  
  const orgObject = orgList.find(org => org.idOrganization == form.idOrg) || {}
  const orgName = orgObject.orgName
  const parallel = form.kurs ? ` ${form.kurs} параллели` : ''
  const studentGender = form.both ? (form.gender === 'М' ? ' (мальчики)' : ' (девочки)') : ''


  return (
    <div className={styles.detailsBlock}>
      {
        !!data.length &&
          <>
            <h3 className={"text-center mb-2"}>{orgName}</h3>
            <h3 className={"text-center mb-2"}>
              {`Обучающиеся${parallel}${studentGender}, имеющие льготу '${form.privilege}' по состоянию на ${moment(form.date).format('DD.MM.YYYY')}`}
            </h3>
            <table style={{width: 'auto'}} className={styles.mainTable}>
              <thead>
                <tr>
                  <th>№ п/п</th>
                  <th>ФИО обучаещегося</th>
                  <th>Класс</th>
                  <th>Субъект РФ</th>
                </tr>
              </thead>
              <tbody>
                {data.map(renderRow)}
              </tbody>
            </table>
          </>
      }
    </div>
  )
}

import React, {useEffect} from 'react';
import {connect} from 'react-redux';
import RouteLayout from "../../../RouteLayout";
import DiseasedPeriodForm from "./Form";
import {organizationList} from "../../../../redux/reducers/organization-list-reducer";
import Spinner from "../../../common/Spinner";
import DiseasedPeriodTable from "./Table";
import {
    diseasedPeriodActions,
    setPeriodFormData
} from "../../../../redux/reducers/Diseased/Period/diseasedPeriodReducer";



const DiseasedPeriod = ({ auth, organizationListStart, orgList, requestReport, loader, list, setFormData, formData }) => {

    useEffect(() => {
        if (orgList !== undefined && orgList.length === 0) {
            organizationListStart(auth)
        }
    }, [organizationListStart, auth]);

    const requestReportHandler = () => {

        const params = {
            school: formData.school ? 1 : 0,
            idOrg: formData.idOrg,
            datefrom: formData.datefrom,
            dateto: formData.dateto
        };

        requestReport({ ...auth,  ...params});
    };

    return (
        <RouteLayout
            title={'Сведения о заболевших на дату'}
        >
            <DiseasedPeriodForm onSubmit={requestReportHandler}
                                orgList={orgList}
                                setFormData={setFormData}
                                formData={formData}/>

            {loader ?
                <Spinner />
                :
                <DiseasedPeriodTable data={list}/>
            }
        </RouteLayout>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth.data,
    orgList: state.reports.orgList.data,
    list: state.reports.diseased.period.list,
    loader: state.reports.diseased.period.loader,
    formData: state.reports.diseased.period.form
});

const mapDispatchToProps = {
    organizationListStart: organizationList.organizationListRequestStart,
    requestReport: diseasedPeriodActions.diseasedPeriodRequestStart,
    setFormData: setPeriodFormData
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DiseasedPeriod);
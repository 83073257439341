import React, { useState } from 'react'
import { Button, Col, FormGroup, FormInput, FormSelect, Row, FormCheckbox } from 'shards-react'
import { useFormik } from 'formik'
import moment from 'moment'
import * as Yup from 'yup'

const STREAMS = [
  { value: 'Основной набор', id: 0 },
  { value: 'Донабор', id: 1 },
  { value: 'Все', id: -1 }
]

const STATUS = [
  { value: 'Все', id: 0 },
  { value: 'Допущенные', id: 1 },
  { value: 'Недопущенные', id: 2 },
  { value: 'Прошедшие', id: 3 },
  { value: 'Непрошедшие', id: 4 }
]


const validationSchema = Yup.object().shape({
  fromdate: Yup.date(),
  todate: Yup.date()
    .min(Yup.ref("fromdate"), "Дата завершения должна быть позже даты начала"),
})

export const CasesNumberForm = ({ onSubmit }) => {
  const [school, setSchool] = useState(0)

  const formik = useFormik({
    initialValues: {
      fromdate: moment(new Date()).format('YYYY-MM-DD'),
      todate: moment(new Date()).format('YYYY-MM-DD'),
      stream: 0,
      school: 1,
      status: 0
    },
    validationSchema: validationSchema,
    onSubmit: values => {
      onSubmit(values)
    },
  })

  formik.values.school = school

  return (
    <Row className={'my-3 justify-content-between'}>
      <Col md={'auto'} className={'d-flex align-items-center'}>
        <FormGroup className={'mr-3'}>
          <label htmlFor='_to'>Дата с</label>
          <FormInput
            size='sm'
            type={'date'}
            name={'fromdate'}
            className='mb-2'
            value={formik.values.fromdate}
            onChange={formik.handleChange}
          />
        </FormGroup>

        <FormGroup className={'mr-3'}>
          <label htmlFor='_to' style={formik.errors.todate ? {color: "red", fontWeight: "700"} : null}>Дата по</label>
          <FormInput
            size='sm'
            type={'date'}
            name={'todate'}
            error={formik.errors.todate}
            className='mb-2'
            value={formik.values.todate}
            onChange={formik.handleChange}
          />
        </FormGroup>

        <FormGroup className={'mr-3'}>
          <label htmlFor='stream'>Тип набора</label>
          <FormSelect
            name={'stream'}
            id='stream'
            size='sm'
            className='mb-2 d-block'
            onChange={formik.handleChange}
            style={{ width: 350 }}
            value={formik.values.stream}
          >
            {STREAMS.map(({ value, id }) => (
              <option key={id} value={id} selected={id === 1}>
                {value}
              </option>
            ))}
          </FormSelect>
        </FormGroup>

        <FormGroup className={'mr-3'}>
          <label htmlFor='status'>Статус кандидата</label>
          <FormSelect
            name={'status'}
            id='status'
            size='sm'
            className='mb-2 d-block'
            onChange={formik.handleChange}
            style={{ width: 350 }}
            value={formik.values.status}
          >
            {STATUS.map(({ value, id }) => (
              <option key={id} value={id} selected={id === 0}>
                {value}
              </option>
            ))}
          </FormSelect>
        </FormGroup>

        <FormCheckbox onChange={() => setSchool(school ? 0 : 1)} name='school' checked={school} className={'mr-3'}>
          Школы
        </FormCheckbox>

        <Button onClick={formik.handleSubmit}>Создать отчет</Button>
      </Col>
    </Row>
  )
}

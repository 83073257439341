import { NotificationManager } from 'react-notifications'
import { api } from '.'
import { BASE_PARAMS } from '../constants/api'

export const fileSharingListRequest = ({ login, pass, idOrg, fromDate, toDate, receive }) => {
  const params = {
    ...BASE_PARAMS,
    login,
    pass,
    action: `${BASE_PARAMS.action}.list.files`,
    idOrg,
    fromDate,
    toDate,
    receive,
  }

  return api.get('/', { params })
}

export const fileSharingAddFile = async ({data, ...getParams}) => {
  const params = {...BASE_PARAMS, ...getParams, action: `consolidated.files.put` }
  const formData = new FormData()
  formData.append('data', data)
  try {
    await api.post(`/`, formData, { params })
    NotificationManager.success('Файл успешно добавлен')
  } catch {
    NotificationManager.error('Произошла ошибка')
  }
}

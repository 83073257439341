import {SUCCESS} from "../../../../constants/action-postfix";
import { dataByOrg, dataByParallel } from "../../../../utils/transformPrivilegesData";
import {PRIVILEGES_REQUEST} from "../privilegesReducer";


const initialState = []


export const privilegesListReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${PRIVILEGES_REQUEST}${SUCCESS}`:
            return action.payload;

        default:
            return state;
    }
};

export const getSubData = ({data, criterions}) => {
  
  const results = criterions.reduce((results, criterion) => ({...results, [criterion.id]: {}}), {})

  data.forEach(value => {
    const existValue = results[value.top_group][value.orgname] || 0
    results[value.top_group][value.orgname] = existValue + Number(value.value)
  });

  return results
}
export const menuStructure = [
  {
    title: 'Главная',
    link: '/',
  },
  {
    title: 'Отчёты',
    link: '/reports',
    dropdown: [
      {
        title: 'Показатели оценки довузовских общеобразовательных организаций (общий)',
        link: '/reports/general_statistic_all',
      },
      {
        title: 'Показатели оценки довузовских общеобразовательных организаций',
        link: '/reports/general_statistic_one/',
      },
      {
        title: 'Объемные показатели по определению лучшего училища',
        link: '/reports/best_school?authorized',
        blank: true,
      },
      {
        title: 'Сведения о заболевших на дату',
        link: '/reports/diseased_period',
      },
      {
        title: 'Статистика по заболевшим COVID-19',
        link: '/reports/diseased_covid',
      },
      {
        title: 'Статистика по вакцинации от COVID-19',
        link: '/reports/vaccinated_covid',
      },
      {
        title: 'КАНДИДАТЫ НА ПОСТУПЛЕНИЕ ПО СУБЪЕКТАМ РФ',
        link: '/reports/candidates',
      },
      {
        title: 'ЧИСЛЕННОСТЬ ПЕРЕМЕННОГО СОСТАВА',
        link: '/reports/students_total',
      },
      {
        title: 'Количество личных дел кандидатов на поступление',
        link: '/reports/cases_number',
      },
      {
        title: 'Телефонный справочник руководящего состава училищ',
        link: '/reports/phonebook'
      },
      {
        title: 'Отчет по двойникам из числа кандидатов на поступление',
        link: '/reports/duplicates'
      },
      {
        title: 'Количество сбоев при подключении к базам данных',
        link: '/reports/connect_fails'
      },
      {
        title: 'Кандидаты на поступление по субъектам РФ (сводный)',
        link: '/reports/candidates_cons'
      },
      {
        title: 'Отчет по льготам переменного состава',
        link: '/reports/privileges'
      },
      {
        title: 'Отчет о поступлении выпускников',
        link: '/reports/graduates'
      }
    ],
  },
  {
    title: 'Справочники',
    link: '/publishers',
    dropdown: [
      {
        title: 'Организации',
        link: '/organization',
      },
    ],
  },
  {
    title: 'Обмен файлами',
    link: '/file_sharing',
  },
]

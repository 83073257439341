import React, {Component, useEffect} from 'react';
import {connect} from 'react-redux';
import DiseasedMonthForm from "./Form";
import {organizationList} from "../../../../redux/reducers/organization-list-reducer";
import RouteLayout from "../../../RouteLayout";
import {diseasedMonthActions} from "../../../../redux/reducers/Diseased/Month/diseasedMonthReducer";
import Spinner from "../../../common/Spinner";
import DiseasedWeekTable from "../Week/Table";
import DiseasedMonthTable from "./Table";
import moment from "moment";

const DiseasedMonth = ({ auth, organizationListStart, orgList, requestReport, loader, list, period }) => {

    useEffect(() => {
        if (orgList !== undefined && orgList.length === 0) {
            organizationListStart(auth)
        }
    }, [organizationListStart, auth]);

    const requestReportHandler = ({ school, idOrg, month, year }) => {

        const params = {
            school: school ? 1 : 0,
            idOrg,
            datefrom: `${year}-${month}-01`,
            dateto: moment(`${year}-${month}-01`).endOf('month').format('YYYY-MM-DD'),
            month,
            year
        };

        requestReport({ ...auth,  ...params});
    };

    return (
        <RouteLayout
            title={'Сведения о заболевших за месяц'}
        >
            <DiseasedMonthForm orgList={orgList} onSubmit={requestReportHandler}/>
            {loader ?
                <Spinner />
                :
                <DiseasedMonthTable data={list} period={period}/>
            }
        </RouteLayout>
    );
};

const mapStateToProps = (state) => ({
    auth: state.auth.data,
    orgList: state.reports.orgList.data,
    list: state.reports.diseased.month.list,
    loader: state.reports.diseased.month.loader,
    period: state.reports.diseased.month.period
});

const mapDispatchToProps = {
    organizationListStart: organizationList.organizationListRequestStart,
    requestReport: diseasedMonthActions.diseasedMonthRequestStart
};

export default connect(
    mapStateToProps,
    mapDispatchToProps
)(DiseasedMonth);
import moment from "moment";
import {SET_CANDIDATES_FORM_DATA} from "../candidatesReducer";


const initialState = {
    'year': new Date().getFullYear(),
    'date': moment().format('YYYY-MM-DD'),
    'idOrg': "0",
    'status': 0

};

export const candidatesFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_CANDIDATES_FORM_DATA:
            return action.payload;
        default:
            return state;
    }
};
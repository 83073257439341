import moment from "moment";
import {SET_WEEK_PERIOD_DATA} from "../diseasedWeekReducer";

const initialState = {
    'school': false,
    'idOrg': "0",
    'year': new Date().getFullYear(),
    'week': moment().format('W')
};


export const diseasedWeekFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_WEEK_PERIOD_DATA:
            return action.payload;
        default:
            return state;
    }
};
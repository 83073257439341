import {SET_PRIVILEGES_MODE} from "../privilegesReducer";


const initialState = true;

export const modeReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PRIVILEGES_MODE:
            return action.payload
        default:
            return state;
    }
};
import {ERROR, START, SUCCESS} from "../../../../constants/action-postfix";
import {CONNECT_FAILS_REQUEST} from "../connectFailsReducer";


const initialState = false;

export const connectFailsLoaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${CONNECT_FAILS_REQUEST}${START}`:
            return true;
        case `${CONNECT_FAILS_REQUEST}${SUCCESS}`:
            return false;
        case `${CONNECT_FAILS_REQUEST}${ERROR}`:
            return false;
        default:
            return state;
    }
};
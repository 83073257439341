const initialState = []

const SET_G_STAT_ALL_PDF_DATA = 'SET_G_STAT_ALL_PDF_DATA'

export const setGeneralStatisticAllPdfData = data => ({
  type: SET_G_STAT_ALL_PDF_DATA,
  payload: data,
})

export const generalStatisticsAllPdfReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_G_STAT_ALL_PDF_DATA:
      return action.payload

    default:
      return state
  }
}

import {SUCCESS} from "../../../../../constants/action-postfix";
import {DISEASED_MONTH_REQUEST} from "../diseasedMonthReducer";


const initialState = [];

export const diseasedMonthListReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${DISEASED_MONTH_REQUEST}${SUCCESS}`:
            return action.payload;

        default:
            return state;
    }
};
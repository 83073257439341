import moment from "moment";
import {SET_PERIOD_DATA} from "../diseasedPeriodReducer";

const initialState = {
    'school': false,
    'idOrg': "0",
    'datefrom': moment().format('YYYY-MM-DD'),
    'dateto': moment().format('YYYY-MM-DD')
};

export const diseasedPeriodFormReducer = (state = initialState, action) => {
    switch (action.type) {
        case SET_PERIOD_DATA:
            return action.payload;
        default:
            return state;
    }
};

import React from 'react'
import { useSelector } from 'react-redux'
import { BASE_URL } from '../../../../constants/api'
import { getAuthData } from '../../../../redux/reducers/auth-reducer'
import { CaptionTable } from '../../../common/CaptionTable/CaptionTable'
import Spinner from '../../../common/Spinner'
import styles from '../FileSharing.module.css'

export const FileSharingReceivedTable = () => {
  const { login, pass } = useSelector(getAuthData)
  const { receivedList, receivedLoading } = useSelector(({ fileSharing }) => fileSharing)

  if (receivedLoading) return <Spinner />
  return (
    <>
      <table className={styles.table}>
        <thead>
          <tr>
            <th>Название файла</th>
            <th>Дата и время отправки</th>
            <th>Училище</th>
          </tr>
        </thead>
        <tbody>
          {receivedList.map(e => (
            <tr>
              <td>
                <a
                  href={`${BASE_URL}/?action=consolidated.files.get&format=json&base=cons&login=${login}&pass=${pass}&fileid=${e.idFile}`}
                >
                  {e.filename}
                </a>
              </td>
              <td>{e.loadtime}</td>
              <td>
                {e.schools.map(e => (
                  <span>{e.name}</span>
                ))}
              </td>
            </tr>
          ))}
        </tbody>
      </table>
      {!receivedList.length && !receivedLoading && <CaptionTable>Список пуст</CaptionTable>}
    </>
  )
}

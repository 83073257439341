import {ERROR, START, SUCCESS} from "../../../../../constants/action-postfix";
import {DISEASED_PERIOD_REQUEST} from "../diseasedPeriodReducer";


const initialState = false;

export const diseasedPeriodLoaderReducer = (state = initialState, action) => {
    switch (action.type) {
        case `${DISEASED_PERIOD_REQUEST}${START}`:
            return true;
        case `${DISEASED_PERIOD_REQUEST}${SUCCESS}`:
            return false;
        case `${DISEASED_PERIOD_REQUEST}${ERROR}`:
            return false;
        default:
            return state;
    }
};
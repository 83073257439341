import { combineReducers } from 'redux'
import { reducer as formReducer } from 'redux-form'
import { authReducer } from './auth-reducer'
import { generalStatisticsOneReducer } from './general-statistic-one-reducer'
import { generalStatisticsAllReducer } from './general-statistic-all-reducer'
import { organizationListReducer } from './organization-list-reducer'
import initializeFormReducer from './initialize-values-in-form'
import { pdfReportsReducer } from './PDFReports/pdfReportsReducer'
import { bestSchoolReducer, specparamsReducer } from './BestSchool/bestSchoolReducer'
import { bestSchoolFormReducer } from './BestSchool/Form/bestSchoolFormReducer'
import { diseasedReducer } from './Diseased/diseasedReducer'
import { candidatesReducer } from './Candidates/candidatesReducer'
import { studentsTotalReducer } from './StudentsTotal/studentsTotalReducer'
import { casesNumberReducer } from './casesNumber/casesNumberReducer'
import { fileSharingReducer } from './fileSharing/fileSharingReducer'
import { phonebookReducer } from './Phonebook/phonebookReducer'
import { vaccinatedReducer } from './Vaccinated/vaccinatedReducer'
import { duplicatesReducer } from './Duplicates/duplicatesReducer'
import { connectFailsReducer } from './ConnectFails/connectFailsReducer'
import { privilegesReducer } from './Priveleges/privilegesReducer'
import { graduatesReducer } from './Graduates/graduatesReducer'

const reportsReducer = combineReducers({
  casesNumber: casesNumberReducer,
  candidates: candidatesReducer,
  generalAll: generalStatisticsAllReducer,
  generalOne: generalStatisticsOneReducer,
  bestSchool: bestSchoolReducer,
  bestSchoolForm: bestSchoolFormReducer,
  orgList: organizationListReducer,
  diseased: diseasedReducer,
  studentsTotal: studentsTotalReducer,
  vaccinated: vaccinatedReducer,
  duplicates: duplicatesReducer,
  connectFails: connectFailsReducer,
  privileges: privilegesReducer,
  graduates: graduatesReducer
})

export const rootReducer = combineReducers({
  fileSharing: fileSharingReducer,
  initForm: initializeFormReducer,
  form: formReducer,
  auth: authReducer,
  reports: reportsReducer,
  pdfReports: pdfReportsReducer,
  specparams: specparamsReducer,
  phoneBook: phonebookReducer
})
